/* eslint-disable react/display-name */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { isEmpty } from "lodash";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNotify, usePermissions, useTranslate } from "react-admin";
import * as XLSX from "xlsx";

import importTemplate from "../assets/importTemplate.xlsx";
import { parseDate, validDateString } from "../common/dateUtils";
import { baseApi } from "../common/envUtils";
import PermissionUtils from "../common/permissionUtils";
import { validateEmail, validatePhone } from "../common/validateUtils";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";
import customProvider from "../providers/customProvider";
import httpClient from "../providers/httpClient";

const tableIcons = {
	Add: forwardRef((properties, reference) => <AddBox {...properties} ref={reference} />),
	Check: forwardRef((properties, reference) => <Check {...properties} ref={reference} />),
	Clear: forwardRef((properties, reference) => <Clear {...properties} ref={reference} />),
	Delete: forwardRef((properties, reference) => (
		<DeleteOutline {...properties} ref={reference} />
	)),
	DetailPanel: forwardRef((properties, reference) => (
		<ChevronRight {...properties} ref={reference} />
	)),
	Edit: forwardRef((properties, reference) => <Edit {...properties} ref={reference} />),
	Export: forwardRef((properties, reference) => <SaveAlt {...properties} ref={reference} />),
	Filter: forwardRef((properties, reference) => <FilterList {...properties} ref={reference} />),
	FirstPage: forwardRef((properties, reference) => <FirstPage {...properties} ref={reference} />),
	LastPage: forwardRef((properties, reference) => <LastPage {...properties} ref={reference} />),
	NextPage: forwardRef((properties, reference) => (
		<ChevronRight {...properties} ref={reference} />
	)),
	PreviousPage: forwardRef((properties, reference) => (
		<ChevronLeft {...properties} ref={reference} />
	)),
	ResetSearch: forwardRef((properties, reference) => <Clear {...properties} ref={reference} />),
	Search: forwardRef((properties, reference) => <Search {...properties} ref={reference} />),
	SortArrow: forwardRef((properties, reference) => (
		<ArrowDownward {...properties} ref={reference} />
	)),
	ThirdStateCheck: forwardRef((properties, reference) => (
		<Remove {...properties} ref={reference} />
	)),
	ViewColumn: forwardRef((properties, reference) => (
		<ViewColumn {...properties} ref={reference} />
	)),
};

const isLicenseTypeValid = licenseType =>
	isEmpty(licenseType) ||
	licenseType.toLowerCase().includes("rosa") ||
	licenseType.includes("EU") ||
	licenseType.toLowerCase().includes("grau");

const isDriverTypeValid = driverTypeEnum =>
	Boolean(driverTypeEnum) &&
	(driverTypeEnum.includes("Firmenwagen") || driverTypeEnum.includes("Gelegenheit"));

const mapToDriverModel = (
	sheetRow,
	// eslint-disable-next-line unicorn/no-object-as-default-parameter
	mapping = {
		surName: "Nachname*",
		firstName: "Vorname*",
		email: "E-Mail*",
		licenseLabelCode: "Label-Code",
		phoneNumber: "Mobilfunknummer",
		licenseType: "Führerscheinart",
		driverTypeEnum: "Fahrertyp*",
		licenseExpiryDate: "Ablaufdatum",
	}
) => ({
	surName: sheetRow[mapping.surName],
	firstName: sheetRow[mapping.firstName],
	email: sheetRow[mapping.email],
	licenseLabelCode: sheetRow[mapping.licenseLabelCode],
	phoneNumber: sheetRow[mapping.phoneNumber],
	licenseType: sheetRow[mapping.licenseType],
	driverTypeEnum: sheetRow[mapping.driverTypeEnum],
	licenseExpiryDate: sheetRow[mapping.licenseExpiryDate],
});

const cellStyle = valid => {
	const baseStyle = {
		width: "100%",
		padding: 10,
	};
	if (!valid) {
		return {
			...baseStyle,
			backgroundColor: "red",
			color: "white",
		};
	}

	return baseStyle;
};

const getLicenseCode = licenseType => {
	if (!licenseType) {
		return null;
	}
	if (licenseType.includes("EU")) {
		return 0;
	}
	if (licenseType.toLowerCase().includes("rosa")) {
		return 1;
	}
	if (licenseType.toLowerCase().includes("grau")) {
		return 2;
	}
	return null;
};

const getDriverType = driverType => {
	if (driverType.includes("Firmenwagen")) {
		return 0;
	}
	if (driverType.includes("Gelegenheit")) {
		return 1;
	}
	return null;
};

const DriverUploadPage = () => {
	const inputElement = useRef(null);
	const translate = useTranslate();
	const notify = useNotify();
	const permissions = new PermissionUtils(usePermissions());
	const [codes, setCodes] = useState([]);

	const [sheetData, setSheetData] = useState([]);

	useEffect(() => {
		httpClient(`${baseApi}/label-codes`).then(({ json }) => {
			setCodes(json);
		});
	}, []);

	const isLabelCodeValid = code => codes.includes(code);

	const handleFileChange = () => {
		if (inputElement && inputElement.current && inputElement.current.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				const fileData = reader.result;
				const wb = XLSX.read(fileData, { type: "binary" });

				const sheet = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
				setSheetData(
					sheet.map(row => mapToDriverModel(row)).filter(r => r.surName && r.firstName)
				);
			});
			reader.readAsBinaryString(inputElement.current.files[0]);
		}
	};

	const isSheetValid = () => {
		if (sheetData.length > 0) {
			return sheetData.every(
				({
					surName,
					firstName,
					email,
					licenseLabelCode,
					phoneNumber,
					licenseType,
					driverTypeEnum,
					licenseExpiryDate,
				}) => {
					const namesValid = !isEmpty(surName) && !isEmpty(firstName);
					const emailValid = validateEmail(email);
					const licenseCodeValid =
						isEmpty(licenseLabelCode) || codes.includes(licenseLabelCode);
					const phoneNumberValid = validatePhone(phoneNumber);
					const licenseTypeValid = isLicenseTypeValid(licenseType);
					const driverTypeValid = isDriverTypeValid(driverTypeEnum);
					const licenseExpiryDateValid =
						isEmpty(licenseExpiryDate) || validDateString(licenseExpiryDate);
					const valid =
						namesValid &&
						licenseCodeValid &&
						licenseTypeValid &&
						driverTypeValid &&
						licenseExpiryDateValid &&
						(emailValid || phoneNumberValid);

					return valid;
				}
			);
		}
		return false;
	};

	const submitDrivers = () => {
		const drivers = sheetData.map(
			({
				surName,
				firstName,
				email,
				licenseLabelCode,
				phoneNumber,
				licenseType,
				driverTypeEnum,
				licenseExpiryDate,
			}) => ({
				surName: surName.trim(),
				firstName: firstName.trim(),
				email: validateEmail(email) ? email : null,
				phoneNumber: validatePhone(phoneNumber) ? phoneNumber : null,
				licenseLabelCode: licenseLabelCode || "00000",
				licenseType: getLicenseCode(licenseType),
				licenseIntervalDays: permissions.getControlIntervalDays(),
				driverTypeEnum: getDriverType(driverTypeEnum),
				licenseExpiryDate: validDateString(licenseExpiryDate)
					? parseDate(licenseExpiryDate)
					: null,
				contactType: validateEmail(email) ? 0 : 1,
			})
		);

		customProvider
			.customBulk("drivers", {
				action: "create",
				drivers,
			})
			.then(() => notify("common.success"));
	};

	const tableColumns = [
		{ title: translate("drivers.fields.surName"), field: "surName" },
		{ title: translate("drivers.fields.firstName"), field: "firstName" },
		{
			title: translate("drivers.fields.email"),
			field: "email",
			render: rowData => (
				<span style={cellStyle(validateEmail(rowData.email) || isEmpty(rowData.email))}>
					{rowData.email}
				</span>
			),
		},
		{
			title: translate("drivers.fields.phoneNumber"),
			field: "phoneNumber",
			render: rowData => (
				<span
					style={cellStyle(
						validatePhone(rowData.phoneNumber) || isEmpty(rowData.phoneNumber)
					)}
				>
					{rowData.phoneNumber}
				</span>
			),
		},
		{
			title: translate("drivers.fields.licenseLabelCode"),
			field: "licenseLabelCode",
			render: rowData => (
				<span
					style={cellStyle(
						isEmpty(rowData.licenseLabelCode) ||
							isLabelCodeValid(rowData.licenseLabelCode)
					)}
				>
					{rowData.licenseLabelCode}
				</span>
			),
		},
		{
			title: translate("drivers.fields.licenseType"),
			field: "licenseType",
			render: rowData => (
				<span style={cellStyle(isLicenseTypeValid(rowData.licenseType))}>
					{rowData.licenseType}
				</span>
			),
		},
		{
			title: translate("drivers.fields.driverTypeEnum"),
			field: "driverTypeEnum",
			render: rowData => (
				<span style={cellStyle(isDriverTypeValid(rowData.driverTypeEnum))}>
					{rowData.driverTypeEnum}
				</span>
			),
		},
		{ title: translate("drivers.fields.licenseExpiryDate"), field: "licenseExpiryDate" },
	];

	return (
		<Box width="100%">
			<TitleBreadcrumbs
				title={translate("pages.driverUpload.title")}
				path={[
					{
						label: translate("pages.driverCreate.title"),
						to: "/#/drivers/create",
					},
				]}
			/>
			<Paper style={{ marginTop: 5 }}>
				<Box display="flex" alignItems="center" padding={2}>
					<Button
						style={{ marginRight: 20 }}
						variant="contained"
						component="label"
						color="primary"
					>
						Upload Excel/CSV
						<input
							ref={inputElement}
							hidden
							id="upload"
							type="file"
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/csv"
							onChange={handleFileChange}
						/>
					</Button>

					{isSheetValid() ? (
						<Button
							style={{ marginLeft: 20 }}
							variant="contained"
							color="primary"
							onClick={submitDrivers}
						>
							{translate("pages.driverUpload.import")}
						</Button>
					) : (
						<a download href={importTemplate}>
							Download Template
						</a>
					)}
				</Box>
				{sheetData.length > 0 && (
					<MaterialTable
						icons={tableIcons}
						title={translate("pages.driverUpload.preview")}
						columns={tableColumns}
						data={sheetData}
						localization={{
							body: {
								editTooltip: translate("ra.action.edit"),
								deleteTooltip: translate("ra.action.delete"),
								editRow: { deleteText: translate("common.confirm") },
							},
						}}
						options={{
							search: false,
							paging: false,
						}}
						editable={{
							onRowUpdate: (newData, oldData) => {
								const data = [...sheetData];
								data[data.indexOf(oldData)] = newData;
								setSheetData(data);
								return Promise.resolve();
							},
							onRowDelete: oldData => {
								const data = [...sheetData];
								data.splice(data.indexOf(oldData), 1);
								setSheetData(data);
								return Promise.resolve();
							},
						}}
					/>
				)}
			</Paper>
		</Box>
	);
};

export default DriverUploadPage;
