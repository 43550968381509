import { baseApi } from "../common/envUtils";

import httpClient from "./httpClient";

const settingsProvider = {
	getSettings: () =>
		httpClient(`${baseApi}/user-settings`).then(({ json }) => {
			const settings = { ...json };
			if (json.driverFilter) {
				Object.assign(settings, { driverFilter: JSON.parse(json.driverFilter) });
			}
			if (json.driverSort) {
				Object.assign(settings, { driverSort: JSON.parse(json.driverSort) });
			}
			return settings;
		}),
	updateSettings: async settings =>
		httpClient(`${baseApi}/user-settings`, {
			method: "POST",
			body: JSON.stringify(settings),
		}).then(({ json }) => json),
};

export default settingsProvider;
