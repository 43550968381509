import CheckBox from "@material-ui/icons/CheckBox";
import React from "react";

const BoolField = ({ record, source }) => {
	if (record && record[source]) {
		return (
			<span>
				<CheckBox color="primary" />
			</span>
		);
	}
	return <span>-</span>;
};

export default BoolField;
