import { baseApi } from "../common/envUtils";

import httpClient from "./httpClient";

const authProvider = {
	login: ({ username, password, twoFactorAuthCode }) => {
		const url = `${baseApi}/auth`;
		return httpClient(url, {
			method: "POST",
			body: JSON.stringify({ email: username, password, twoFactorAuthCode }),
		}).then(({ body }) => {
			const jsonBody = JSON.parse(body);
			const { accessToken, data } = jsonBody;
			localStorage.setItem("token", accessToken);
			localStorage.setItem("user", JSON.stringify(data));
			return jsonBody;
		});
	},
	logout: () => {
		localStorage.clear();
		return Promise.resolve();
	},
	reset: ({ username }) => {
		const url = `${baseApi}/auth/password/reset`;
		return httpClient(url, {
			method: "POST",
			body: JSON.stringify({ name: username }),
		}).then(({ body }) => JSON.parse(body));
	},
	verify: ({ username, resetCode, newPassword }) => {
		const url = `${baseApi}/auth/password/verify`;
		return httpClient(url, {
			method: "POST",
			body: JSON.stringify({ name: username, resetCode, newPassword }),
		}).then(({ body }) => JSON.parse(body));
	},
	checkAuth: () =>
		localStorage.getItem("token") ? Promise.resolve() : Promise.reject({ message: false }),
	checkError: error => {
		const { status } = error;
		if (status === 401 || status === 403) {
			localStorage.clear();
			return Promise.reject({ message: false });
		}
		return Promise.resolve();
	},
	getPermissions: () => Promise.resolve(JSON.parse(localStorage.getItem("user"))),
	switchTenant: tenantId => {
		const url = `${baseApi}/auth/switchTenant/${tenantId}`;
		return httpClient(url, { method: "POST" }).then(({ body }) => {
			const { accessToken, data } = JSON.parse(body);
			localStorage.setItem("token", accessToken);
			localStorage.setItem("user", JSON.stringify(data));
		});
	},
	password: ({ oldPassword, newPassword }) => {
		const url = `${baseApi}/auth/password`;
		return httpClient(url, {
			method: "PUT",
			body: JSON.stringify({ newPassword, oldPassword }),
		});
	},
};

export default authProvider;
