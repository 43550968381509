import { stringify } from "query-string";

import { baseApi } from "../common/envUtils";

import httpClient from "./httpClient";

const statsProvider = {
	getDashboard: async ({ tenantIds }) => {
		const query = {};
		if (tenantIds) {
			query.fk_tenantId = tenantIds;
		}

		const queryString = stringify(query, {
			skipEmptyString: true,
			skipNull: true,
		});
		const dashboardApi = "/v2/stat/dashboard";

		const url = queryString
			? `${baseApi}${dashboardApi}?${queryString}`
			: `${baseApi}${dashboardApi}`;
		// await httpClient(`${baseApi}/stat/dashboard`, { method: 'POST' });
		return httpClient(url).then(({ json }) => json);
	},
	getMonitoring: ({ startDate, endDate }) => {
		const query = { startDate: startDate || undefined, endDate: endDate || undefined };

		const url = `${baseApi}/stat/monitoring?${stringify(query)}`;
		return httpClient(url).then(({ json }) => json);
	},
};

export default statsProvider;
