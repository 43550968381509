import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { useCallback, useEffect, useState } from "react";
import {
	required,
	TextInput,
	useAuthProvider,
	useDataProvider,
	useNotify,
	usePermissions,
	useTranslate,
} from "react-admin";
import { Form } from "react-final-form";

import { baseApi } from "../common/envUtils";
import PermissionUtils from "../common/permissionUtils";
import { validatePasswordPolicy } from "../common/validateUtils";
import FormSubtitle from "../components/form/FormSubtitle";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";
import httpClient from "../providers/httpClient";

import TwoFAFlow from "./TwoFAFlow";

const useStyles = makeStyles(() => ({
	card: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: "26px 32px",
		maxWidth: 450,
		margin: "12px 0",
		flex: 1,
	},
}));

const SettingsPage = () => {
	const classes = useStyles();
	const translate = useTranslate();
	const notify = useNotify();
	const authProvider = useAuthProvider();
	const dataProvider = useDataProvider();
	const permissions = new PermissionUtils(usePermissions());
	const [initValue, setInitValue] = useState({});
	const [user, setUser] = useState(null);

	const id = permissions.getId();

	const fetchDriver = useCallback(id => {
		dataProvider
			.getOne("users", { id })
			.then(res => {
				if (res?.data?.id) {
					setUser(res.data);
				}
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (id) {
			fetchDriver(id);
		}
	}, [id]);

	const submit = values => {
		const { oldPassword, newPassword } = values;
		if (oldPassword && newPassword) {
			authProvider.password({ oldPassword, newPassword }).then(() => {
				notify("common.success");
				setInitValue({});
			});
		}
	};

	const validateSamePw = values => {
		const errors = {};
		if (
			values.newPassword &&
			values.newPasswordConfirm &&
			values.newPassword !== values.newPasswordConfirm
		) {
			errors.newPasswordConfirm = translate(
				"pages.settings.changePassword.notIdenticalError"
			);
		}
		return errors;
	};

	const handleTwoFacDisable = async () => {
		await dataProvider.update("users", {
			id: permissions.getId(),
			data: { twoFactorEnabled: false },
		});
		if (id) {
			await fetchDriver(id);
		}
	};

	const handleTwoFacEnable = async twoFactorAuthCode => {
		const url = `${baseApi}/auth/2fa/turn-on`;
		try {
			await httpClient(url, {
				method: "POST",
				body: JSON.stringify({ twoFactorAuthCode }),
			});
			notify("common.success");
		} catch {
			notify("common.fail");
		}
	};

	return (
		<div>
			<TitleBreadcrumbs title={translate("pages.settings.title")} />
			<Box display="flex" flexDirection="row" flexWrap="wrap">
				<Paper className={classes.card}>
					<Form validate={validateSamePw} initialValues={initValue} onSubmit={submit}>
						{({ handleSubmit, pristine, error, invalid }) => (
							<form style={{ width: "100%" }} onSubmit={handleSubmit}>
								<FormSubtitle>
									{translate("pages.settings.changePassword.title")}
								</FormSubtitle>
								<TextInput
									fullWidth
									label={translate("pages.settings.changePassword.oldPassword")}
									name="oldPassword"
									type="password"
									variant="outlined"
									margin="none"
									validate={required()}
								/>
								<TextInput
									fullWidth
									label={translate("pages.settings.changePassword.newPassword")}
									name="newPassword"
									type="password"
									variant="outlined"
									margin="none"
									validate={[required(), validatePasswordPolicy(translate)]}
								/>
								<TextInput
									fullWidth
									name="newPasswordConfirm"
									type="password"
									variant="outlined"
									margin="none"
									validate={[required()]}
									label={translate(
										"pages.settings.changePassword.newPasswordConfirm"
									)}
								/>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={pristine || error || invalid}
								>
									{translate("pages.settings.changePassword.submitButton")}
								</Button>
							</form>
						)}
					</Form>
				</Paper>

				<Paper className={classes.card}>
					{user ? (
						<TwoFAFlow
							user={user}
							onActivate={handleTwoFacEnable}
							onDeactivate={handleTwoFacDisable}
						/>
					) : (
						<span />
					)}
					<Box width="100%" display="flex" flexDirection="row" justifyContent="end">
						<a href="/third-party-licenses.txt" target="_blank">
							Third Party Licenses
						</a>
					</Box>
				</Paper>
			</Box>
		</div>
	);
};

export default SettingsPage;
