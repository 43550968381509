import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import {
	BulkDeleteButton,
	Datagrid,
	DatagridBody,
	List,
	ReferenceField,
	TextField,
	usePermissions,
	useTranslate,
} from "react-admin";

import PermissionUtils from "../common/permissionUtils";
import BatchActionButton from "../components/BatchActionButton";
import ControlStatusField from "../components/table/ControlStatusField";
import MyPagination from "../components/table/Pagination";
import StatusField from "../components/table/StatusField";
import { useIsMigratedMATM } from "../hooks/global-view-v2";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

import DriverBatchMove from "./DriverBatchMove";
import DriverFilter from "./DriverFilter";

const statusColors = status => {
	switch (status) {
		case -1:
		case 6:
		case 4: {
			return "primary";
		}
		case 0: {
			return "red";
		}
		case 1:
		case 3:
		case 5: {
			return "yellow";
		}
		case 2: {
			return "green";
		}
		default: {
			return "gray";
		}
	}
};

const driverColors = (driver, override = null) => {
	if (!driver.active) {
		return "gray";
	}
	if (override) {
		return statusColors(override);
	}

	const status = driver.uvvActive ? driver.statusCombined : driver.statusFS;
	return statusColors(status);
};

const driverRowColor = driver => {
	if (!driver.active) {
		return "gray";
	}
	if (
		driver.statusFS === 0 ||
		driver.statusUVV === 0 ||
		driver.statusUVVLKW === 0 ||
		(driver.ControlStatuses && driver.ControlStatuses.some(s => s.status === 0))
	) {
		return "red";
	}

	return "white";
};

const useListStyles = makeStyles({
	headerCell: {
		fontWeight: 600,
		padding: 12,
		textAlign: "center",
	},
});

const controlStatusColor = (record, controlType) => {
	const controlStatus = record.ControlStatuses.find(c => c.controlType === controlType);

	if (!controlStatus) {
		return "primary";
	}

	return statusColors(controlStatus.status);
};

const useStyles = makeStyles(theme => ({
	rowRoot: {
		overflow: "visible",
		padding: 0,
		backgroundColor: ({ driverData }) => theme.palette[driverRowColor(driverData)].light,
		cursor: "pointer",
	},
	fskCell: {
		backgroundColor: ({ driverData }) =>
			theme.palette[driverColors(driverData, driverData.statusFS)].light,
	},
	uvvCell: {
		backgroundColor: ({ driverData }) =>
			theme.palette[driverColors(driverData, driverData.statusUVV)].light,
	},
	uvvLkwCell: {
		backgroundColor: ({ driverData }) =>
			theme.palette[driverColors(driverData, driverData.statusUVVLKW)].light,
	},
	commentCell: {
		backgroundColor: "white",
	},
	selectCell: {
		padding: "0 12px 0 16px",
		maxHeight: 45,
		"& > *": {
			padding: 0,
		},
	},
	tableCell: {
		height: 44,
		paddingTop: 0,
		paddingBottom: 0,
		textAlign: "center",
	},
	indicatorContainer: {
		position: "relative",
	},
	indicator: {
		backgroundColor: ({ driverData }) => theme.palette[driverColors(driverData)].main,
		left: -22,
		top: -10,
		width: 6,
		height: 44,
		borderTopLeftRadius: 95,
		borderBottomLeftRadius: 95,
		position: "absolute",
	},
}));

const MyDatagridRow = ({
	record,
	resource,
	id,
	onToggleItem,
	children,
	selected,
	basePath,
	rowClick,
}) => {
	const classes = useStyles({ driverData: record });
	const theme = useTheme();
	const isMigratedMATM = useIsMigratedMATM();

	const handleToggleItem = (itemId, event) => {
		event.stopPropagation();
		onToggleItem(itemId, event);
	};
	if (!record) {
		return null;
	}
	return (
		<TableRow key={id} classes={{ root: classes.rowRoot }} onClick={() => rowClick(id)}>
			<TableCell className={classes.selectCell}>
				<div className={classes.indicatorContainer}>
					<div className={classes.indicator} />
				</div>
				<Checkbox
					color="primary"
					disabled={record.selectable}
					checked={selected}
					onClick={event => handleToggleItem(id, event)}
				/>
			</TableCell>
			{React.Children.map(children.filter(Boolean), field => (
				<TableCell
					key={`${id}-${field.props.source}`}
					className={classnames({
						[classes.tableCell]: true,
						[classes.fskCell]: field.props.source === "statusFS",
						[classes.uvvCell]: field.props.source === "statusUVV",
						[classes.uvvLkwCell]: field.props.source === "statusUVVLKW",
						[classes.commentCell]:
							field.props.source && field.props.source.includes("Comment"),
					})}
					style={{
						backgroundColor: field.props.controlType
							? theme.palette[controlStatusColor(record, field.props.controlType)]
									.light
							: undefined,
					}}
				>
					{React.cloneElement(field, {
						record,
						basePath,
						resource,
						// Remove the link on groups if the organization has been migrated
						link:
							field.props.reference === "groups" && isMigratedMATM
								? null
								: field.props.link,
					})}
				</TableCell>
			))}
		</TableRow>
	);
};

const accordionClasses = makeStyles(theme => ({
	root: {
		boxShadow: "unset",
		backgroundColor: "rgb(250, 251, 253)",
	},
	expanded: {
		boxShadow: theme.shadows[0],
		backgroundColor: "white",
	},
}));
const handleEditClick = event => {
	event.stopPropagation(); // Prevent row click event from firing
};
const EditIconButton = ({ record }) => (
	<IconButton
		component="a"
		href={`/driver/edit/${record.id}`}
		color="primary"
		aria-label="edit"
		onClick={handleEditClick} // Attach the click handler
	>
		<EditIcon />
	</IconButton>
);

const MyDatagridBody = properties => <DatagridBody {...properties} row={<MyDatagridRow />} />;

const DriverList = properties => {
	const { options, history } = properties;
	const translate = useTranslate();
	const listClasses = useListStyles();
	const permissions = new PermissionUtils(usePermissions());
	const isMigratedMATM = useIsMigratedMATM();

	const [additionalUVVs, setAdditionalUVVs] = useState([]);

	useEffect(() => {
		if (
			permissions.isLoggedIn() &&
			permissions.getAdditionalUVVs() &&
			additionalUVVs.length !== permissions.getAdditionalUVVs().length
		) {
			setAdditionalUVVs(permissions.getAdditionalUVVs());
		}
	}, [permissions]);

	const DriverBulkActionButtons = p => {
		if (permissions.canEdit()) {
			return (
				<Accordion square classes={accordionClasses()}>
					<AccordionSummary
						expandIcon={<MoreVertIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					/>
					<AccordionDetails>
						<Box display="flex" flexDirection="column">
							{permissions.canManage() && <BulkDeleteButton {...p} />}
							<BatchActionButton
								label="drivers.actions.active"
								action="update"
								bodyAttr="driverIds"
								attributes={{
									attributes: {
										active: true,
									},
								}}
								{...p}
							/>
							<BatchActionButton
								label="drivers.actions.inactive"
								action="update"
								bodyAttr="driverIds"
								attributes={{
									attributes: {
										active: false,
									},
								}}
								{...p}
							/>

							<BatchActionButton
								label="drivers.actions.control"
								action="trigger/adhoc"
								bodyAttr="driverIds"
								{...p}
							/>
							<BatchActionButton
								label="drivers.actions.welcome"
								action="trigger/welcome"
								bodyAttr="driverIds"
								{...p}
							/>
							{permissions.isIncludeUvv() && (
								<BatchActionButton
									label="drivers.actions.uvv"
									action="trigger/uvv"
									bodyAttr="driverIds"
									{...p}
								/>
							)}
							{permissions.isIncludeUvvLkw() && (
								<BatchActionButton
									label="drivers.actions.uvvLkw"
									action="trigger/uvv-lkw"
									bodyAttr="driverIds"
									{...p}
								/>
							)}
							{additionalUVVs.map(ct => (
								<BatchActionButton
									key={ct}
									label={`${translate(`tenants.uvvs.${ct}`)} ${translate("tenants.uvvs.request")}`}
									bodyAttr="driverIds"
									action="cuvv"
									attributes={{
										courseId: ct,
									}}
									{...p}
									resource="c-controls"
								/>
							))}
							{permissions.hasPhotoRollout() && (
								<BatchActionButton
									label="drivers.actions.rollout"
									action="trigger/rollout"
									bodyAttr="driverIds"
									{...p}
								/>
							)}
							{permissions.isGroupAdmin() && <DriverBatchMove {...p} />}
						</Box>
					</AccordionDetails>
				</Accordion>
			);
		}

		return null;
	};

	const handleRowClick = id => {
		history.push(`/drivers/${id}/show`);
	};

	return (
		<div>
			<TitleBreadcrumbs title={translate(options.label)} />
			<List
				pagination={<MyPagination />}
				actions={null}
				hasCreate={permissions.canManage()}
				empty={false}
				filters={<DriverFilter history={history} />}
				bulkActionButtons={<DriverBulkActionButtons />}
				perPage={25}
				{...properties}
			>
				<Datagrid rowClick={handleRowClick} classes={listClasses} body={<MyDatagridBody />}>
					<TextField source="surName" label="drivers.fields.surName" />
					<TextField source="firstName" label="drivers.fields.firstName" />
					<TextField source="Tenant.name" label="drivers.fields.tenant" />
					{(permissions.isTenantAdmin() || permissions.isSuperAdmin()) && (
						<ReferenceField
							source="Tenant.fk_groupId"
							reference="groups"
							label={isMigratedMATM ? "drivers.fields.area" : "drivers.fields.group"}
						>
							<TextField source="name" />
						</ReferenceField>
					)}
					<StatusField source="statusFS" label="drivers.fields.statusFS" />
					{permissions.isIncludeUvv() && (
						<StatusField source="statusUVV" label="drivers.fields.statusUVV" />
					)}
					{permissions.isIncludeUvvLkw() && (
						<StatusField source="statusUVVLKW" label="drivers.fields.statusUVVLKW" />
					)}
					{additionalUVVs.map(ct => (
						<ControlStatusField
							key={ct}
							controlType={ct}
							label={`tenants.uvvs.${ct}`}
						/>
					))}
					{permissions.getCommentField1Name() && (
						<TextField
							source="licenseComment1"
							label={permissions.getCommentField1Name()}
						/>
					)}

					{permissions.getCommentField2Name() && (
						<TextField
							source="licenseComment2"
							label={permissions.getCommentField2Name()}
						/>
					)}
					{permissions.isExternal() && (
						<TextField source="group" label="drivers.fields.externalGroup" />
					)}
					{(permissions.isTenantAdmin() || permissions.isSuperAdmin()) && (
						<EditIconButton />
					)}
				</Datagrid>
			</List>
		</div>
	);
};

export default DriverList;
