/* eslint-disable react/no-array-index-key */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FilterListIcon from "@material-ui/icons/FilterList";
import { isNil } from "lodash";
import React from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles(theme => ({
	container: {
		padding: "12px 16px",
		backgroundColor: theme.palette.white.main,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	},
	chip: {
		marginRight: 10,
		color: "black",
		backgroundColor: theme.palette.primary.light,
	},
}));

const FilterChipBar = ({ filterValues, onFilterChange, filterDisplay }) => {
	const translate = useTranslate();
	const classes = useStyles();

	const clearFilter = () => {
		onFilterChange({});
	};

	if (Object.keys(filterValues).filter(k => k !== "q").length === 0) {
		return null;
	}

	const handleFilterValueDelete = (key, value) => {
		onFilterChange({
			...filterValues,
			[key]: Array.isArray(filterValues[key])
				? filterValues[key].filter(v => v !== value)
				: null,
		});
	};

	const renderChips = () =>
		Object.keys(filterValues)
			.filter(fv => !fv.includes("fk_"))
			.flatMap(key => {
				if (filterValues[key] && Array.isArray(filterValues[key])) {
					return filterValues[key].map(value => ({
						key,
						value,
						label: filterDisplay[key] && filterDisplay[key].values[value],
						icon: filterDisplay[key] && filterDisplay[key].icon,
					}));
				}
				if (filterValues[key] && !isNil(filterValues[key])) {
					return {
						key,
						value: filterValues[key],
						label: filterDisplay[key] && filterDisplay[key].values[filterValues[key]],
						icon: filterDisplay[key] && filterDisplay[key].icon,
					};
				}
				return [];
			})
			.map(({ key, value, label, icon }, index) => (
				<Chip
					key={`${index}:${value}`}
					clickable
					icon={icon}
					className={classes.chip}
					label={label || value}
					variant="outlined"
					color="primary"
					onDelete={() => handleFilterValueDelete(key, value)}
				/>
			));

	return (
		<Box
			boxShadow={1}
			className={classes.container}
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
		>
			<FilterListIcon color="primary" />
			<Box ml={2} flex={3} display="flex" flexDirection="row" alignItems="center">
				{renderChips()}
			</Box>
			<Button color="primary" onClick={clearFilter}>
				{translate("common.clearAll")}
			</Button>
		</Box>
	);
};

export default FilterChipBar;
