import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useTranslate } from "react-admin";

import { baseApi } from "../common/envUtils";
import httpClient from "../providers/httpClient";

const TwoFAFlow = ({ user, onActivate, onDeactivate }) => {
	const translate = useTranslate();
	const [enableFlow, setEnableFlow] = useState(false);
	const [qrCode, setQrCode] = useState(null);
	const [twoFactorAuthCode, setTwoFactorAuthCode] = useState("");

	const handleEnableFlow = async () => {
		setEnableFlow(true);
		const url = `${baseApi}/auth/2fa/generate`;
		const { body } = await httpClient(url, { method: "POST" });
		setQrCode(body);
	};

	const handleActivate = async () => {
		onActivate(twoFactorAuthCode);
	};

	return (
		<Box display="flex" flexDirection="column">
			<Typography variant="subtitle1">{translate("pages.settings.twoFa.title")} </Typography>

			{!enableFlow && (
				<Box>
					{user.twoFactorEnabled ? (
						<Button
							color="primary"
							variant="contained"
							type="submit"
							onClick={onDeactivate}
						>
							{translate("pages.settings.twoFa.deactivate")}
						</Button>
					) : (
						<Button
							color="primary"
							variant="contained"
							type="submit"
							onClick={handleEnableFlow}
						>
							{translate("pages.settings.twoFa.activate")}
						</Button>
					)}
				</Box>
			)}

			{enableFlow && (
				<Box display="flex" flexDirection="column" alignItems="center">
					<Typography variant="subtitle2">
						{translate("pages.settings.twoFa.hint")}
					</Typography>

					{qrCode && (
						<Box
							width="100%"
							display="flex"
							flexDirection="row"
							justifyContent="center"
						>
							<img src={qrCode} alt="qr-code" />
						</Box>
					)}

					<Box margin={1}>
						<TextField
							fullWidth
							name="twoFaCode"
							value={twoFactorAuthCode}
							label={translate("pages.settings.twoFa.twoFactorAuthCode")}
							variant="outlined"
							onChange={e => setTwoFactorAuthCode(e.target.value)}
						/>
					</Box>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						disabled={!twoFactorAuthCode || twoFactorAuthCode.length < 4}
						onClick={handleActivate}
					>
						{translate("pages.settings.twoFa.activate")}
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default TwoFAFlow;
