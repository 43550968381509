import React from "react";
import {
	DeleteButton,
	Edit,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useTranslate,
} from "react-admin";

import FormHtmlEditor from "../components/form/FormHtmlEditor";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

const TemplateEditToolbar = properties => {
	const { invalid, pristine, saving, handleSubmitWithRedirect } = properties;

	return (
		<Toolbar
			{...properties}
			style={{
				backgroundColor: "inherit",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			}}
		>
			<SaveButton
				variant="contained"
				saving={saving}
				handleSubmitWithRedirect={handleSubmitWithRedirect}
				label="templates.actions.save"
				redirect={false}
				submitOnEnter={false}
				disabled={invalid || pristine}
			/>
			<DeleteButton label="templates.actions.reset" />
		</Toolbar>
	);
};

const TemplateEdit = properties => {
	const translate = useTranslate();
	return (
		<div>
			<TitleBreadcrumbs
				path={[{ label: translate("pages.admin.title"), to: "tenants" }]}
				title={translate("templates.actions.edit")}
			/>
			<Edit {...properties}>
				<SimpleForm margin={10} toolbar={<TemplateEditToolbar />}>
					<TextInput
						source="subject"
						label="templates.fields.subject"
						variant="outlined"
						margin="none"
						validate={required()}
					/>
					<TextInput
						multiline
						fullWidth
						source="smsBody"
						label="templates.fields.smsBody"
						variant="outlined"
						margin="none"
					/>
					<FormHtmlEditor source="body" />
				</SimpleForm>
			</Edit>
		</div>
	);
};

export default TemplateEdit;
