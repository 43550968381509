import React from "react";
import {
	Create,
	FormWithRedirect,
	SaveButton,
	useRedirect,
	useRefresh,
	useTranslate,
} from "react-admin";

import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

import SuperTenantForm from "./SuperTenantForm";

const SuperTenantCreateToolbar = properties => {
	const { invalid, pristine, saving, handleSubmitWithRedirect } = properties;
	return (
		<div className={{ backgroundColor: "inherit" }}>
			<SaveButton
				redirect
				variant="contained"
				saving={saving}
				handleSubmitWithRedirect={handleSubmitWithRedirect}
				label="superTenants.actions.save"
				submitOnEnter={false}
				disabled={invalid || pristine}
			/>
		</div>
	);
};

const SuperTenantCreate = properties => {
	const translate = useTranslate();
	const refresh = useRefresh();
	const redirect = useRedirect();

	const onSuccess = ({ data }) => {
		redirect(`/super-tenants/${data.id}/edit`);
		refresh();
	};

	return (
		<div>
			<TitleBreadcrumbs
				path={[{ label: translate("pages.admin.title"), to: "superTenants" }]}
				title={translate("superTenants.actions.create")}
			/>
			<Create {...properties} onSuccess={onSuccess}>
				<FormWithRedirect
					render={formProperties => (
						<SuperTenantForm
							formProps={formProperties}
							toolbar={<SuperTenantCreateToolbar {...formProperties} />}
						/>
					)}
				/>
			</Create>
		</div>
	);
};

export default SuperTenantCreate;
