import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { Create, FormWithRedirect, SaveButton, useTranslate } from "react-admin";

import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

import BusinessUnitForm from "./BusinessUnitForm";

const useStyles = makeStyles(() => ({
	toolbar: {
		backgroundColor: "inherit",
	},
}));

const BUCreateToolbar = properties => {
	const classes = useStyles();
	const { invalid, pristine, saving, handleSubmitWithRedirect } = properties;
	return (
		<div className={classes.toolbar}>
			<SaveButton
				variant="contained"
				saving={saving}
				handleSubmitWithRedirect={handleSubmitWithRedirect}
				label="businessUnits.actions.save"
				redirect={false}
				submitOnEnter={false}
				disabled={invalid || pristine}
			/>
		</div>
	);
};

const BusinessUnitCreate = properties => {
	const translate = useTranslate();

	return (
		<div style={{ maxWidth: 600 }}>
			<TitleBreadcrumbs
				path={[{ label: translate("pages.admin.title"), to: "businessUnits" }]}
				title={translate("businessUnits.actions.create")}
			/>
			<Create {...properties}>
				<FormWithRedirect
					render={formProperties => (
						<BusinessUnitForm
							formProps={formProperties}
							toolbar={<BUCreateToolbar {...formProperties} />}
						/>
					)}
				/>
			</Create>
		</div>
	);
};

export default BusinessUnitCreate;
