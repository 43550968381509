import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import React from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

import { formatIsoDate } from "../../common/dateUtils";

const useStyles = makeStyles(theme => ({
	tooltipContainer: {
		display: "flex",
		flexDirection: "column",
		borderRadius: 5,
		padding: 12,
		backgroundColor: theme.palette.white.main,
		opacity: 0.9,
	},
	label: {
		fontSize: 12,
		color: theme.palette.gray.main,
	},
	value: {
		fontSize: 14,
		color: "black",
	},
}));

const DailyCounterChart = ({ dailyCounters }) => {
	const classes = useStyles();
	const theme = useTheme();

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload.length > 0) {
			return (
				<div className={classes.tooltipContainer}>
					<span className={classes.label}>{formatIsoDate(label)}</span>
					<span className={classes.value}>{payload[0].value}</span>
				</div>
			);
		}

		return null;
	};

	return (
		<BarChart
			width={450}
			height={300}
			data={dailyCounters}
			margin={{
				top: 15,
			}}
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis ti dataKey="countersDate" tickFormatter={formatIsoDate} />
			<YAxis />
			<Tooltip content={<CustomTooltip />} />
			<Bar
				dataKey="dailyControls"
				fill={theme.palette.primary.main}
				label={{ fill: "white" }}
			/>
		</BarChart>
	);
};

export default DailyCounterChart;
