import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classnames from "classnames";
import React from "react";
import { Datagrid, List, NumberField, TextField, useListContext, useTranslate } from "react-admin";

import { formatDateAsIso } from "../common/dateUtils";
import DateInput from "../components/DateField";
import DateField from "../components/table/DateField";
import SearchFilter from "../components/table/SearchFilter";

import AdminHeader from "./AdminHeader";

const useStyles = makeStyles(theme => ({
	tableCell: {
		height: 44,
		paddingTop: 0,
		paddingBottom: 0,
		textAlign: "center",
	},
	green: {
		backgroundColor: theme.palette.green.light,
	},
	yellow: {
		backgroundColor: theme.palette.yellow.light,
	},
	red: {
		backgroundColor: theme.palette.red.light,
	},
	picker: {
		width: "48%",
	},
}));

const StatPerformanceFilter = properties => {
	const classes = useStyles();
	const translate = useTranslate();
	const { filterValues, setFilters } = useListContext();

	const handleDateChange = name => date => {
		setFilters({ ...filterValues, [name]: date ? formatDateAsIso(date) : null }, []);
	};

	if (properties.context === "button") {
		return null;
	}

	return (
		<SearchFilter {...properties}>
			<Box alwaysOn display="flex" flexDirection="row" justifyContent="space-between">
				<DateInput
					disableFuture
					clearable
					className={classes.picker}
					label={translate("common.startDate")}
					value={filterValues.startDate}
					maxDate={filterValues.endDate}
					onChange={handleDateChange("startDate")}
				/>
				<DateInput
					disableFuture
					clearable
					className={classes.picker}
					label={translate("common.endDate")}
					value={filterValues.endDate}
					minDate={filterValues.startDate}
					onChange={handleDateChange("endDate")}
				/>
			</Box>
		</SearchFilter>
	);
};

const StatPerformanceList = properties => {
	const { basePath } = properties;
	const classes = useStyles();
	return (
		<div>
			<AdminHeader active={basePath} />
			<List filters={<StatPerformanceFilter />} {...properties}>
				<Datagrid rowClick={null}>
					<TextField source="name" label="statsPerf.fields.name" />
					<DateField source="created" label="statsPerf.fields.created" />
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.yellow)}
						source="requested"
						label="statsPerf.fields.requested"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="successFirst"
						label="statsPerf.fields.successFirst"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="successSecond"
						label="statsPerf.fields.successSecond"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="success"
						label="statsPerf.fields.success"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.yellow)}
						source="eventScheduled"
						label="statsPerf.fields.eventScheduled"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="manualControlSa"
						label="statsPerf.fields.manualControlSa"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="manualControl"
						label="statsPerf.fields.manualControl"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="ratioSuccess"
						label="statsPerf.fields.ratioSuccess"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="ratioFirst"
						label="statsPerf.fields.ratioFirst"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="ratioSecond"
						label="statsPerf.fields.ratioSecond"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="ratioLicenseType0"
						label="statsPerf.fields.ratioLicenseType0"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="ratioLicenseType1"
						label="statsPerf.fields.ratioLicenseType1"
					/>
					<NumberField
						sortable={false}
						cellClassName={classnames(classes.tableCell, classes.green)}
						source="ratioLicenseType2"
						label="statsPerf.fields.ratioLicenseType2"
					/>
				</Datagrid>
			</List>
		</div>
	);
};

export default StatPerformanceList;
