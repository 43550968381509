const initialState = {
	username: "",
	resetCode: "",
	password: "",
	twoFactorAuthCode: null,
	twoFaEnabled: false,
};

export const setUsernameAction = username => ({
	type: "SET_USERNAME",
	payload: username,
});

export const setResetCodeAction = resetCode => ({
	type: "SET_RESETCODE",
	payload: resetCode,
});

export const setPasswordAction = password => ({
	type: "SET_PASSWORD",
	payload: password,
});

export const setTwoFactorAuthCode = twoFaCode => ({
	type: "SET_TWOFACTORAUTHCODE",
	payload: twoFaCode,
});

export const setTwoFaEnabled = twoFaEnabled => ({
	type: "SET_ TWOFA_ENABLED",
	payload: twoFaEnabled,
});

export default (previousState = initialState, { type, payload }) => {
	if (type === "SET_USERNAME") {
		return {
			...previousState,
			username: payload,
		};
	}
	if (type === "SET_RESETCODE") {
		return {
			...previousState,
			resetCode: payload,
		};
	}
	if (type === "SET_PASSWORD") {
		return {
			...previousState,
			password: payload,
		};
	}
	if (type === "SET_TWOFACTORAUTHCODE") {
		return {
			...previousState,
			twoFactorAuthCode: payload,
		};
	}
	if (type === "SET_ TWOFA_ENABLED") {
		return {
			...previousState,
			twoFaEnabled: payload,
		};
	}

	return previousState;
};
