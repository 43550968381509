import {
	isAfter,
	format,
	parseISO,
	formatISO,
	parse,
	isMatch,
	differenceInDays,
	addDays,
} from "date-fns";
import { de, enUS } from "date-fns/locale";

import i18nProvider from "./i18nProvider";

const locales = { de, enUS, en: enUS };

export const getLocale = () => locales[i18nProvider.getLocale()];

// eslint-disable-next-line import/prefer-default-export
export const formatIsoDate = dateString =>
	format(parseISO(dateString), "P", {
		locale: locales[i18nProvider.getLocale()],
	});

export const formatIsoTime = dateTimeString =>
	format(parseISO(dateTimeString), "p", {
		locale: locales[i18nProvider.getLocale()],
	});

export const formatIsoDateTime = dateTimeString =>
	format(parseISO(dateTimeString), "Pp", {
		locale: locales[i18nProvider.getLocale()],
	});

export const todayIsAfter = (date, amountDays) =>
	isAfter(new Date(), addDays(parseISO(date), amountDays));

export const daysTillLicenseCheck = (licenseUpdateDate, amountDays) =>
	differenceInDays(addDays(parseISO(licenseUpdateDate), amountDays), new Date());

export const nextLicenseCheck = (licenseUpdateDate, amountDays) =>
	format(addDays(parseISO(licenseUpdateDate), amountDays), "P", {
		locale: locales[i18nProvider.getLocale()],
	});

export const formatDateAsIso = date => formatISO(date, { representation: "date" });

export const parseDate = dateString => parse(dateString, "dd.MM.yyyy", new Date());

export const validDateString = dateString => isMatch(dateString, "dd.MM.yyyy");
