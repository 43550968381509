import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import {
	BulkDeleteButton,
	CreateButton,
	Datagrid,
	List,
	sanitizeListRestProps,
	TextField,
	TextInput,
	TopToolbar,
	useListContext,
	usePermissions,
	useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { connect } from "react-redux";

import AdminHeader from "../admin/AdminHeader";
import PermissionUtils from "../common/permissionUtils";
import { useOutlinedInputStyles } from "../common/theme";
import AutocompleteFilter from "../components/AutocompleteFilter";
import BoolField from "../components/table/BoolField";
import MyPagination from "../components/table/Pagination";

const UserFilter = ({ globalView }) => {
	const translate = useTranslate();
	const permissions = new PermissionUtils(usePermissions());
	const inputStyles = useOutlinedInputStyles();
	const { filterValues, setFilters } = useListContext();

	const handleTenantChange = selected => {
		setFilters({ ...filterValues, fk_tenantId: selected }, []);
	};

	const handleGroupChange = selected => {
		setFilters({ ...filterValues, fk_groupId: selected }, []);
	};

	const onHandleSubmit = values => {
		setFilters(values);
	};

	return (
		<Box width="100%">
			<Form initialValues={filterValues} onSubmit={onHandleSubmit}>
				{({ handleSubmit }) => (
					<form style={{ width: "100%" }} onSubmit={handleSubmit}>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="flex-start"
							mt={4}
							mb={2}
							width="100%"
						>
							<Box minWidth="50%">
								<TextInput
									resettable
									fullWidth
									alwaysOn
									helperText={false}
									source="q"
									label=""
									placeholder="Suchen"
									variant="outlined"
									margin="none"
									InputProps={{
										classes: inputStyles,
										startAdornment: (
											<InputAdornment>
												<SearchIcon />
											</InputAdornment>
										),
									}}
									onChange={() => handleSubmit()}
								/>
							</Box>
						</Box>
						<Box
							width="100%"
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="left"
							mt={1}
							mb={2}
						>
							<Box mr={1}>
								{(permissions.isSuperAdmin() ||
									permissions.isTenantAdmin() ||
									permissions.isGroupAdmin()) &&
									globalView && (
										<AutocompleteFilter
											resource="tenants"
											label={translate("users.fields.tenant")}
											selected={filterValues.fk_tenantId}
											width={300}
											onChange={handleTenantChange}
										/>
									)}
							</Box>
							{permissions.isTenantAdmin() && globalView && (
								<AutocompleteFilter
									resource="groups"
									label={translate("users.fields.group")}
									selected={filterValues.fk_groupId}
									width={300}
									onChange={handleGroupChange}
								/>
							)}
						</Box>
					</form>
				)}
			</Form>
		</Box>
	);
};

const UserActions = properties => {
	const { className, exporter, filters, maxResults, globalView, ...rest } = properties;

	const { basePath } = useListContext();

	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			<CreateButton variant="contained" basePath={basePath} label="users.actions.create" />
		</TopToolbar>
	);
};
const UserList = properties => {
	const { basePath, globalView } = properties;
	const permissions = new PermissionUtils(usePermissions());

	const BulkActionButtons = p => {
		if (permissions.canManage()) {
			return <BulkDeleteButton {...p} />;
		}
		return null;
	};

	return (
		<div>
			<AdminHeader active={basePath} />
			<List
				actions={permissions.isAdmin() && permissions.canManage() ? <UserActions /> : null}
				filters={<UserFilter globalView={globalView} />}
				pagination={<MyPagination />}
				bulkActionButtons={<BulkActionButtons />}
				hasCreate={permissions.canManage()}
				{...properties}
			>
				<Datagrid rowClick="show">
					<TextField source="surName" label="users.fields.surName" />
					<TextField source="firstName" label="users.fields.firstName" />
					<TextField source="email" label="users.fields.email" />
					{(permissions.isTenantAdmin() || permissions.isSuperAdmin()) && (
						<TextField source="Tenant.name" label="users.fields.tenant" />
					)}
					<BoolField source="canRead" label="users.fields.canRead" />
					<BoolField source="canEdit" label="users.fields.canEdit" />
					<BoolField source="canManage" label="users.fields.canManage" />
					<BoolField source="isNotified" label="users.fields.notified" />
				</Datagrid>
			</List>
		</div>
	);
};

const mapStateToProperties = state => ({
	globalView: state.settings.globalView,
});

export default connect(mapStateToProperties)(UserList);
