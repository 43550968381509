import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

import IndicatorCard from "../components/IndicatorCard";

const useStyles = makeStyles(theme => ({
	cardContent: {
		width: 450,
		marginBottom: 22,
	},
	separatorLine: {
		alignSelf: "center",
		width: "90%",
		backgroundColor: theme.palette.primary.light,
		height: 1,
	},
}));

const DashboardCard = ({ color, children }) => {
	const classes = useStyles();

	const renderContent = () => {
		if (Array.isArray(children)) {
			return children.map((c, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<React.Fragment key={index}>
					{c}
					{index < children.length - 1 && <div className={classes.separatorLine} />}
				</React.Fragment>
			));
		}
		return children;
	};

	return (
		<IndicatorCard color={color} classes={{ container: classes.cardContent }}>
			<Box width="100%" display="flex" flexDirection="column" justifyContent="space-around">
				{renderContent()}
			</Box>
		</IndicatorCard>
	);
};

export default DashboardCard;
