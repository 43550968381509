// in src/App.js
import * as React from "react";
import { Admin, Resource } from "react-admin";

import LogsList from "./admin/LogsList";
import StatPerformanceList from "./admin/StatPerformanceList";
import { ReactComponent as AdminIcon } from "./assets/icons/sidebar/admin.svg";
import { ReactComponent as AdminActiveIcon } from "./assets/icons/sidebar/admin_active.svg";
import { ReactComponent as DriverIcon } from "./assets/icons/sidebar/driver.svg";
import { ReactComponent as DriverActiveIcon } from "./assets/icons/sidebar/driver_active.svg";
import { ReactComponent as PhotoControlIcon } from "./assets/icons/sidebar/photo_control.svg";
import { ReactComponent as PhotoControlActiveIcon } from "./assets/icons/sidebar/photo_control_active.svg";
import BusinessUnitCreate from "./businessUnits/BusinessUnitCreate";
import BusinessUnitEdit from "./businessUnits/BusinessUnitEdit";
import BusinessUnitList from "./businessUnits/BusinessUnitList";
import i18nProvider from "./common/i18nProvider";
import theme from "./common/theme";
import customRoutes from "./customRoutes";
import DriverList from "./drivers/DriverList";
import DriverShow from "./drivers/DriverShow";
import GroupCreate from "./group/GroupCreate";
import GroupEdit from "./group/GroupEdit";
import GroupList from "./group/GroupList";
import InvoiceList from "./invoices/InvoiceList";
import SupertenantInvoiceList from "./invoices/SuperTenantInvoiceList";
import MyLayout from "./layout/Layout";
import MyLoginPage from "./layout/LoginPage";
import PhotoControlList from "./photoControls/PhotoControlList";
import PhotoControlShow from "./photoControls/PhotoControlShow";
import authProvider from "./providers/authProvider";
import resourceDataProvider from "./providers/resourceDataProvider";
import authReducer from "./redux/authReducer";
import settingsReducer from "./redux/settingsReducer";
import settingsSaga from "./redux/settingsSaga";
import statsReducer from "./redux/statsReducer";
import statsSaga from "./redux/statsSaga";
import SuperTenantCreate from "./superTenant/SuperTenantCreate";
import SuperTenantEdit from "./superTenant/SuperTenantEdit";
import SuperTenantList from "./superTenant/SuperTenantList";
import TemplateEdit from "./templates/TemplateEdit";
import TemplateList from "./templates/TemplateList";
import TenantCreate from "./tenant/TenantCreate";
import TenantEdit from "./tenant/TenantEdit";
import TenantList from "./tenant/TenantList";
import UserCreate from "./users/UserCreate";
import UserEdit from "./users/UserEdit";
import UserList from "./users/UserList";
import UserShow from "./users/UserShow";

const App = () => (
	<Admin
		disableTelemetry
		title=""
		theme={theme}
		dataProvider={resourceDataProvider}
		i18nProvider={i18nProvider}
		authProvider={authProvider}
		layout={MyLayout}
		loginPage={MyLoginPage}
		customReducers={{ auth: authReducer, stats: statsReducer, settings: settingsReducer }}
		customSagas={[statsSaga, settingsSaga]}
		customRoutes={customRoutes}
	>
		<Resource
			name="drivers"
			icon={DriverIcon}
			list={DriverList}
			show={DriverShow}
			options={{
				label: "pages.driverList.title",
				activeIcon: DriverActiveIcon,
			}}
		/>
		<Resource
			name="businessUnits"
			list={BusinessUnitList}
			create={BusinessUnitCreate}
			edit={BusinessUnitEdit}
			options={{
				noMenu: true,
				label: "businessUnits.plural",
			}}
		/>
		<Resource
			name="users"
			icon={AdminIcon}
			list={UserList}
			create={UserCreate}
			show={UserShow}
			edit={UserEdit}
			options={{
				noMenu: true,
				label: "pages.admin.title",
				activeIcon: AdminActiveIcon,
			}}
		/>
		<Resource
			name="tenants"
			list={TenantList}
			edit={TenantEdit}
			create={TenantCreate}
			options={{
				noMenu: true,
				label: "tenants.plural",
			}}
		/>
		<Resource
			name="super-tenants"
			list={SuperTenantList}
			edit={SuperTenantEdit}
			create={SuperTenantCreate}
			options={{
				noMenu: true,
				label: "superTenants.plural",
			}}
		/>
		<Resource
			name="photo-controls"
			icon={PhotoControlIcon}
			list={PhotoControlList}
			show={PhotoControlShow}
			options={{
				label: "photoControls.plural",
				activeIcon: PhotoControlActiveIcon,
				showFunc: permissions =>
					permissions &&
					(permissions.canEdit() ||
						permissions.hasPhotoRollout() ||
						permissions.hasPhotoControl()),
			}}
		/>
		<Resource
			name="stat_logs"
			list={LogsList}
			options={{
				noMenu: true,
				label: "logs.label",
			}}
		/>
		<Resource
			name="stat_perf"
			list={StatPerformanceList}
			options={{
				noMenu: true,
				label: "statsPerf.label",
			}}
		/>
		<Resource
			name="templates"
			list={TemplateList}
			edit={TemplateEdit}
			options={{
				noMenu: true,
				label: "templates.label",
			}}
		/>
		<Resource
			name="invoices"
			list={InvoiceList}
			options={{ noMenu: true, label: "invoices.label" }}
		/>
		<Resource
			name="supertenant-invoices"
			list={SupertenantInvoiceList}
			options={{ noMenu: true, label: "superTenantInvoices.label" }}
		/>
		<Resource
			name="groups"
			list={GroupList}
			edit={GroupEdit}
			create={GroupCreate}
			options={{ noMenu: true, label: "groups.plural" }}
		/>
	</Admin>
);

export default App;
