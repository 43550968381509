import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import {
	AutocompleteInput,
	DeleteButton,
	Edit,
	FormWithRedirect,
	ReferenceInput,
	required,
	SaveButton,
	TextInput,
	Toolbar,
	usePermissions,
	useTranslate,
} from "react-admin";

import PermissionUtils from "../common/permissionUtils";
import FormSubtitle from "../components/form/FormSubtitle";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

import GroupAssignList from "./GroupAssignList";

const useStyles = makeStyles(() => ({
	container: {
		padding: "20px 40px 30px 40px",
		flexDirection: "row",
		flexWrap: "wrap",
		width: "100%",
	},
	halfWidth: {
		flexBasis: "48%",
	},
}));

const GroupEditToolbar = properties => {
	const { invalid, pristine, saving, handleSubmitWithRedirect } = properties;
	const permissions = new PermissionUtils(usePermissions());

	return (
		<Toolbar
			{...properties}
			style={{
				backgroundColor: "inherit",
				display: "flex",
				flexDirection: "row",
				justifyContent: "start",
			}}
		>
			<SaveButton
				variant="contained"
				saving={saving}
				handleSubmitWithRedirect={handleSubmitWithRedirect}
				label="groups.actions.save"
				redirect="edit"
				submitOnEnter={false}
				disabled={invalid || pristine}
			/>
			{permissions.canManage() && <DeleteButton label="groups.actions.delete" />}
		</Toolbar>
	);
};

const GroupEdit = properties => {
	const translate = useTranslate();
	const classes = useStyles();

	return (
		<div>
			<TitleBreadcrumbs
				path={[{ label: translate("groups.plural"), to: "/#/groups" }]}
				title={translate("groups.actions.edit")}
			/>
			<Edit {...properties}>
				<FormWithRedirect
					render={formProperties => (
						<form className={classes.container}>
							<Box display="flex" flexDirection="row" width="100%">
								<Box minWidth={360} flex={1} display="flex" flexDirection="column">
									<FormSubtitle>
										{translate("groups.sections.masterData")}
									</FormSubtitle>

									<TextInput
										source="name"
										label="groups.fields.name"
										variant="outlined"
										margin="none"
										validate={required()}
									/>

									<ReferenceInput
										key="superTenant"
										label="groups.fields.superTenant"
										source="fk_superTenantId"
										reference="super-tenants"
										filter={null}
									>
										<AutocompleteInput
											fullWidth
											disabled
											margin="none"
											variant="outlined"
											optionText="name"
											validate={required()}
										/>
									</ReferenceInput>
								</Box>

								<Box
									minWidth={360}
									flex={2}
									pl={2}
									display="flex"
									flexDirection="column"
								>
									<FormSubtitle>
										{translate("groups.sections.tenants")}
									</FormSubtitle>

									<GroupAssignList
										superTenantId={formProperties.record.fk_superTenantId}
										groupId={formProperties.record.id}
									/>
								</Box>
							</Box>
							<GroupEditToolbar {...formProperties} />
						</form>
					)}
				/>
			</Edit>
		</div>
	);
};

export default GroupEdit;
