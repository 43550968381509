import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import MovieIcon from "@material-ui/icons/Movie";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import React, { useState } from "react";
import { useNotify, useTranslate } from "react-admin";

import { ReactComponent as OpenIcon } from "../assets/icons/open.svg";
import fskFile from "../assets/support/Digitale_Führerscheinkontrolle-easycheck_by_edding.pdf";
import rolloutFile from "../assets/support/Merkblatt Roll-Out_easycheck by edding.pdf";
import onboardingFile from "../assets/support/Onboarding_How_to_easycheck_Flyer.pdf";
import { baseApi } from "../common/envUtils";
import FormSubtitle from "../components/form/FormSubtitle";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";
import httpClient from "../providers/httpClient";

const useStyles = makeStyles(() => ({
	card: {
		display: "flex",
		flexDirection: "column",
		padding: "26px 32px",
		flex: 1,
		maxWidth: 500,
		minWidth: 250,
		margin: "12px 12px",
	},
	button: {
		margin: "15px 12px",
		width: 200,
	},
}));

const SupportPage = () => {
	const classes = useStyles();
	const translate = useTranslate();
	const notify = useNotify();

	const [text, setText] = useState("");

	const handleSend = () => {
		const url = `${baseApi}/users/support`;
		notify("common.supportRequestSent");

		httpClient(url, { method: "POST", body: JSON.stringify({ text }) })
			.then(() => notify("common.supportRequestSuccess"))
			.catch(() => notify("common.supportRequestError"));
	};

	const docs = [
		{ title: "Merkblatt Roll-Out", file: rolloutFile },
		{ title: "Digitale Führerscheinkontrolle mit edding", file: fskFile },
		{ title: "Onboarding How-to", file: onboardingFile },
		{
			title: "Anleitung zur Führerscheinkontrolle",
			file: "https://static.helpjuice.com/helpjuice_production/uploads/upload/image/5153/direct/1653390312681-02%20easycheck%20tutorial%201920x1080%20NO%20CHECKS-LOW.mp4",
			type: "video",
		},
	];

	return (
		<div>
			<TitleBreadcrumbs title={translate("pages.support.title")} />
			<Box display="flex" flexDirection="column" width="100%">
				<Button
					className={classes.button}
					color="primary"
					target="_blank"
					href="https://faq.edding.com/easycheck-by-edding?kb_language=de_DE"
					variant="contained"
					startIcon={<OpenIcon />}
				>
					{translate("pages.support.faq")}
				</Button>
				<Box display="flex" flexDirection="row" flexWrap="wrap" height="100%">
					<Paper className={classes.card}>
						<FormSubtitle>{translate("pages.support.downloads")}</FormSubtitle>
						{docs.map(document_ => (
							<List key={document_.title}>
								<ListItem
									button
									component="a"
									href={document_.file}
									target="_blank"
								>
									<ListItemAvatar>
										<Avatar>
											{document_.type === "video" ? (
												<MovieIcon />
											) : (
												<PdfIcon />
											)}
										</Avatar>
									</ListItemAvatar>
									<ListItemText primary={document_.title} />
								</ListItem>
							</List>
						))}
					</Paper>
					<Paper className={classes.card}>
						<FormSubtitle>{translate("pages.support.yourMessage")}</FormSubtitle>
						<TextField
							multiline
							variant="outlined"
							maxRows={15}
							value={text}
							onChange={e => setText(e.target.value)}
						/>
						<Button
							className={classes.button}
							color="primary"
							variant="contained"
							disabled={!text}
							onClick={handleSend}
						>
							{translate("pages.support.send")}
						</Button>
					</Paper>
				</Box>
			</Box>
		</div>
	);
};

export default SupportPage;
