import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslate } from "react-admin";

import IndicatorCard from "./IndicatorCard";

const useStyles = makeStyles({
	statusCard: {
		width: 160,
		height: 45,
	},
});

const statusColors = status => {
	switch (status) {
		case -1:
		case 6:
		case 4: {
			return "primary";
		}
		case 1:
		case 3:
		case 5: {
			return "yellow";
		}
		case 2: {
			return "green";
		}
		case 0: {
			return "red";
		}
		default: {
			return "gray";
		}
	}
};

const ControlStatusIndicator = ({ controlType, record }) => {
	const classes = useStyles();
	const translate = useTranslate();

	const controlStatus = record.ControlStatuses.find(c => c.controlType === controlType);

	const state = controlStatus ? controlStatus.status : 6;

	const getStatusText = status => {
		switch (status) {
			case 1:
			case 4:
			case 3:
			case 5: {
				return translate("drivers.states.pending");
			}
			case 2: {
				return translate("drivers.states.success");
			}
			case 0: {
				return translate("drivers.states.fail");
			}
			//
			// case 6:
			default: {
				return translate("drivers.states.created");
			}
		}
	};

	return (
		<Box>
			<Typography variant="subtitle2">{translate(`tenants.uvvs.${controlType}`)}</Typography>
			<IndicatorCard classes={{ container: classes.statusCard }} color={statusColors(state)}>
				<Box flex={1} pl={2} py={1}>
					<Typography variant="h6">{getStatusText(state)}</Typography>
				</Box>
			</IndicatorCard>
		</Box>
	);
};

export default ControlStatusIndicator;
