import Box from "@material-ui/core/Box";
import MuiButton from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { Button, Confirm, useRefresh, useTranslate, useUnselectAll } from "react-admin";

import customProvider from "../providers/customProvider";

const useStyles = makeStyles(theme => ({
	container: {
		padding: 24,
		display: "flex",
		flexDirection: "column",
		maxWidth: 800,
		maxHeight: 450,
		position: "absolute",
		top: "35%",
		left: "35%",
		backgroundColor: theme.palette.background.paper,
	},
}));

const handleListClick = id => {
	const win = window.open(`/drivers/${id}/show`, "_blank");
	win.focus();
};

const BatchActionButton = ({ selectedIds, action, bodyAttr, label, resource, attributes = {} }) => {
	const translate = useTranslate();

	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [skipped, setSkipped] = useState(null);

	const refresh = useRefresh();
	const unselectAll = useUnselectAll();

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = async () => {
		try {
			const { data } = await customProvider.customBulk(resource, {
				action,
				[bodyAttr]: selectedIds,
				...attributes,
			});
			if (data.skipped && data.skipped.length > 0) {
				setSkipped(data.skipped);
			} else {
				refresh();
				unselectAll(resource);
				setOpen(false);
			}
		} catch {
			// notify('common.fail');
			setOpen(false);
		}
	};

	const handleClose = () => {
		unselectAll(resource);
		refresh();
		setSkipped(null);
	};

	const renderValidDriverDialog = () =>
		skipped && (
			<Modal open={skipped && skipped.length > 0} onClose={handleClose}>
				<Paper className={classes.container}>
					<Typography mb={4} variant="h4">
						{translate("drivers.actions.stillValidNotification")}
					</Typography>
					<List style={{ maxHeight: "100%", overflow: "auto" }}>
						{skipped.map(({ firstName, surName, id }) => (
							<ListItem key={id} button onClick={() => handleListClick(id)}>
								<ListItemText>{`${firstName} ${surName}`}</ListItemText>
							</ListItem>
						))}
					</List>

					<Box display="flex" flexDirection="row" justifyContent="flex-end">
						<MuiButton color="primary" onClick={handleClose}>
							{translate("ra.action.confirm")}
						</MuiButton>
					</Box>
				</Paper>
			</Modal>
		);

	return (
		<>
			<Button label={label} style={{ padding: 10 }} onClick={handleClick} />
			<Confirm
				isOpen={open}
				title={label}
				content="common.confirm"
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
			{renderValidDriverDialog()}
		</>
	);
};

export default BatchActionButton;
