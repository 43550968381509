import Done from "@material-ui/icons/Done";
import React from "react";
import { useTranslate } from "react-admin";

import { formatIsoDate } from "../../common/dateUtils";

// const useStyles = makeStyles((theme) => ({
//   back,
// }));

const StatusField = ({ record, source }) => {
	const translate = useTranslate();

	const state = record[source];

	if (source === "statusUVV" && !record.uvvActive) {
		return <span />;
	}

	switch (state) {
		case 4: {
			return <span>{translate("drivers.tableStates.initPending")}</span>;
		}
		case 1:
		case 3:
		case 5: {
			return record.scheduledEventDate && source === "statusFS" ? (
				<span>
					{`${translate("drivers.fields.scheduledEventDate")} ${formatIsoDate(record.scheduledEventDate)}`}
				</span>
			) : (
				<span>{translate("drivers.tableStates.pending")}</span>
			);
		}
		case 2: {
			return (
				<span>
					<Done />
				</span>
			);
		}
		case 0: {
			return <span>{translate("drivers.tableStates.fail")}</span>;
		}
		case -1:
		case 6: {
			return translate("drivers.states.created");
		}
		default: {
			return <span />;
		}
	}
};

export default StatusField;
