import React from "react";
import { Datagrid, List, TextField } from "react-admin";

import AdminHeader from "../admin/AdminHeader";
import MyPagination from "../components/table/Pagination";
import SearchFilter from "../components/table/SearchFilter";

const BusinessUnitList = properties => {
	const { basePath } = properties;

	return (
		<div>
			<AdminHeader active={basePath} />
			<h1 style={{ color: "red" }}>Veraltet! Bitte Abteilungen verwenden</h1>
			<List
				pagination={<MyPagination />}
				actions={null}
				filters={<SearchFilter />}
				{...properties}
			>
				<Datagrid rowClick="edit">
					<TextField source="name" label="businessUnits.fields.name" />
					<TextField source="address" label="businessUnits.fields.address" />
					<TextField source="city" label="businessUnits.fields.city" />
					<TextField source="country" label="businessUnits.fields.country" />
					<TextField source="contactPerson" label="businessUnits.fields.contactPerson" />
					<TextField source="comments" label="businessUnits.fields.comments" />
				</Datagrid>
			</List>
		</div>
	);
};

export default BusinessUnitList;
