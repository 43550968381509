import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LaunchIcon from "@material-ui/icons/Launch";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import React from "react";

const useStyles = makeStyles(theme => ({
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		padding: 15,
	},
	kpi: {
		fontSize: 36,
		fontWeight: 600,
	},
	secKpi: {
		fontSize: 12,
		fontWeight: 600,
	},
	secKpiLabel: {
		fontSize: 12,
		color: theme.palette.gray.main,
	},
	trendIcon: {
		color: ({ trend }) => (trend > 0 ? theme.palette.green.main : theme.palette.yellow.main),
		transform: ({ trend }) => (trend > 0 ? "rotate(-90deg)" : null),
	},
}));

const DashboardKpi = ({
	color,
	icon,
	title,
	count,
	secondCount,
	secLabel,
	onClick,
	noTrend,
	onSecClick,
}) => {
	const classes = useStyles({ trend: secondCount });
	return (
		<Box className={classes.content}>
			<Box width="100%" display="flex" flexDirection="row">
				<Box mr={1} style={{ color }}>
					{icon}
				</Box>
				<Typography variant="h4">{title}</Typography>
			</Box>

			<Box
				mt={1}
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<span id={`${color}-kpi`} className={classes.kpi}>
					{count}
				</span>

				<Box display="flex" flexDirection="row" alignItems="center">
					{!noTrend && <TrendingFlatIcon className={classes.trendIcon} />}
					<Box display="flex" flexDirection="column">
						<div className={classes.secKpi}>{secondCount}</div>
						<div className={classes.secKpiLabel}>{secLabel}</div>
					</Box>
					{onSecClick && (
						<IconButton onClick={onSecClick}>
							<LaunchIcon />
						</IconButton>
					)}
				</Box>

				<IconButton id={`${color}-button`} onClick={onClick}>
					<LaunchIcon />
				</IconButton>
			</Box>
		</Box>
	);
};

export default DashboardKpi;
