import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import { useHistory } from "react-router-dom";

import FormSubtitle from "../components/form/FormSubtitle";
import BoolField from "../components/table/BoolField";
import DateField from "../components/table/DateField";

import TenantGroupBatchMove from "./TenantGroupBatchMove";
import GroupPanel from "./TenantListPanel";

const initTableProperties = {
	basePath: "/tenants",
	hasCreate: false,
	hasEdit: true,
	hasList: true,
	hasShow: false,
	location: {
		pathname: "/",
		search: "",
		hash: "",
		state: undefined,
	},
	sort: { field: "createdAt", order: "DESC" },
	match: {
		path: "/",
		url: "/",
		isExact: true,
		params: {},
	},
	options: {},
	permissions: null,
	resource: "tenants",
	perPage: 500,
};

const useStyles = makeStyles(() => ({
	expandedPanel: {
		"& > *": {
			padding: 0,
		},
	},
}));

const SuperTenantAssignList = ({ superTenantId }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const history = useHistory();

	const handleCreateClick = () => {
		history.push(`/tenants/create?superTenantId=${superTenantId}`);
	};

	const handleGroupCreateClick = () => {
		history.push(`/groups/create?superTenantId=${superTenantId}`);
	};

	return (
		<Box display="flex" flexDirection="column">
			<Box display="flex" flexDirection="row">
				<Button
					variant="contained"
					color="primary"
					style={{ width: 200, margin: "20px 0" }}
					onClick={handleGroupCreateClick}
				>
					{translate("groups.actions.create")}
				</Button>

				<Button
					color="primary"
					style={{ width: 200, margin: "20px" }}
					onClick={handleCreateClick}
				>
					{translate("tenants.actions.create")}
				</Button>
			</Box>

			<Box width="90%" marginBottom="20px">
				<List
					{...initTableProperties}
					basePath="/groups"
					resource="groups"
					pagination={null}
					bulkActionButtons={false}
					filterDefaultValues={{ fk_superTenantId: superTenantId }}
					exporter={false}
					actions={null}
				>
					<Datagrid
						rowClick="edit"
						isRowExpandable={rec => Boolean(rec.Tenants && rec.Tenants.length > 0)}
						expand={<GroupPanel />}
						classes={{ expandedPanel: classes.expandedPanel }}
					>
						<TextField source="name" label="tenants.fields.name" />
						<DateField source="createdAt" label="tenants.fields.createdAt" />
					</Datagrid>
				</List>
			</Box>

			<Box width="90%">
				<FormSubtitle>{translate("superTenants.sections.withoutGroup")}</FormSubtitle>
				<List
					{...initTableProperties}
					filterDefaultValues={{ fk_superTenantId: superTenantId, fk_groupId: null }}
					pagination={null}
					bulkActionButtons={<TenantGroupBatchMove />}
					exporter={false}
					actions={null}
				>
					<Datagrid rowClick="edit">
						<TextField source="name" label="tenants.fields.name" />
						<BoolField source="active" label="tenants.fields.active" />
						<DateField source="createdAt" label="tenants.fields.createdAt" />
					</Datagrid>
				</List>
			</Box>
		</Box>
	);
};

export default SuperTenantAssignList;
