import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { Notification, useAuthProvider, useNotify, useTranslate } from "react-admin";
import { connect } from "react-redux";

import Icon from "../assets/img/edding-logo.svg";
import theme from "../common/theme";
import { setResetCodeAction, setUsernameAction } from "../redux/authReducer";

const useStyles = makeStyles(t => ({
	logoImage: {
		objectFit: "scale-down",
		width: 445,
		height: 128,
	},
	titleContainer: {
		padding: 10,
		borderBottom: `1px solid ${t.palette.secondary.main}`,
	},
}));

const PasswordResetPage = ({ username, onUsernameChange, onResetCodeChange, history }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const authProvider = useAuthProvider();
	const notify = useNotify();

	const submit = event => {
		event.preventDefault();
		authProvider
			.reset({ username })
			.then(body => {
				if (body.debug_reset_code) {
					onResetCodeChange(body.debug_reset_code);
				}
				history.push("/auth/verify");
				notify("pages.passwordForget.successMessage");
			})
			.catch(() => {
				notify("pages.passwordForget.failed");
			});
	};

	return (
		<ThemeProvider theme={theme}>
			<Container fixed>
				<Box
					height="90vh"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
				>
					<Box
						height="80vh"
						maxHeight={700}
						display="flex"
						flexDirection="column"
						justifyContent="space-around"
						alignItems="center"
					>
						<img className={classes.logoImage} src={Icon} alt="company_logo" />

						<Box className={classes.titleContainer}>
							<Typography variant="h3">
								{translate("pages.passwordForget.title")}
							</Typography>
						</Box>
						<form onSubmit={submit}>
							<Box
								maxWidth={520}
								width="50vw"
								height={250}
								display="flex"
								flexDirection="column"
								flexWrap="wrap"
								justifyContent="space-around"
							>
								<TextField
									fullWidth
									label={translate("ra.auth.username")}
									name="username"
									type="username"
									value={username}
									variant="outlined"
									onChange={e => onUsernameChange(e.target.value)}
								/>

								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={!username}
								>
									{translate("pages.passwordForget.button")}
								</Button>
							</Box>
						</form>
					</Box>
				</Box>
				<Notification />
			</Container>
		</ThemeProvider>
	);
};

const mapStateToProperties = state => ({
	username: state.auth.username,
});

const mapDispatchToProperties = dispatch => ({
	onUsernameChange: username => {
		dispatch(setUsernameAction(username));
	},
	onResetCodeChange: resetCode => {
		dispatch(setResetCodeAction(resetCode));
	},
});

export default connect(mapStateToProperties, mapDispatchToProperties)(PasswordResetPage);
