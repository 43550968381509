import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { NumberInput, required, SelectInput, TextInput, useTranslate } from "react-admin";

import FormCheckbox from "../components/form/FormCheckbox";
import FormSubtitle from "../components/form/FormSubtitle";

import SuperTenantAssignList from "./SuperTenantAssignList";

const useStyles = makeStyles(() => ({
	container: {
		padding: "20px 40px 30px 40px",
		flexDirection: "row",
		flexWrap: "wrap",
		width: "100%",
	},
	halfWidth: {
		flexBasis: "48%",
	},
}));

const SuperTenantForm = ({ toolbar, formProps }) => {
	const classes = useStyles();
	const translate = useTranslate();

	return (
		<form className={classes.container}>
			<Box display="flex" flexDirection="row" flexWarp="wrap" width="100%">
				<Box minWidth={360} flex={1} display="flex" flexDirection="column">
					<FormSubtitle>{translate("superTenants.sections.masterData")}</FormSubtitle>
					<TextInput
						source="name"
						label="superTenants.fields.name"
						variant="outlined"
						margin="none"
						validate={required()}
					/>

					<NumberInput
						source="maxTenants"
						label="superTenants.fields.maxTenants"
						variant="outlined"
						margin="none"
					/>

					<FormCheckbox source="includeUVV" label="superTenants.fields.includeUVV" />

					<FormCheckbox source="type1UVV" label="superTenants.fields.type1UVV" />

					<SelectInput
						className={classes.halfWidth}
						margin="none"
						variant="outlined"
						source="controlIntervalDays"
						label="superTenants.fields.controlIntervalDays"
						choices={[
							{
								id: 3,
								name: `3 ${translate("drivers.fields.licenseIntervalPostfixDays")}`,
							},
							...[1, 2, 3, 4, 5, 6].map(value => ({
								id: value * 30,
								name: `${value} ${translate("drivers.fields.licenseIntervalPostfix")}`,
							})),
						]}
					/>

					{toolbar}
				</Box>

				<Box minWidth={360} flex={2} pl={2} display="flex" flexDirection="column">
					<FormSubtitle>{translate("superTenants.sections.tenants")}</FormSubtitle>

					{formProps.record.id ? (
						<SuperTenantAssignList superTenantId={formProps.record.id} />
					) : (
						<FormSubtitle
							style={{
								color: "gray",
								paddingTop: 20,
							}}
						>
							{translate("superTenants.sections.hint")}
						</FormSubtitle>
					)}
				</Box>
			</Box>
		</form>
	);
};

export default SuperTenantForm;
