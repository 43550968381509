import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiTextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import {
	Datagrid,
	List,
	useDataProvider,
	useTranslate,
	TextField,
	useRefresh,
	usePermissions,
} from "react-admin";
import { useHistory } from "react-router-dom";

import PermissionUtils from "../common/permissionUtils";
import DateField from "../components/table/DateField";

const initTableProperties = {
	basePath: "/tenants",
	hasCreate: false,
	hasEdit: true,
	hasList: true,
	hasShow: false,
	location: {
		pathname: "/",
		search: "",
		hash: "",
		state: undefined,
	},
	match: {
		path: "/",
		url: "/",
		isExact: true,
		params: {},
	},
	options: {},
	permissions: null,
	resource: "tenants",
	perPage: 500,
};

const GroupAssignList = ({ superTenantId, groupId }) => {
	const translate = useTranslate();
	const history = useHistory();
	const permissions = new PermissionUtils(usePermissions());
	const dataProvider = useDataProvider();

	const [tenants, setTenants] = useState([]);
	const [selectedTenants, setSelectedTenants] = useState([]);
	const refresh = useRefresh();

	const [open, setOpen] = useState(false);

	const fetchTenants = () => {
		if (groupId) {
			dataProvider
				.getList("tenants", {
					pagination: { page: 1, perPage: 500 },
					filter: {
						fk_superTenantId: superTenantId,
					},
				})
				.then(res => {
					if (res) {
						setTenants(res.data);
					}
				});
		}
	};

	useEffect(() => {
		fetchTenants();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchTenants();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupId]);

	const handleCreateClick = () => {
		history.push(`/tenants/create?superTenantId=${superTenantId}&groupId=${groupId}`);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleAddTenants = () => {
		dataProvider
			.update("groups", {
				id: groupId,
				data: { tenantIds: selectedTenants.map(t => t.id) },
			})
			.then(() => {
				refresh();
			});
	};

	return (
		<Box display="flex" flexDirection="column">
			{permissions.canManage() && (
				<Box display="flex" flexDirection="row">
					<Button
						variant="contained"
						color="primary"
						style={{ width: 200, margin: "20px 0" }}
						onClick={handleOpen}
					>
						{translate("groups.actions.addTenant")}
					</Button>

					<Button
						variant="outlined"
						color="primary"
						style={{ width: 200, margin: "20px 10px" }}
						onClick={handleCreateClick}
					>
						{translate("groups.actions.createTenant")}
					</Button>
				</Box>
			)}

			<Dialog open={open} aria-labelledby="form-dialog-title" onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{translate("groups.actions.addTenant")}
				</DialogTitle>
				<DialogContent>
					<Autocomplete
						multiple
						id="combo-box-demo"
						options={tenants.filter(t => t.fk_groupId !== groupId)}
						getOptionLabel={option => option.name}
						style={{ width: 300 }}
						renderInput={parameters => (
							<MuiTextField
								{...parameters}
								label={translate("groups.fields.tenant")}
								variant="outlined"
							/>
						)}
						onChange={(_, values) => setSelectedTenants(values)}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleClose}>
						{translate("ra.action.cancel")}
					</Button>
					<Button color="primary" onClick={handleAddTenants}>
						{translate("ra.action.save")}
					</Button>
				</DialogActions>
			</Dialog>

			<List
				{...initTableProperties}
				filterDefaultValues={{ fk_groupId: groupId }}
				pagination={null}
				exporter={false}
				actions={null}
			>
				<Datagrid rowClick="edit">
					<TextField source="name" label="tenants.fields.name" />
					<DateField source="createdAt" label="tenants.fields.createdAt" />
				</Datagrid>
			</List>
		</Box>
	);
};

export default GroupAssignList;
