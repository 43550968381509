const initialState = {
	driverFilter: {},
	driverSort: {},
	globalView: localStorage.getItem("globalView") === "true",
};

export const fetchSettings = () => ({
	type: "FETCH_SETTINGS",
	payload: {},
});

export const updateSettings = settings => ({
	type: "UPDATE_SETTINGS",
	payload: settings,
});

export const setSettings = data => ({
	type: "SET_SETTINGS",
	payload: data,
});

export default (previousState = initialState, { type, payload }) => {
	if (type === "FETCH_SETTINGS") {
		return {
			...previousState,
		};
	}
	if (type === "UPDATE_SETTINGS") {
		return {
			...previousState,
			...payload,
		};
	}

	if (type === "SET_SETTINGS") {
		return { ...payload };
	}

	return previousState;
};
