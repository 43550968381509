import PropTypes from "prop-types";
import React, { useState } from "react";
import {
	Button,
	Confirm,
	useNotify,
	usePermissions,
	useRefresh,
	useUnselectAll,
} from "react-admin";

import PermissionUtils from "../common/permissionUtils";
import TenantSelect from "../components/TenantSelect";
import customProvider from "../providers/customProvider";

Confirm.propTypes = {
	...Confirm.propTypes,
	// eslint-disable-next-line react/forbid-prop-types
	content: PropTypes.any,
};

const DriverBatchMove = ({ selectedIds }) => {
	const [open, setOpen] = useState(false);
	const [tenant, setTenant] = useState("");

	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const permissions = new PermissionUtils(usePermissions());

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = async () => {
		if (!tenant) {
			return;
		}
		try {
			await customProvider.customBulk("drivers", {
				action: "update",
				driverIds: selectedIds,
				attributes: {
					fk_tenantId: tenant.id,
				},
			});
			refresh();
			notify("common.successBatch");
			unselectAll("drivers");
		} catch {
			notify("common.fail");
			setOpen(false);
		}
	};

	return (
		<>
			<Button label="drivers.actions.move" style={{ padding: 10 }} onClick={handleClick} />

			<Confirm
				isOpen={open}
				title="drivers.actions.move"
				content={
					<TenantSelect
						selected={tenant}
						superTenantId={
							permissions.isSuperAdmin() ? undefined : permissions.getSuperTenantId()
						}
						onChange={setTenant}
					/>
				}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
		</>
	);
};

export default DriverBatchMove;
