import polyglotI18nProvider from "ra-i18n-polyglot";
import germanMessages from "ra-language-german";

const english = {
	ra: {
		notification: {
			http_error: "Network error. Please retry",
		},
		action: {
			save: "Save",
			delete: "Delete",
		},
	},
};

germanMessages.ra.action.unselect = "abwählen";
germanMessages.ra.page.empty = "Keine Einträge vorhanden";
germanMessages.ra.page.invite = "";
germanMessages.ra.page.loading = "";
germanMessages.ra.auth.auth_check_error = "Authentifizierung fehlgeschlagen";
germanMessages.ra.auth.username = "E-Mail Adresse";
germanMessages.ra.auth.sign_in = "Jetzt anmelden";
germanMessages.ra.notification.logged_out = "Sie wurden abgemeldet";
germanMessages.ra.auth.invalid_2fa = "2FA Code invalide";
germanMessages.ra.auth.require_2fa = "Bitte aktivieren Sie die Zwei-Faktor-Authentifizierung";
germanMessages.ra.auth.not_assigned_any_department =
	"Sie sind keiner Abteilung zugewiesen. Bitte kontaktiere einen Super-Admin deiner Organisation.";

const german = {
	...germanMessages,
	common: {
		clearAll: "Alle löschen",
		confirm: "Sind Sie sicher?",
		success: "Aktion erfolgreich durchgeführt",
		supportRequestSent: "Die Supportanfrage wurde gesendet.",
		labelOrderSent: "Die Labelbestellung wurde gesendet.",
		supportRequestSuccess: "Die Supportanfrage wurde erfolgreich durchgeführt.",
		labelOrderSuccess: "Die Labelbestellung wurde erfolgreich durchgeführt.",
		supportRequestError: "Bei der Supportanfrage gab es einen Fehler.",
		labelOrderError: "Bei der Labelbestellung gab es einen Fehler.",
		successBatch: "Aktion wird im Hintergrund durchgeführt. Dies kann einen Moment dauern",
		fail: "Aktion fehlgeschlagen",
		startDate: "Von",
		endDate: "Bis",
		sum: "Gesamt",
		clear: "Löschen",
		download: "Download",
		wait: "Bitte warten",
		pw: {
			shouldContain:
				"Muss bestehen aus: Groß- & Kleinbuchstaben (A-Z, a-z), Zahlen (0-9) und Sonderzeichen (z.B.: !@#$%^&*)",
			lengthAtLeast: "Muss mindestens 16 Zeichen lang sein",
		},
	},
	pages: {
		appBar: {
			switchTenant: "Abteilung wechseln",
			globalView: "Globale Ansicht",
		},
		login: {
			title: "Herzlich Willkommen bei easycheck",
			subtitle: "Melden Sie sich an und verwalten Sie Ihre Fahrer",
			passwordForget: "Passwort vergessen",
		},
		passwordForget: {
			title: "Passwort vergessen? Bitte Email-Adresse angeben",
			button: "Zurücksetzen anfordern",
			successMessage: "Zurücksetzen erfolgreich. Verifikationscode versendet.",
			failed: "Zurücksetzen nicht möglich. Eventuell existiert kein Konto mit dieser E-Mail Adresse",
		},
		activation: {
			title: "easycheck-Account aktivieren",
			require2FA: "Bitte aktivieren Sie Zwei-Faktor-Authentifizierung",
			error: "Fehler beim aktivieren. Bitte versuchen Sie es erneut",
			successMessage: "Account erfolgreich aktiviert",
		},
		passwordVerify: {
			title: "Neues Passwort setzen",
			resetCode: "Verifikationscode",
			newPassword: "Neues Passwort",
			button: "Speichern",
			successMessage: "Passwort erfolgreich gesetzt",
		},
		dashboard: {
			title: "Dashboard",
			showUvv: "Status UVV anzeigen",
			values: {
				totalDrivers: { title: "Fahrer Gesamt", secLabel: "davon inaktiv" },
				newDrivers: { title: "Nicht gestartete Kontrollen", secLabel: "zu letzter Woche" },
				overdueFourWeeks: {
					title: "Kontrolle überfällig seit 4 Wochen",
					secLabel: "zu letzter Woche",
				},
				pendingInit: {
					title: "Ausstehende Initialisierungen",
					secLabel: "zu letzter Woche",
				},
				pendingControl: { title: "Ausstehende Kontrollen", secLabel: "zu letzter Woche" },
				failedControl: {
					title: "Fehlgeschlagene Kontrollen",
					secLabel: "zu letzter Woche",
				},
				overdueControl: {
					title: "Kontrolle überfällig seit 2 Wochen",
					secLabel: "zu letzter Woche",
				},
				validControl: {
					title: "Fahrer mit erfolgreicher Kontrolle",
					secLabel: "zu letzter Woche",
				},
				validVisualControl: {
					title: "Fahrer mit gültiger Sichtkontrolle",
					secLabel: "zu letzter Woche",
				},
				neutralState: {
					title: "Art der Kontrolle nicht definiert",
					secLabel: "zu letzter Woche",
				},
				firstFailedControl: {
					title: "Erste Kontrolle fehlgeschlagen",
					secLabel: "zu letzter Woche",
				},
				validUVVStatus: { title: "Erfolgreiche Unterweisungen", secLabel: "davon inaktiv" },
				pendingUVVStatus: {
					title: "Ausstehende Unterweisungen",
					secLabel: "davon inaktiv",
				},
				overdueUVVStatus: {
					title: "Fehlgeschlagene Unterweisungen",
					secLabel: "davon inaktiv",
				},
			},
		},
		monitoring: {
			title: "Monitoring & Analyse",
			range: "Zeitspanne",
			hint: "Bitte wählen Sie ein Startdatum aus",
			driversByStatus: {
				title: "Anzahl Fahrer pro Status",
				overdue: "Seit 4 Wochen überfällig",
				pending: "Initialisierung ausstehend",
			},
			driversByType: {
				title: "Anzahl Fahrer nach Kontrollart",
				0: "Firmenwagenfahrer",
				1: "Gelegenheitsfahrer",
			},
			driversByUvv: {
				title: "Status UVV Unterweisungen",
				success: "Fahrer mit gültiger Unterweisung",
				pending: "Mehr als 2 Wochen überfällig",
				overdue: "Mehr als 4 Wochen überfällig",
			},
			dailyCounters: {
				title: "Gesamtzahl Führerscheinkontrollen",
			},
		},
		driverCreate: {
			title: "Neuen Fahrer anlegen",
			error: "Es existiert bereits ein Fahrer zu dieser Email-Adresse",
			maxDriverReached: "Maximale Anzahl von Fahrern bereits erreicht",
		},
		driverEdit: {
			title: "Fahrer bearbeiten",
			codeHint: "Invalider Labelcode",
		},
		driverList: {
			title: "Fahrerübersicht",
			filters: {
				criticalOnly: "Nur kritische Fahrer anzeigen",
				deleted: "Gelöschte anzeigen",
				invalidLabelCode: "00000 Labelcodes",
				neutralState: "Neutral State",
			},
		},
		driverShow: {
			statusFS: "FS Prüfstatus",
			statusUVV: "UVV Prüfstatus",
			statusUVVLKW: "LKW-UVV Prüfstatus",
			initModal: "Foto-Rollout wurde angefragt.",
			adhocModal: "FS Kontrolle wurde angefragt",
			uvvModal: "UVV Unterweisung wurde angefragt",
			uvvLkwModal: "LKW-UVV-Unterweisung wurde angefragt",
			uvvFail: "UVV wurde bereits erfolgreich absolviert. Erneute Aufforderung nicht möglich",
			cUvv: "Unterweisung wurde angefragt",
			cUvvFail: "Unterweisung konnte nicht angefragt werden",
			manualButton: "Manuelle Sichtkontrolle",
			manualModal: "Manuelle Sichtkontrolle erfolgreich",
			manualConfirm: "Führerschein liegt im Originalen vor?",
			newDriverSuccess: "Der Fahrer wurde angelegt",
			driverUpdateSuccess: "Der Fahrer wurde aktualisiert",
		},
		driverReport: {
			title: "Standard Report",
			fsk: "Führerscheinkontrollen",
			uvv: "Unterweisungen",
			preview: "Vorschau (Auszug)",
		},
		driverUpload: {
			title: "Fahrerupload",
			preview: "Vorschau",
			import: "Fahrer importieren",
		},
		userCreate: {
			title: "Neuen Admin anlegen",
			error: "Es existiert bereits ein Nutzer zu dieser Email-Adresse",
		},
		userEdit: {
			title: "Admin Profil bearbeiten",
		},
		admin: {
			title: "Verwaltung",
			manageAccounts: "Accounts verwalten",
			manageAdmins: "Admins verwalten",
			manageUnits: "Geschäftseinheiten verwalten",
			features: "Features",
			orderLabel: "Label nachbestellen",
			downloadArea: "Download-Bereich",
			addressBook: "Adressbuch",
			tenants: "Abteilungen verwalten",
			superTenants: "Verbunde verwalten",
			reminder: "Erinnerungen verwalten",
			labelCodes: "Label Codes verwalten",
		},
		adminFeatures: {
			batchCheck: "Sammelprüfung",
			batchCheckButton: "Sammelprüfung starten",
		},
		settings: {
			title: "Einstellungen",
			changePassword: {
				title: "Passwort ändern",
				oldPassword: "Aktuelles Passwort",
				newPassword: "Neues Passwort",
				newPasswordConfirm: "Neues Passwort wiederholen",
				submitButton: "Passwort ändern",
				notIdenticalError: "Neue Passworter nicht identisch",
			},
			twoFa: {
				title: "Zwei-Faktor-Authentifizierung",
				activate: "Aktivieren",
				deactivate: "Deaktivieren",
				generateCode: "Code generieren",
				twoFactorAuthCode: "2FA Code",
				hint: "Bitte QR-Code in Authenticator App scannen",
			},
		},
		support: {
			title: "Support",
			faq: "Hilfe und FAQs",
			yourMessage: "Ihre Nachricht",
			downloads: "Download Bereich",
			send: "Nachricht versenden",
		},
		download: {
			download: "Download",
			search: "Suche",
			rangeError: "Keine Daten in der angegebenen Zeitspanne gefunden",
		},
		scheduleEvent: {
			title: "Vereinbarung Prüftermin",
			invalid: "Der verwendete Link ist leider ungültig",
			success: "Die Vereinbarung wurde erfolgreich angelegt.",
		},
	},
	drivers: {
		states: {
			created: "Angelegt",
			fail: "Kontrolle fehlgeschlagen",
			pending: "Kontrolle ausstehend",
			success: "Kontrolle erfolgreich",
			controlFailedFirst: "Erste Kontrolle fehlgeschlagen",
			initPending: "Initialisierung ausstehend",
			initFailed: "Initialisierung fehlgeschlagen",
			initSuccess: "Initialisierung erfolgreich",
		},
		contactType: {
			0: "E-Mail",
			1: "SMS",
			label: "Kontakt",
		},
		licenseType: {
			0: "EU-Führerschein",
			1: "Papier-Rosa",
			2: "Papier-Grau",
		},
		uvvTypes: {
			1: "PKW",
			11: "LKW",
		},
		tableStates: {
			created: "Angelegt",
			fail: "Fehlgeschlagen",
			pending: "Ausstehend",
			success: "Erfolgreich",
			controlFailedFirst: "Erste fehlgeschlagen",
			initPending: "Init. ausstehend",
			initFailed: "Init. fehlgeschlagen",
			initFailedFirst: "Erste Init. fehlgeschlagen",
			other: "Abgelaufen",
		},
		actions: {
			save: "Fahrerprofil speichern",
			edit: "Fahrerakte bearbeiten",
			download: "Download der Akte",
			delete: "Fahrerakte löschen",
			deleteFinal: "Fahrerakte final löschen",
			recover: "Fahrerakte wiederherstellen",
			reinit: "Initialisierung anfordern",
			welcome: "Willkommens-Mail versenden",
			control: "FS-Kontrolle anfordern",
			adhoc: "FS-Kontrolle anfordern",
			invalidLabelCode:
				"Achtung! Bitte gültigen Labelcode einfügen. Führerscheinkontrolle aktuell nicht nutzbar",
			active: "Aktiv setzen",
			inactive: "Inaktiv setzen",
			clearDbFilter: "Dashboard Filter entfernen",
			uvv: "UVV-Unterweisung anfordern",
			uvvLkw: "LKW-UVV-Unterweisung anfordern",
			rollout: "Foto-Rollout auslösen",
			move: "Fahrerakte(n) verschieben",
			stillValid:
				"Möchten Sie dieses Event wirklich auslösen? Es ist erst wieder in %{days} Tagen fällig und wird automatisch durch easycheck ausgelöst",
			stillValidUvv:
				"Sind Sie sicher, dass Sie diese Unterweisung auslösen wollen? Der User hat diese Unterweisung bereits am %{lastDate}. erfolgreich durchgeführt. Die nächste Unterweisung ist erst wieder am %{nextDate}. notwendig. Eine Auslösen dieses Events ist aktuell unnötig.",
			stillValidNotification:
				"Einige Fahrer wurden aufgrund gültiger Kontrollen oder nicht definierter Art der Kontrollen übersprungen",
		},
		driverForm: {
			successMessage: {
				title: "Ihr Fahrer wurde angelegt",
				copy: "Die Führerscheinkontrolle und/oder Unterweisung muss noch entsprechend startet werden.",
				links: {
					detailView: "Zur Fahrerakte",
					overView: "Zur Fahrerübersicht",
				},
			},
			errorMessage: {
				title: "Ihr Fahrer konnte nicht angelegt werden.", // TODO: to be checked
			},
			contactSection: {
				title: "Fahrerdaten",
				copy: {
					default:
						"Geben Sie die Kontaktdaten ihres Fahrers an, über die wir den Fahrer zur Führerscheinkontrolle auffordern sollen.",
					withUvv:
						"Geben Sie hier die Kontaktdaten ihres Fahrers an, über die wir ihn zur Führerscheinkontrolle, und UVV-Unterweisung, auffordern sollen.",
				},
			},
			licenseCheckSection: {
				title: "Führerscheinkontrolle",
				copy: "Wählen Sie, wie sieh diesen Fahrer prüfen wollen. Sie können die Art der Prüfung jederzeit ändern.",
				options: {
					digital: {
						title: "Digitale Prüfung",
						copy: "Die vollständige Prüfung mit dem easycheck Prüflabel. Alles voll automatisch, sobald sie einmal gestartet ist.",
					},
					manual: {
						title: "Manuelle Sichtkontrolle",
						copy: "Falls Sie den Fahrer nicht mit einem Prüflabel ausstatten können, können Sie ihn trotzdem bequem in easycheck im Blick behalten.",
					},
				},
			},
			uvvSection: {
				title: "UVV-Unterweisung",
				copy: "Wählen Sie welche Fahrzeugtypen Ihre Fahrer bewegt um nur die entsprechenden UVV-Unterweisungen verschicken zu können.",
			},
			commentSection: {
				title: "Notizen",
				copy: "Geben Sie zusätzliche Hinweise zum Führerschein, oder zum Fahrers an.",
			},
			fields: {
				department: {
					label: "Abteilung",
					hint: {
						disabled: "Sie haben aktuell nur eine Abteilung.",
					},
				},
				firstName: {
					label: "Vorname",
					error: {
						required: "Bitte geben Sie den Vornamen an.",
					},
				},
				surName: {
					label: "Nachname",
					error: {
						required: "Bitte geben Sie den Nachnamen an.",
					},
				},
				contactOptions: {
					options: {
						email: "E-Mail",
						sms: "SMS",
					},
				},
				email: {
					label: "E-Mail Adresse",
					error: {
						required: "Wir benötigen eine E-Mail Adresse",
						invalid: "Bitte überprüfen Sie das Format",
					},
				},
				mobilePhone: {
					label: "Handynummer",
					hint: "Im Format +49xxxxxxxx.",
					error: {
						required: "Wir benötigen eine Telefonnummer im Format +49xxxxxxxx.",
						invalid: "Bitte im Format +49xxxxxxxx.",
					},
				},
				labelCode: {
					label: "Label-Code",
					hint: "",
					error: {
						required: "Für die digitale Prüfung ist ein Label notwendig.",
						invalid: "Bitte überprüfen Sie den Code, dieser is ungültig.",
					},
				},
				interval: {
					label: "Prüfinterval",
					hint: "Sie können pro Fahrer ein spezielles Interval auswählen.",
				},
				comment: {
					label: "Notiz",
					hint: "Kostenstelle, Fahrerklassen, Bemerkungen etc.",
				},
				licenseExpiryDate: {
					label: "Führerschein Ablaufdatum",
					hint: "Format: DD/MM/YYYY",
					error: {
						invalid: "Bitte nutzen im Format DD/MM/YYYY.",
					},
				},
			},
			ctaButton: {
				label: "Fahrer anlegen",
			},
		},

		fields: {
			surName: "Nachname",
			firstName: "Vorname",
			email: "E-Mail Adresse",
			phoneNumber: "Handynummer",
			phoneNumberError: "Format nicht korrekt",
			companyDetails: "Geschäftseinheit",
			companyDetailsEdit: "Firma/Abteilung",
			tenant: "Abteilung",
			driverTypeEnum: "Fahrertyp",
			licenseInterval: "Prüfintervall",
			licenseIntervalDays: "Prüfintervall (Tage)",
			licenseIntervalPostfixDays: "Tage",
			licenseIntervalPostfix: "Monate",
			licenseLabelCode: "Label-Code",
			invalidLabelCode:
				"Achtung! Bitte gültigen Labelcode einfügen. Führerscheinkontrolle aktuell nicht nutzbar",
			licenseType: "Führerscheinart",
			licenseExpiryDate: "Ablaufdatum",
			licenseComment1: "Kommentar",
			licenseComment2: "Kommentar (2)",
			statusFS: "Status FS-Kontrolle",
			statusUVV: "Status UVV",
			statusUVVLKW: "Status LKW-UVV",
			statusFSVisualControlText: "FS Sichtkontrolle",
			controlDate: "Datum d. Prüfung",
			controlType: "Prüfungsform",
			controllerName: "Prüfer",
			active: "Aktiv",
			inactive: "Inaktiv",
			activeUntil: "aktiv bis: ",
			inactiveUntil: "inaktiv bis: ",
			deleted: "Gelöscht",
			uvvActive: "UVV aktiv",
			scheduledEventDate: "Video-Prüfdatum",
			loginCode: "Stationärer Login Code",
			externalGroup: "Externe Gruppe",
			group: "Gruppe",
			area: "Bereich",
			isPkw: "PKW",
			isLkw: "LKW",
			uvvType: "UVV Typ",
			helperText: {
				tenant: "Wählen Sie den zugehörigen Mandanten aus.",
				firstName: "Geben Sie den Vornamen des Fahrers ein.",
				surName: "Geben Sie den Nachnamen des Fahrers ein.",
				email: "Geben Sie eine gültige E-Mail-Adresse des Fahrers ein.",
				phoneNumber: "Geben Sie die Telefonnummer des Fahrers ein.",
				licenseInterval:
					"Wählen Sie das Intervall für Führerscheinkontrollen (z.B. 1 Monat, 3 Monate, 6 Monate).",
				licenseLabelCode:
					"Geben Sie den mit dem Führerschein des Fahrers verbundenen Labelcode ein.",
				licenseType: "Wählen Sie die Art des Führerscheins, den der Fahrer besitzt.",
				licenseExpiryDate: "Geben Sie das Ablaufdatum des Führerscheins des Fahrers ein.",
				licenseComment1:
					"Geben Sie zusätzliche Kommentare zum Führerschein des Fahrers ein.",
				licenseComment2: "Geben Sie weitere Kommentare zum Führerschein des Fahrers ein.",
			},
			licenseControl: {
				info: "Führerschein- und Kontrolldaten",
				interval: "Kontrollintervall",
				method: "Art der Kontrolle",
				neutral: "Keine Kontrolle",
				digital: "Digital",
				manual: "Manuell",
			},
		},
		events: {
			comment: "Kommentar",
			diff: "Änderungen durch %{user}",
			newDriver: "Fahrer angelegt",
			activated: "Fahrer aktiviert",
			deactivated: "Fahrer deaktiviert",
			fsManual: "Manuelle Sichtkontrolle",
			fsManualSa: "Manuelle Sichtkontrolle durch edding",
			fsPending: "FS Kontrolle ausstehend",
			fsRequested: "FS Kontrolle angefragt",
			fsDone: "FS Kontrolle erfolgreich",
			fsOverdue: "FS Kontrolle überfällig",
			fsAdhoc: "FS Kontrolle angefragt",
			fsFail: "FS Kontrolle fehlgeschlagen",
			fsFailFirst: "Erste FS Kontrolle fehlgeschlagen",
			initRequested: "Foto-Rollout angefragt",
			initFailed: "Foto-Rollout abgelehnt",
			initSuccess: "Foto-Rollout erfolgreich",
			initStarted: "Foto-Upload bereit zur Kontrolle",
			uvvPending: "UVV Kontrolle ausstehend",
			uvvRequested: "UVV Kontrolle angefragt",
			uvvDone: "UVV Kontrolle erfolgreich",
			uvvFail: "UVV Kontrolle fehlgeschlagen",
			uvvOverdue: "UVV Kontrolle überfällig",
			next: "Nächste FS Kontrolle",
			fsCancel: "FS Kontrolle abgebrochen",
			eventScheduled: "Video-Prüfung mit edding vereinbart",
			uvvLkwPending: "LKW-UVV Kontrolle ausstehend",
			uvvLkwRequested: "LKW-UVV Kontrolle angefragt",
			uvvLkwDone: "LKW-UVV Kontrolle erfolgreich",
			uvvLkwFail: "LKW-UVV Kontrolle fehlgeschlagen",
			uvvLkwOverdue: "LKW-UVV Kontrolle überfällig",
			photoControlRequest: "Fotokontrolle - Upload erfolgreich",
			photoControlFail: "Fotokontrolle abgelehnt",
			photoControlSuccess: "Fotokontrolle erfolgreich",
			driverWelcome: "Willkommen zur FSK",
			driverUpdate: "Fahrerdaten geändert",
		},
		sections: {
			contactInfo: "Allgemeine Kontaktdaten",
			businessUnit: "Geschäftseinheit",
			licenseInfo: "Führerschein- und Prüfdaten",
			responsible: "Zugewiesener Verantwortlicher",
			vehicleTypes: "Fahrzeuge",
			adminName: "Name",
			adminMailAddress: "E-Mail Adresse",
		},
		controlTypes: {
			0: "digital",
			1: "analog",
			null: "-",
		},
		driverTypes: {
			0: "Firmenwagenfahrer",
			1: "Gelegenheitsfahrer",
		},
		inputFields: {
			businessUnit: "Geschäftseinheit/Abteilung",
		},
	},
	users: {
		roles: {
			0: "Ohne",
			1: "Fuhrparkmanager",
			2: "Master Admin",
			3: "Verbund Admin",
			4: "Gruppen Admin",
		},
		hints: {
			canRead: "Hat Systemzugang und kann Daten einsehen und bearbeiten.",
			canEdit: "Kann Fahrer bearbeiten sowie Kontrollen auslösen und durchführen.",
			canManage: "Kann Nutzer hinzufügen und löschen und Nutzungeberechtigungen ändern.",
			isNotified: "Der Nutzer erhält die FPM betreffenden Eskalationsemails",
			isNotifiedGlobal:
				"Der Nutzer erhält Eskalationsemails aus allen zugehörigen Abteilungen",
		},
		fields: {
			surName: "Nachname",
			firstName: "Vorname",
			email: "E-Mail",
			role: "Rolle",
			businessUnit: "Geschäftseinheit",
			tenant: "Abteilung",
			superTenant: "Verbund",
			group: "Gruppe",
			password: "Passwort",
			canRead: "Lesen & Analyse",
			canEdit: "Bearbeiten",
			canManage: "Nutzer verwalten",
			isNotified: "Benachrichtigungen erhalten",
			isNotifiedGlobal: "Globale Benachrichtigungen erhalten",
			notified: "Benachrichtigungen",
		},
		sections: {
			personalData: "Persönliche Daten",
			companyDetails: "Abteilung",
			adminRoles: "Admin-Rollen",
			rights: "Berechtigungen",
		},
		inputFields: {
			businessUnit: "Geschäftseinheit/Abteilung",
		},
		actions: {
			create: "Neuen Admin hinzufügen",
			save: "Admin Profil speichern",
			edit: "Admin Profil bearbeiten",
			delete: "Admin Profil löschen",
			reset2Fa: "2FA zurücksetzen",
		},
	},
	businessUnits: {
		label: "Geschäftseinheit",
		plural: "Geschäftseinheiten",
		fields: {
			active: "Aktiv",
			description: "Beschreibung",
			name: "Name",
			address: "Adresse",
			city: "Stadt",
			country: "Land",
			contactPerson: "Kontaktperson",
			comments: "Sonstiges",
		},
		sections: {
			masterData: "Daten der Geschäftseinheit",
			contactPerson: "Kontaktperson",
			other: "Sonstige",
		},
		actions: {
			create: "Neue Geschäftseinheit anlegen",
			edit: "Geschäftseinheit bearbeiten",
			save: "Geschäftseinheit speichern",
		},
		inputFields: {
			address: "Straße und Hausnummer",
			contactPerson: "Vor- und Nachname",
			comments: "Sonstige Informationen",
		},
	},
	driversReport: {
		label: "Report herunterladen",
	},
	labels: {
		label: "Labels bestellen",
		sections: {
			amount: "Menge",
			address: "Lieferadresse",
			companyDetails: "Unternehmensdaten",
			comment: "Anmerkung",
		},
		amountLabel: {
			10: "10 Stück",
			25: "25 Stück",
			50: "50 Stück",
			100: "100 Stück",
		},
		fields: {
			amount: "Anzahl",
			surName: "Nachname",
			firstName: "Vorname",
			email: "E-Mail Adresse",
			phone: "Telefonnummer",
			companyName: "Firmenname",
			zip: "PLZ",
			street: "Adresse",
			city: "Stadt",
			country: "Land",
		},
		actions: {
			send: "Bestellung absenden",
		},
	},
	tenants: {
		label: "Abteilung",
		actions: {
			edit: "Abteilung bearbeiten",
			create: "Neue Abteilung erstellen",
			save: "Abteilung speichern",
			move: "Abteilung verschieben",
			external: "Neuen API-Key generieren",
			externalHint: "Der alte Key verliert hierbei seine Gültigkeit",
		},
		fields: {
			description: "Beschreibung",
			name: "Name",
			tenantName: "Abteilungsname",
			address: "Adresse",
			city: "Stadt",
			country: "Land",
			responsibleUser: "Verantwortlicher",
			active: "Aktiv",
			createdByUserName: "Erstellt von",
			controlIntervalDays: "Prüfintervall",
			uvvIntervalDays: "UVV Prüfintervall",
			driversType0: "Max. Firmenwagenfahrer",
			driversType1: "Max. Gelegenheitsfahrer",
			createdAt: "Erstellt",
			includeUVV: "UVV (Fahrerunterweisung)",
			includeUVVLKW: "UVV für LKWs",
			type1UVV: "UVV für Gelegenheitsfahrer",
			superTenant: "Abteilungsverbund",
			group: "Gruppe",
			loginCode: "Login Code für stationäre Prüfung",
			external: "Externes Management",
			callbackToken: "Callback API Token",
			useReminder: "Email/SMS Eskalation",
			useTriggerEmail: "Trigger Email/SMS",
			useInterval: "Prüfinterval",
			fskCount: "Durchgeführte FSKs",
			allowSms: "SMS-Versand erlauben",
			smsCount: "Versandte SMS",
			emailCount: "Versandte Emails",
			hasPhotoControl: "Fotokontrolle aktiviert",
			hasPhotoRollout: "Fotoupload-Rollout",
			isPkw: "PKW-Fahrer",
			isLkw: "LKW-Fahrer",
			require2FA: "2FA verpflichtend",
			commentField1Name: "Bezeichnung Feld 1",
			commentField2Name: "Bezeichnung Feld 2",
			showDeleted: "Gelöschte Anzeigen",
		},
		sections: {
			masterData: "Abteilung",
			superTenant: "Verbund",
			contactPerson: "Kontaktperson",
			settings: "Einstellungen",
			driverPresets: "Fahrer- und UVV-Einstellungen",
			additionalData: "Optional",
			uvv: "Einstellungen UVVs",
			cUvv: "Weitere UVVs",
			photo: "Einstellungen Fotokontrolle",
			commentFieldNames: "Bezeichnung Kommentarfelder",
			key: "API Key. Bitte an einen sicheren Ort aufbewahren.",
			counter: "Nutzungsinformationen",
			logo: "Email-Logo",
		},
		uvvs: {
			request: "anfordern",
			52: "UVV + Zusatzmodul Elektrofahrzeuge",
			45: "Betriebshygiene – Transportlogistik",
			40: "Dienstradfahrer",
			7: "Gabelstapler",
			8: "Ladungssicherung",
			29: "Gefahrguttransport – Basismodul",
			31: "Gefahrguttransport – Spezialmodul ADN",
			30: "Gefahrguttransport – Spezialmodul ADR",
			32: "Gefahrguttransport – Spezialmodul IMDG",
			33: "Gefahrguttransport – Spezialmodul RID",
			43: "Homeoffice (inkl. Telearbeit)",
			62: "Homeoffice (Mobile Arbeit)",
			2: "Allgemeiner Arbeitsschutz",
		},
	},
	superTenants: {
		label: "Abteilungsverbund",
		plural: "Abteilungsverbunde",
		actions: {
			edit: "Verbund bearbeiten",
			create: "Neuen Verbund erstellen",
			save: "Verbund speichern",
			allTenants: "Alle Abteilungen anzeigen",
		},
		fields: {
			name: "Name",
			maxTenants: "Max. Abteilungen",
			createdByUserName: "Erstellt von",
			controlIntervalDays: "Prüfintervall",
			driversType0: "Max. Firmenwagenfahrer",
			driversType1: "Max. Gelegenheitsfahrer",
			createdAt: "Erstellt",
			includeUVV: "inkl. UVV",
			type1UVV: "UVV für Gelegenheitsfahrer",
			addTenant: "Abteilung hinzufügen",
		},
		sections: {
			masterData: "Verbund",
			tenants: "Abteilungen nach Gruppe",
			withoutGroup: "Ohne Gruppe",
			withoutSuperTenant: "Ohne Verbund",
			hint: "Abteilungen können nach dem speichern hinzugefügt werden",
		},
	},
	groups: {
		label: "Gruppe",
		plural: "Gruppen",
		actions: {
			edit: "Gruppe bearbeiten",
			create: "Neue Gruppe erstellen",
			save: "Gruppe speichern",
			delete: "Gruppe löschen",
			createTenant: "Abteilung erstellen",
			addTenant: "Bestehende hinzufügen",
		},
		fields: {
			name: "Name",
			createdByUserName: "Erstellt von",
			superTenant: "Verbund",
			tenant: "Abteilung",
			tenants: "Abteilungen",
			createdAt: "Erstellt",
		},
		sections: {
			masterData: "Gruppe",
			tenants: "Abteilungen",
			hint: "Abteilungen können nach dem speichern hinzugefügt werden",
		},
	},
	logs: {
		label: "Logging",
		fields: {
			text: "Beschreibung",
			level: "Level",
			createdAt: "Datum & Uhrzeit",
		},
		levels: {
			0: "Debug",
			1: "Info",
			2: "Warnung",
			3: "Error",
		},
	},
	templates: {
		label: "Vorlagen",
		fields: {
			templateType: "Name",
			subject: "Betreff",
			smsBody: "SMS Text",
			body: "Emailvorlage",
		},
		actions: {
			edit: "Vorlage bearbeiten",
			reset: "Vorlage zurücksetzen",
			save: "Vorlage speichern",
		},
		types: {
			100: "Einladung FP-Manager",
			101: "Passwort-Reset",
			102: "Label Bestellung",

			110: "Foto-Rollout",

			111: "Foto-Rollout fehlgeschlagen",
			112: "Foto-Rollout erfolgreich",
			113: "Foto-Rollout - Erinnerung",
			114: "Foto-Rollout - Erinnerung Admin",

			120: "Führerscheinkontrolle",
			121: "Eskalation Führerscheinkontrolle – Erinnerung 1",
			122: "Eskalation Führerscheinkontrolle - Erinnerung an Fuhrparkmanager",
			123: "Bestätigung erfolgreiche Kontrolle",
			124: "Bestätigung ungültige Kontrolle - an Fuhrparkmanager",
			125: "Erste Führerscheinkontrolle ungültig",
			126: "Eskalation Führerscheinkontrolle – Erinnerung 2",
			127: "Eskalation Führerscheinkontrolle – Erinnerung 3",
			128: "Fehlgeschlagene Führerscheinkontrolle",
			129: "Abgebrochene Führerscheinkontrolle",

			130: "UVV-Aufforderung",
			131: "Eskalation UVV – Erinnerung 1",
			132: "Eskalation UVV – Erinnerung 2",
			133: "Eskalation UVV – Erinnerung 3",
			134: "Eskalation UVV - Erinnerung an Fuhrparkmanager",
			135: "Eskalation UVV - Erinnerung 2 an Fuhrparkmanager",
			136: "Bestätigung erfolgreiche UVV",
			137: "Abgelaufene Führerscheinkontrolle",
			140: "Erinnerungen an FPM Ablaufdatum Führerschein 1",
			141: "Erinnerungen an FPM Ablaufdatum Führerschein 2",
			142: "Erinnerungen an FPM Ablaufdatum Führerschein - Ablauftag",

			150: "Neue Fotokontrolle - an Admin",
			151: "Bestätigung fehlgeschlagene Fotokontrolle",
			152: "Bestätigung erfolgreiche Fotokontrolle",
			153: "Bestätigung Nutzerupload erfolgreich",
			154: "Erste fehlgeschlagene Fotokontrolle",
			155: "Erinnerung Admin Fotokontrolle",

			230: "LKW-UVV-Aufforderung",
			231: "Eskalation LKW-UVV – Erinnerung 1",
			232: "Eskalation LKW-UVV – Erinnerung 2",
			233: "Eskalation LKW-UVV – Erinnerung 3",
			234: "Eskalation LKW-UVV - Erinnerung an Fuhrparkmanager",
			235: "Eskalation LKW-UVV - Erinnerung 2 an Fuhrparkmanager",
			236: "Bestätigung erfolgreiche LKW-UVV",

			300: " - Aufforderung",
			301: " - Erinnerung 1",
			302: " - Erinnerung 2",
			303: " - Erinnerung 3",
			304: " - Erinnerung an Fuhrparkmanager",
			305: " - Erfolgreich",
		},
	},

	invoices: {
		label: "Rechnungen",
		fields: {
			tenantName: "Abteilung",
			invoiceDate: "Rechnungsdatum",
			startDate: "Startdatum",
			endDate: "Enddatum",
			countDriverType0: "Firmenwagenfahrer",
			countDriverType1: "Gelegenheitsfahrer",
			totalDrivers: "Gesamt",
			includeUVV: "inkl. UVV",
			uvvDrivers: "UVV Fahrer",
			totalDiff: "Differenz",
			smsCount: "SMS",
		},
		currentRange: "Aktuelle Auswahl",
		pastRange: "Vergangenes Interval",
	},
	superTenantInvoices: {
		label: "Verbundrechnungen",
		fields: {
			name: "Verbund",
			invoicedate: "Rechnungsdatum",
			startdate: "Startdatum",
			enddate: "Enddatum",
			countdrivertype0: "Firmenwagenfahrer",
			countdrivertype1: "Gelegenheitsfahrer",
			totaldrivers: "Gesamt",
			uvvdrivers: "UVV Fahrer",
		},
	},
	statsPerf: {
		label: "Performance Statistiken",
		fields: {
			name: "Abteilung",
			requested: "Anzahl an versandten Aufforderung mit Prüfergebnis",
			success: "Summe erfolgreiche Prüfungen (1 + 2 Durchgang)",
			successFirst: "Erfolgreiche Prüfungen im ersten Durchgang",
			successSecond: "Erfolgreich Prüfungen im zweiten Durchgang",
			canceled: "Abgebrochen",
			failedFirst: "1. Fehlgeschlagen",
			failed: "Fehlgeschlagen",
			eventScheduled: "Anzahl an geplanten Video-Prüfungen",
			manualControlSa: "Durchgeführte manuelle Kontrolle durch edding",
			manualControl: "Durchgeführte manuelle Kontrolle durch Kunden",
			ratioSuccess: "Quote Gesamt - Prüfung nach dem 1. & 2. Versuch erfolgreich",
			ratioFirst: "Quote - Prüfung nach dem 1. Versuch erfolgreich",
			ratioSecond: "Quote - Prüfung nach dem 2. Versuch erfolgreich",
			ratioFailed: "Quote Fehlgeschlagen",
			successLicenseType0: "Erfolgreich EU-FS",
			successLicenseType1: "Erfolgreich Rosa-FS",
			successLicenseType2: "Erfolgreich Grau-FS",
			ratioLicenseType0: "Erfolgsquote EU-FS",
			ratioLicenseType1: "Erfolgsquote Rosa-FS",
			ratioLicenseType2: "Erfolgsquote Grau-FS",
			created: "Start",
		},
	},
	photoControls: {
		label: "Fotokontrolle",
		plural: "Fotokontrollen",
		status: "Status Fotokontrolle",
		noDriver: "Invalide Kontrolle. Kein Fahrer zugewiesen",
		frontPics: "Vorderseite Führerschein",
		backPics: "Rückseite Führerschein",
		commentHeading: "Kontroll-Protokoll",
		reasons: "Freigabe nicht möglich",
		done: "Kontrolle abgeschlossen",
		rejectionHeading: "Gründe warum Freigabe nicht möglich",
		acceptButton: "Führerschein freigeben",
		rejectButton: "Freigabe nicht möglich",
		date: "Prüfdatum",
		send: "Absenden",
		success: "Freigabe erfolgreich",
		fail: "Freigabe aus folgenden Gründen nicht möglich",
		quiz: "Aufgabe:",
		showDone: "Fertiggestellte anzeigen",
		fields: {
			driver: "Fahrer",
			inspector: "Bearbeiter",
			tenant: "Abteilung",
			createdAt: "Erstellt",
		},
		tasks: {
			1: "Legen Sie den Zeigefinger der rechten Hand auf die rechte untere Ecke des Führerscheins und fotografieren ihn.",
			2: "Legen Sie den Zeigefinger der rechten Hand auf die rechte obere Ecke des Führerscheins und fotografieren ihn.",
			3: "Legen Sie den Zeigefinger der rechten Hand auf die rechte Seite des Führerscheins und fotografieren ihn.",
			4: "Legen Sie den Zeigefinger der rechten Hand auf die obere Seite des Führerscheins und fotografieren ihn.",
			5: "Legen Sie den Zeigefinger der rechten Hand auf die untere Seite des Führerscheins und fotografieren ihn.",

			6: "Legen Sie den Daumen der rechten Hand auf die rechte untere Ecke des Führerscheins und fotografieren ihn.",
			7: "Legen Sie den Daumen der rechten Hand auf die rechte obere Ecke des Führerscheins und fotografieren ihn.",
			8: "Legen Sie den Daumen der rechten Hand auf die rechte Seite des Führerscheins und fotografieren ihn.",
			9: "Legen Sie den Daumen der rechten Hand auf die obere Seite des Führerscheins und fotografieren ihn.",
			10: "Legen Sie den Daumen der rechten Hand auf die untere Seite des Führerscheins und fotografieren ihn.",

			11: "Legen Sie den kleinen Finger der rechten Hand auf die rechte untere Ecke des Führerscheins und fotografieren ihn.",
			12: "Legen Sie den kleinen Finger der rechten Hand auf die rechte obere Ecke des Führerscheins und fotografieren ihn.",
			13: "Legen Sie den kleinen Finger der rechten Hand auf die rechte Seite des Führerscheins und fotografieren ihn.",
			14: "Legen Sie den kleinen Finger der rechten Hand auf die obere Seite des Führerscheins und fotografieren ihn.",
			15: "Legen Sie den kleinen Finger der rechten Hand auf die untere Seite des Führerscheins und fotografieren ihn.",

			16: "Legen Sie den Zeigefinger der linken Hand auf die linke untere Ecke des Führerscheins und fotografieren ihn.",
			17: "Legen Sie den Zeigefinger der linken Hand auf die linke obere Ecke des Führerscheins und fotografieren ihn.",
			18: "Legen Sie den Zeigefinger der linken Hand auf die linke Seite des Führerscheins und fotografieren ihn.",

			19: "Legen Sie den Daumen der linken Hand auf die linke untere Ecke des Führerscheins und fotografieren ihn.",
			20: "Legen Sie den Daumen der linken Hand auf die linke obere Ecke des Führerscheins und fotografieren ihn.",
			21: "Legen Sie den Daumen der linken Hand auf die linke Seite des Führerscheins und fotografieren ihn.",

			22: "Legen Sie den kleinen Finger der linken Hand auf die linke untere Ecke des Führerscheins und fotografieren ihn.",
			23: "Legen Sie den kleinen Finger der linken Hand auf die linke obere Ecke des Führerscheins und fotografieren ihn.",
			24: "Legen Sie den kleinen Finger der linken Hand auf die linke Seite des Führerscheins und fotografieren ihn.",
		},
	},
};

const i18nProvider = polyglotI18nProvider(locale => (locale === "de" ? german : english), "de");

export default i18nProvider;
