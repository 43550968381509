import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import React from "react";
import { useTranslate } from "react-admin";
import { Cell, Legend, Pie, PieChart } from "recharts";

const useStyles = makeStyles(theme => ({
	legendContainer: {
		display: "flex",
		flexDirection: "column",
		padding: "0 5px",
		margin: 5,
		borderLeft: "3px solid green",
	},
	label: {
		fontSize: 12,
		color: theme.palette.gray.main,
	},
	value: {
		fontSize: 14,
		color: "black",
	},
}));

const DriverByUvvChart = ({ monitoring }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const theme = useTheme();

	const data = [
		{
			label: translate("pages.monitoring.driversByUvv.overdue"),
			value: monitoring.driversCountByStatusUVV0,
			color: theme.palette.red.main,
		},
		{
			label: translate("pages.monitoring.driversByUvv.pending"),
			value: monitoring.driversCountByStatusUVV1,
			color: theme.palette.yellow.main,
		},
		{
			label: translate("pages.monitoring.driversByUvv.success"),
			value: monitoring.driversCountByStatusUVV2,
			color: theme.palette.green.main,
		},
	];

	const renderLegend = properties => {
		const { payload } = properties;
		return (
			<div>
				{payload.map((entry, index) => (
					<div
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className={classes.legendContainer}
						style={{ borderColor: entry.payload.color }}
					>
						<span className={classes.label}>{entry.value}</span>
						<span className={classes.value}>{entry.payload.value}</span>
					</div>
				))}
			</div>
		);
	};

	return (
		<PieChart width={430} height={300}>
			<Pie
				activeIndex={1}
				data={data}
				cx={110}
				dataKey="value"
				nameKey="label"
				innerRadius={70}
				outerRadius={100}
				fill="#8884d8"
				animationBegin={0}
				animationDuration={0}
				paddingAngle={1}
			>
				{data.map(value => (
					<Cell key={value.color} fill={value.color} />
				))}
			</Pie>
			<Legend
				wrapperStyle={{ top: 100 }}
				content={renderLegend}
				align="right"
				layout="vertical"
				height={36}
			/>
		</PieChart>
	);
};

export default DriverByUvvChart;
