import { useEffect, useState, useCallback } from "react";

const useCookiebot = () => {
	const [isCookiebotReady, setCookiebotReady] = useState(false);
	const [consent, setConsent] = useState({
		necessary: true,
		preferences: false,
		statistics: false,
		marketing: false,
	});

	const updateConsentState = useCallback(() => {
		if ("Cookiebot" in window && typeof window.Cookiebot === "object") {
			setConsent({
				necessary: true,
				preferences: window.Cookiebot?.consent?.preferences ?? false,
				statistics: window.Cookiebot?.consent?.statistics ?? false,
				marketing: window.Cookiebot?.consent?.marketing ?? false,
			});
		}
	}, []);

	useEffect(() => {
		const handleConsentReady = () => {
			updateConsentState();
			setCookiebotReady(true);
		};

		const handleConsentAccept = () => {
			updateConsentState();
		};

		const handleConsentDecline = () => {
			updateConsentState();
		};

		window.addEventListener("CookiebotOnConsentReady", handleConsentReady);

		window.addEventListener("CookiebotOnAccept", handleConsentAccept);
		window.addEventListener("CookiebotOnDecline", handleConsentDecline);

		return () => {
			window.removeEventListener("CookiebotOnConsentReady", handleConsentReady);
			window.removeEventListener("CookiebotOnAccept", handleConsentAccept);
			window.removeEventListener("CookiebotOnDecline", handleConsentDecline);
		};
	}, [updateConsentState]);

	const showBanner = useCallback(() => {
		if (window.Cookiebot) {
			window.Cookiebot.show();
		}
	}, []);

	const hideBanner = useCallback(() => {
		if (window.Cookiebot) {
			window.Cookiebot.hide();
		}
	}, []);

	const renewConsent = useCallback(() => {
		if (window.Cookiebot) {
			window.Cookiebot.renew();
		}
	}, []);

	const submitCustomConsent = useCallback((optinPreferences, optinStatistics, optinMarketing) => {
		if (window.Cookiebot) {
			window.Cookiebot.submitCustomConsent(optinPreferences, optinStatistics, optinMarketing);
		}
	}, []);

	return {
		isCookiebotReady,
		consent,
		showBanner,
		hideBanner,
		renewConsent,
		submitCustomConsent,
	};
};

export default useCookiebot;
