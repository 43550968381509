import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
// import { useListContext } from 'react-admin';
import { Filter, TextInput } from "react-admin";

import { useOutlinedInputStyles } from "../../common/theme";

const SearchFilter = properties => (
	<Filter {...properties} variant="outlined">
		<TextInput
			resettable
			fullWidth
			alwaysOn
			style={{ width: "100%" }}
			helperText={false}
			source="q"
			label=""
			placeholder="Suchen"
			variant="outlined"
			InputProps={{
				classes: useOutlinedInputStyles(),
				startAdornment: (
					<InputAdornment>
						<SearchIcon />
					</InputAdornment>
				),
			}}
		/>
		{properties.children}
	</Filter>
);
// const {
//   filterValues,
//   setFilters,
// } = useListContext();

export default SearchFilter;
