import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import Done from "@material-ui/icons/Done";
import SearchIcon from "@material-ui/icons/Search";
import { debounce } from "lodash";
import React from "react";
import {
	Datagrid,
	List,
	TextField,
	TextInput,
	useListContext,
	usePermissions,
	useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { connect } from "react-redux";

import PermissionUtils from "../common/permissionUtils";
import { useOutlinedInputStyles } from "../common/theme";
import AutocompleteFilter from "../components/AutocompleteFilter";
import LargeSwitch from "../components/LargeSwitch";
import DateTimeField from "../components/table/DateTimeField";
import NameField from "../components/table/NameField";
import { useGlobalViewV2 } from "../hooks/global-view-v2";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

const StatusField = ({ record }) => {
	const translate = useTranslate();
	if (!record) {
		return <span />;
	}
	switch (record.status) {
		case 1: {
			return <span>{translate("drivers.tableStates.pending")}</span>;
		}
		case 2: {
			return (
				<span>
					<Done />
				</span>
			);
		}
		case 0: {
			return <span>{translate("drivers.tableStates.fail")}</span>;
		}
		case 3: {
			return <span>{translate("drivers.tableStates.controlFailedFirst")}</span>;
		}
		case 7: {
			return <span>{translate("drivers.tableStates.other")}</span>;
		}
		//
		// case -1:
		default: {
			return <span>{translate("drivers.states.created")}</span>;
		}
	}
};

const PhotoControlFilter = ({ globalView }) => {
	const translate = useTranslate();
	const classes = useOutlinedInputStyles();
	const permissions = new PermissionUtils(usePermissions());
	const { filterValues, setFilters } = useListContext();
	const globalViewV2 = useGlobalViewV2(globalView);

	const handleFilterChange = filters => {
		setFilters(filters, {});
	};

	const handleShowDoneChange = () => {
		if (filterValues.status) {
			handleFilterChange({ ...filterValues, status: undefined });
		} else {
			handleFilterChange({ ...filterValues, status: [1] });
		}
	};

	const handleTenantChange = selected => {
		setFilters({ ...filterValues, fk_tenantId: selected }, []);
	};

	const onHandleSubmit = values => {
		if (Object.keys(values).length > 0) {
			setFilters(values);
		}
	};

	return (
		<Box width="100%" pt={2} display="flex" flexDirection="row" alignItems="center">
			<Form initialValues={filterValues} onSubmit={onHandleSubmit}>
				{({ handleSubmit }) => (
					<form style={{ width: "100%" }} onSubmit={handleSubmit}>
						<Grid container justifyContent="space-between" alignItems="center">
							<Grid item xs={4}>
								<TextInput
									resettable
									fullWidth
									alwaysOn
									style={{ width: "100%" }}
									helperText={false}
									source="q"
									label=""
									placeholder="Suchen"
									variant="outlined"
									InputProps={{
										classes,
										startAdornment: (
											<InputAdornment>
												<SearchIcon />
											</InputAdornment>
										),
									}}
									onChange={() => debounce(handleSubmit, 100)}
								/>
							</Grid>
							<Grid item xs={4}>
								<Box
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="end"
								>
									<LargeSwitch
										checked={!filterValues.status}
										onChange={handleShowDoneChange}
									/>
									<Box ml={1}>
										<Typography variant="h6">
											{translate("photoControls.showDone")}
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
						{(((permissions.isSuperAdmin() || permissions.isTenantAdmin()) &&
							globalView) ||
							globalViewV2) && (
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="left"
								my={2}
							>
								<Box mr={1}>
									<AutocompleteFilter
										resource="tenants"
										label={translate("users.fields.tenant")}
										selected={filterValues.fk_tenantId}
										width={300}
										disabled={
											!permissions.isSuperAdmin() &&
											!permissions.isTenantAdmin()
										}
										onChange={handleTenantChange}
									/>
								</Box>
							</Box>
						)}
					</form>
				)}
			</Form>
		</Box>
	);
};
const PhotoControlList = properties => {
	const { options, globalView } = properties;
	const translate = useTranslate();
	const theme = useTheme();

	const statusRowStyle = record => {
		if (!record) {
			return {};
		}
		switch (record.status) {
			case 1: {
				return { backgroundColor: theme.palette.yellow.light };
			}
			case 2: {
				return { backgroundColor: theme.palette.green.light };
			}
			case 0:
			case 3: {
				return { backgroundColor: theme.palette.red.light };
			}
			//
			// case -1:
			default: {
				return { backgroundColor: theme.palette.primary.light };
			}
		}
	};

	return (
		<div>
			<TitleBreadcrumbs title={translate(options.label)} />
			<List
				{...properties}
				filters={<PhotoControlFilter globalView={globalView} />}
				actions={null}
				empty={false}
				sort={{ field: "createdAt", order: "DESC" }}
				filterDefaultValues={{ status: [-1, 1] }}
			>
				<Datagrid rowClick="show" rowStyle={statusRowStyle}>
					<NameField
						label="photoControls.fields.driver"
						source="Driver"
						sortBy="Driver.firstName"
					/>
					<StatusField source="status" label="drivers.fields.statusFS" />
					<DateTimeField source="createdAt" label="photoControls.fields.createdAt" />
					<NameField
						source="User"
						label="photoControls.fields.inspector"
						sortBy="User.firstName"
					/>
					<TextField source="Tenant.name" label="photoControls.fields.tenant" />
				</Datagrid>
			</List>
		</div>
	);
};

const mapStateToProperties = state => ({
	globalView: state.settings.globalView,
});

export default connect(mapStateToProperties)(PhotoControlList);
