import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";

const handleMouseDownPassword = event => {
	event.preventDefault();
};

const PasswordTextField = properties => {
	const [visible, setVisible] = useState(false);

	const handleClickShowPassword = () => {
		setVisible(!visible);
	};

	return (
		<TextField
			id="outlined-adornment-password"
			type={visible ? "text" : "password"}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
						>
							{visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
						</IconButton>
					</InputAdornment>
				),
			}}
			{...properties}
		/>
	);
};

export default PasswordTextField;
