import { call, put, takeEvery } from "redux-saga/effects";

import statsProvider from "../providers/statsProvider";

import { setDashboardData, setMonitoringData } from "./statsReducer";

export default function* statsSaga() {
	yield takeEvery("FETCH_DASHBOARD", function* ({ payload }) {
		try {
			const { driverInfo } = yield call(statsProvider.getDashboard, payload);
			yield put(setDashboardData(driverInfo));
		} catch {
			yield put(setDashboardData({}));
		}
	});

	yield takeEvery("FETCH_MONITORING", function* ({ payload }) {
		try {
			const data = yield call(statsProvider.getMonitoring, payload);
			yield put(setMonitoringData(data));
		} catch {
			yield put(setMonitoringData({}));
		}
	});
}
