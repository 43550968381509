import Done from "@material-ui/icons/Done";
import React from "react";
import { useTranslate } from "react-admin";

const ControlStatusField = ({ record, controlType }) => {
	const translate = useTranslate();
	const controlStatus = record.ControlStatuses.find(c => c.controlType === controlType);

	const state = controlStatus ? controlStatus.status : 6;

	switch (state) {
		case 4: {
			return <span>{translate("drivers.tableStates.initPending")}</span>;
		}
		case 1:
		case 3:
		case 5: {
			return <span>{translate("drivers.tableStates.pending")}</span>;
		}
		case 2: {
			return (
				<span>
					<Done />
				</span>
			);
		}
		case 0: {
			return <span>{translate("drivers.tableStates.fail")}</span>;
		}
		case -1:
		case 6: {
			return translate("drivers.states.created");
		}
		default: {
			return <span />;
		}
	}
};

export default ControlStatusField;
