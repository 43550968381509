import { ListItemIcon } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import SvgIcon from "@material-ui/core/SvgIcon";
import AssessmentIcon from "@material-ui/icons/AssessmentOutlined";
import LabelIcon from "@material-ui/icons/LabelOutlined";
import classNames from "classnames";
import * as React from "react";
import { getResources, MenuItemLink, usePermissions, useTranslate } from "react-admin";
import { useSelector } from "react-redux";

import { ReactComponent as AdminIcon } from "../assets/icons/sidebar/admin.svg";
import { ReactComponent as AdminActiveIcon } from "../assets/icons/sidebar/admin_active.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/sidebar/dashboard.svg";
import { ReactComponent as DashboardActiveIcon } from "../assets/icons/sidebar/dashboard_active.svg";
import { ReactComponent as DriverIcon } from "../assets/icons/sidebar/driver.svg";
import { ReactComponent as DriverActiveIcon } from "../assets/icons/sidebar/driver_active.svg";
import { ReactComponent as InvoiceIcon } from "../assets/icons/sidebar/invoice.svg";
import { ReactComponent as InvoiceActiveIcon } from "../assets/icons/sidebar/invoice_active.svg";
import { ReactComponent as PhotoIcon } from "../assets/icons/sidebar/photo_control.svg";
import { ReactComponent as PhotoActiveIcon } from "../assets/icons/sidebar/photo_control_active.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/sidebar/plus.svg";
import { ReactComponent as PlusActiveIcon } from "../assets/icons/sidebar/plus_active.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/sidebar/settings.svg";
import { ReactComponent as SettingsActiveIcon } from "../assets/icons/sidebar/settings_active.svg";
import { ReactComponent as SupportIcon } from "../assets/icons/sidebar/support.svg";
import { ReactComponent as SupportActiveIcon } from "../assets/icons/sidebar/support_active.svg";
import PermissionUtils from "../common/permissionUtils";
import { useIsMigratedMATM } from "../hooks/global-view-v2";

const useMenuItemStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		height: 58,
		borderLeft: "4px solid transparent",
		fontWeight: 600,
	},
	active: {
		color: theme.palette.gray.main,
	},
}));
const useNewMenuItemStyles = makeStyles(theme => ({
	root: {
		height: 58,
		width: "calc(100% - 4px)",
		fontWeight: "bold",
		color: theme.palette.text.secondary,
		marginLeft: 4,
	},
	icon: { minWidth: 36 },
}));

const useStyles = makeStyles(theme => ({
	container: {
		width: "100%",
	},
	active: {
		backgroundImage: `linear-gradient(to left, ${theme.palette.primary.light}, #ffffff)`,
		borderLeft: `4px solid ${theme.palette.primary.main}`,
		color: theme.palette.primary.main,
	},
	":hover": {
		display: "none",
	},
}));

const MyMenuItem = ({ primaryText, sidebarIsOpen, leftIcon, ...rest }) => {
	const menuItemStyles = useMenuItemStyles();
	return (
		<MenuItemLink
			classes={menuItemStyles}
			primaryText={sidebarIsOpen ? primaryText : ""}
			leftIcon={
				<SvgIcon component="span" style={{ width: "unset", height: "unset" }}>
					{leftIcon}
				</SvgIcon>
			}
			{...rest}
		/>
	);
};

const NewMenu = ({ onMenuClick }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const permissions = new PermissionUtils(usePermissions());
	const open = useSelector(state => state.admin.ui.sidebarOpen);
	const activeRoute = useSelector(state => state.router.location.pathname);
	const theme = useTheme();
	const menuClasses = useNewMenuItemStyles();
	const resources = useSelector(getResources);
	const activeResources = resources.map(resource => resource.name);

	return (
		<>
			<MyMenuItem
				key="dashboard"
				to="/dashboard"
				className={activeRoute === "/dashboard" ? classes.active : null}
				primaryText={translate("pages.dashboard.title")}
				sidebarIsOpen={open}
				leftIcon={
					activeRoute === "/dashboard" ? <DashboardActiveIcon /> : <DashboardIcon />
				}
				onClick={onMenuClick}
			/>
			<MyMenuItem
				key="drivers"
				to="/drivers"
				className={activeRoute === "/drivers" ? classes.active : null}
				primaryText={translate("pages.driverList.title")}
				leftIcon={activeRoute === "/drivers" ? <DriverActiveIcon /> : <DriverIcon />}
				sidebarIsOpen={open}
				onClick={onMenuClick}
			/>
			<MenuItem
				component="a"
				href="/driver/create"
				tabIndex={0}
				className={menuClasses.root}
				onClick={onMenuClick}
			>
				<ListItemIcon className={classNames(classes.icon, menuClasses.icon)}>
					<PlusIcon />
				</ListItemIcon>
				{open && translate("pages.driverCreate.title")}
			</MenuItem>
			{permissions.hasPhotoControl() && (
				<MyMenuItem
					key="photo-controls"
					to="/photo-controls"
					className={activeRoute === "/photo-controls" ? classes.active : null}
					primaryText={translate("photoControls.plural")}
					sidebarIsOpen={open}
					leftIcon={
						activeRoute === "/photo-controls" ? <PhotoActiveIcon /> : <PhotoIcon />
					}
					onClick={onMenuClick}
				/>
			)}
			{permissions.isSuperAdminV2() && (
				<MenuItem
					component="a"
					href={`/organization/${permissions.getSuperTenantId()}/managers`}
					tabIndex={0}
					className={menuClasses.root}
					onClick={onMenuClick}
				>
					<ListItemIcon className={classNames(classes.icon, menuClasses.icon)}>
						<AdminIcon />
					</ListItemIcon>
					{open && translate("pages.admin.title")}
				</MenuItem>
			)}

			<MyMenuItem
				key="orderLabel"
				to="/admin/order-label"
				className={activeRoute.includes("admin/order-label") ? classes.active : null}
				primaryText={translate("labels.label")}
				sidebarIsOpen={open}
				leftIcon={
					<LabelIcon
						style={{
							color: activeRoute.includes("admin/order-label")
								? theme.palette.primary.main
								: theme.palette.gray.main,
						}}
					/>
				}
				onClick={onMenuClick}
			/>
			<MyMenuItem
				key="driversReport"
				to="/drivers-report"
				className={activeRoute.includes("drivers-report") ? classes.active : null}
				primaryText={translate("driversReport.label")}
				sidebarIsOpen={open}
				leftIcon={
					<AssessmentIcon
						style={{
							color: activeRoute.includes("drivers-report")
								? theme.palette.primary.main
								: theme.palette.gray.main,
						}}
					/>
				}
				onClick={onMenuClick}
			/>
			{permissions.isSuperAdmin() && (
				<MyMenuItem
					key="invoice"
					to="/invoices"
					className={activeRoute.includes("invoices") ? classes.active : null}
					primaryText={translate("invoices.label")}
					sidebarIsOpen={open}
					leftIcon={
						activeRoute.includes("invoices") ? <InvoiceActiveIcon /> : <InvoiceIcon />
					}
					onClick={onMenuClick}
				/>
			)}

			<MyMenuItem
				key="support"
				to="/support"
				className={activeRoute.includes("support") ? classes.active : null}
				primaryText={translate("pages.support.title")}
				leftIcon={activeRoute.includes("support") ? <SupportActiveIcon /> : <SupportIcon />}
				sidebarIsOpen={open}
				onClick={onMenuClick}
			/>

			<MyMenuItem
				key="settings"
				to="/settings"
				className={activeRoute.includes("settings") ? classes.active : null}
				primaryText={translate("pages.settings.title")}
				sidebarIsOpen={open}
				leftIcon={
					activeRoute.includes("settings") ? <SettingsActiveIcon /> : <SettingsIcon />
				}
				onClick={onMenuClick}
			/>
		</>
	);
};

const OldMenu = ({ onMenuClick }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const permissions = new PermissionUtils(usePermissions());
	const open = useSelector(state => state.admin.ui.sidebarOpen);
	const resources = useSelector(getResources);
	const activeRoute = useSelector(state => state.router.location.pathname);
	const theme = useTheme();
	const renderResources = () =>
		resources
			.filter(
				resource =>
					!resource.options.noMenu &&
					(!resource.options.showFunc || resource.options.showFunc(permissions))
			)
			.map(resource => {
				const to = `/${resource.name}`;
				const isActive = activeRoute === to;
				return (
					<div key={to}>
						<MyMenuItem
							className={isActive ? classes.active : null}
							to={to}
							sidebarIsOpen={open}
							primaryText={
								(resource.options && translate(resource.options.label)) ||
								resource.name
							}
							leftIcon={
								isActive ? <resource.options.activeIcon /> : <resource.icon />
							}
							onClick={onMenuClick}
						/>
						{resource.options?.menuItems
							?.filter(m => permissions.checkPermissions(m.permissions))
							.map(menuItem => {
								const isSubActive = activeRoute === menuItem.to;
								return (
									<MyMenuItem
										key={menuItem.to}
										className={isSubActive ? classes.active : null}
										to={menuItem.to}
										primaryText={menuItem.label}
										sidebarIsOpen={open}
										leftIcon={
											isSubActive ? (
												<menuItem.activeIcon />
											) : (
												<menuItem.icon />
											)
										}
										onClick={onMenuClick}
									/>
								);
							})}
					</div>
				);
			});

	return (
		<div className={classes.container}>
			<MyMenuItem
				key="dashboard"
				to="/dashboard"
				className={activeRoute === "/dashboard" ? classes.active : null}
				primaryText={translate("pages.dashboard.title")}
				sidebarIsOpen={open}
				leftIcon={
					activeRoute === "/dashboard" ? <DashboardActiveIcon /> : <DashboardIcon />
				}
				onClick={onMenuClick}
			/>
			<MenuItem
				component="a"
				href="/driver/create"
				tabIndex={0}
				style={{ height: 58, fontWeight: "bold", color: theme.palette.text.secondary }}
				onClick={onMenuClick}
			>
				<ListItemIcon className={classes.icon} style={{ marginLeft: 8, minWidth: 36 }}>
					{activeRoute.includes("drivers/create") ? <PlusActiveIcon /> : <PlusIcon />}
				</ListItemIcon>
				{open && translate("pages.driverCreate.title")}
			</MenuItem>

			{renderResources()}

			{permissions.isTenantAdmin() &&
				!permissions.isSuperAdmin() &&
				permissions.canEdit() && (
					<MyMenuItem
						key="admin"
						to="/groups"
						primaryText={translate("pages.admin.title")}
						sidebarIsOpen={open}
						className={
							activeRoute.includes("groups") ||
							activeRoute.includes("users") ||
							activeRoute.includes("businessUnits") ||
							activeRoute.includes("tenants")
								? classes.active
								: null
						}
						leftIcon={
							activeRoute.includes("groups") ||
							activeRoute.includes("users") ||
							activeRoute.includes("businessUnits") ||
							activeRoute.includes("tenants") ? (
								<AdminActiveIcon />
							) : (
								<AdminIcon />
							)
						}
						onClick={onMenuClick}
					/>
				)}
			{permissions.isSuperAdmin() && (
				<MyMenuItem
					key="admin"
					to="/super-tenants"
					primaryText={translate("pages.admin.title")}
					sidebarIsOpen={open}
					className={
						activeRoute.includes("super-tenants") ||
						activeRoute.includes("users") ||
						activeRoute.includes("businessUnits") ||
						activeRoute.includes("tenants")
							? classes.active
							: null
					}
					leftIcon={
						activeRoute.includes("super-tenants") ||
						activeRoute.includes("users") ||
						activeRoute.includes("businessUnits") ||
						activeRoute.includes("tenants") ? (
							<AdminActiveIcon />
						) : (
							<AdminIcon />
						)
					}
					onClick={onMenuClick}
				/>
			)}

			{permissions.isGroupAdmin() &&
				permissions.canEdit() &&
				!permissions.isTenantAdmin() &&
				!permissions.isSuperAdmin() && (
					<MyMenuItem
						key="admin"
						to="/tenants"
						primaryText={translate("pages.admin.title")}
						sidebarIsOpen={open}
						className={
							activeRoute.includes("tenants") ||
							activeRoute.includes("users") ||
							activeRoute.includes("businessUnits") ||
							activeRoute.includes("tenants")
								? classes.active
								: null
						}
						leftIcon={
							activeRoute.includes("tenants") ||
							activeRoute.includes("users") ||
							activeRoute.includes("businessUnits") ||
							activeRoute.includes("tenants") ? (
								<AdminActiveIcon />
							) : (
								<AdminIcon />
							)
						}
						onClick={onMenuClick}
					/>
				)}

			{permissions.isFpm() && permissions.canEdit() && !permissions.isSuperAdmin() && (
				<MyMenuItem
					key="admin"
					to="/users"
					primaryText={translate("pages.admin.title")}
					sidebarIsOpen={open}
					className={
						activeRoute.includes("admin") ||
						activeRoute.includes("users") ||
						activeRoute.includes("businessUnits") ||
						activeRoute.includes("tenants")
							? classes.active
							: null
					}
					leftIcon={
						activeRoute.includes("admin") ||
						activeRoute.includes("users") ||
						activeRoute.includes("businessUnits") ||
						activeRoute.includes("tenants") ? (
							<AdminActiveIcon />
						) : (
							<AdminIcon />
						)
					}
					onClick={onMenuClick}
				/>
			)}

			{permissions.isSuperAdmin() && (
				<MyMenuItem
					key="invoice"
					to="/invoices"
					className={activeRoute.includes("invoices") ? classes.active : null}
					primaryText={translate("invoices.label")}
					sidebarIsOpen={open}
					leftIcon={
						activeRoute.includes("invoices") ? <InvoiceActiveIcon /> : <InvoiceIcon />
					}
					onClick={onMenuClick}
				/>
			)}

			<MyMenuItem
				key="support"
				to="/support"
				className={activeRoute.includes("support") ? classes.active : null}
				primaryText={translate("pages.support.title")}
				leftIcon={activeRoute.includes("support") ? <SupportActiveIcon /> : <SupportIcon />}
				sidebarIsOpen={open}
				onClick={onMenuClick}
			/>

			<MyMenuItem
				key="settings"
				to="/settings"
				className={activeRoute.includes("settings") ? classes.active : null}
				primaryText={translate("pages.settings.title")}
				sidebarIsOpen={open}
				leftIcon={
					activeRoute.includes("settings") ? <SettingsActiveIcon /> : <SettingsIcon />
				}
				onClick={onMenuClick}
			/>
		</div>
	);
};

const Menu = ({ onMenuClick }) => {
	const isMigrated = useIsMigratedMATM();

	return isMigrated ? (
		<NewMenu onMenuClick={onMenuClick} />
	) : (
		<OldMenu onMenuClick={onMenuClick} />
	);
};

export default Menu;
