import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import {
	BulkDeleteButton,
	CreateButton,
	Datagrid,
	List,
	sanitizeListRestProps,
	TextField,
	TextInput,
	TopToolbar,
	useDataProvider,
	useListContext,
	usePermissions,
	useTranslate,
} from "react-admin";
import { Form } from "react-final-form";

import AdminHeader from "../admin/AdminHeader";
import PermissionUtils from "../common/permissionUtils";
import { useOutlinedInputStyles } from "../common/theme";
import SelectFilter from "../components/SelectFilter";
import BoolField from "../components/table/BoolField";
import DateField from "../components/table/DateField";
import MyPagination from "../components/table/Pagination";

const TenantFilter = () => {
	const translate = useTranslate();
	const permissions = new PermissionUtils(usePermissions());
	const inputStyles = useOutlinedInputStyles();
	const { filterValues, setFilters } = useListContext();

	const dataProvider = useDataProvider();
	const [superTenants, setSuperTenants] = useState([]);

	useEffect(() => {
		dataProvider
			.getList("super-tenants", {
				pagination: { page: 1, perPage: 1000 },
				filter: {},
				sort: {},
			})
			.then(res => {
				if (res && res.data) {
					setSuperTenants(res.data);
				}
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			!permissions.isSuperAdmin() &&
			permissions.getSuperTenantId() &&
			!filterValues.fk_superTenantId
		) {
			setFilters({ ...filterValues, fk_superTenantId: [permissions.getSuperTenantId()] }, []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [permissions, filterValues]);

	const handleSuperTenantChange = selected => {
		setFilters({ ...filterValues, fk_superTenantId: selected }, []);
	};

	const onHandleSubmit = values => {
		setFilters(values);
	};

	return (
		<Box width="100%">
			<Form initialValues={filterValues} onSubmit={onHandleSubmit}>
				{({ handleSubmit }) => (
					<form style={{ width: "100%" }} onSubmit={handleSubmit}>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="flex-start"
							mt={4}
							mb={2}
							width="100%"
						>
							<Box minWidth="50%">
								<TextInput
									resettable
									fullWidth
									alwaysOn
									helperText={false}
									source="q"
									label=""
									placeholder="Suchen"
									variant="outlined"
									margin="none"
									InputProps={{
										classes: inputStyles,
										startAdornment: (
											<InputAdornment position="end">
												<SearchIcon />
											</InputAdornment>
										),
									}}
									onChange={() => handleSubmit()}
								/>
							</Box>
						</Box>
						<Box
							width="100%"
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="left"
							mt={1}
							mb={2}
						>
							<Box mr={1}>
								{!isEmpty(superTenants) && permissions.isSuperAdmin() && (
									<SelectFilter
										choices={superTenants}
										label={translate("tenants.fields.superTenant")}
										selected={filterValues.fk_superTenantId}
										width={300}
										onChange={handleSuperTenantChange}
									/>
								)}
							</Box>
						</Box>
					</form>
				)}
			</Form>
		</Box>
	);
};

const TenantActions = properties => {
	const { className, exporter, filters, maxResults, ...rest } = properties;

	const { basePath } = useListContext();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			<CreateButton variant="contained" basePath={basePath} label="tenants.actions.create" />
		</TopToolbar>
	);
};

const TenantList = properties => {
	const { basePath } = properties;
	const permissions = new PermissionUtils(usePermissions());

	const BulkActionButtons = p => {
		if (permissions.canManage()) {
			return <BulkDeleteButton {...p} />;
		}
		return null;
	};
	return (
		<div>
			<AdminHeader active={basePath} />
			<List
				pagination={<MyPagination />}
				filters={<TenantFilter />}
				actions={permissions.canManage() && <TenantActions />}
				hasCreate={permissions.canManage()}
				bulkActionButtons={<BulkActionButtons />}
				filter={
					permissions.isSuperAdmin()
						? null
						: { fk_superTenantId: permissions.getSuperTenantId() }
				}
				{...properties}
			>
				<Datagrid rowClick="edit">
					<TextField source="name" label="tenants.fields.name" />
					<TextField source="SuperTenant.name" label="tenants.fields.superTenant" />
					<TextField source="Group.name" label="tenants.fields.group" />
					<BoolField source="active" label="tenants.fields.active" />
					<BoolField source="includeUVV" label="tenants.fields.includeUVV" />
					<TextField
						source="controlIntervalDays"
						label="tenants.fields.controlIntervalDays"
					/>
					<DateField source="createdAt" label="tenants.fields.createdAt" />
				</Datagrid>
			</List>
		</div>
	);
};

export default TenantList;
