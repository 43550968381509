import PropTypes from "prop-types";
import React, { useState } from "react";
import {
	Button,
	Confirm,
	useNotify,
	usePermissions,
	useRefresh,
	useUnselectAll,
} from "react-admin";

import PermissionUtils from "../common/permissionUtils";
import GroupSelect from "../components/GroupSelect";
import customProvider from "../providers/customProvider";

Confirm.propTypes = {
	...Confirm.propTypes,
	// eslint-disable-next-line react/forbid-prop-types
	content: PropTypes.any,
};

const TenantGroupBatchMove = ({ selectedIds }) => {
	const [open, setOpen] = useState(false);
	const [group, setGroup] = useState("");

	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const permissions = new PermissionUtils(usePermissions());

	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = async () => {
		try {
			await customProvider.customBulk("tenants", {
				action: "update",
				tenantIds: selectedIds,
				attributes: {
					fk_groupId: group ? group.id : null,
				},
			});
			refresh();
			notify("common.successBatch");
			unselectAll("tenants");
		} catch {
			notify("common.fail");
			setOpen(false);
		}
	};

	return (
		<>
			<Button label="tenants.actions.move" style={{ padding: 10 }} onClick={handleClick} />

			<Confirm
				isOpen={open}
				title="tenants.actions.move"
				content={
					<GroupSelect
						selected={group}
						superTenantId={
							permissions.isSuperAdmin() ? undefined : permissions.getSuperTenantId()
						}
						onChange={setGroup}
					/>
				}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
		</>
	);
};

export default TenantGroupBatchMove;
