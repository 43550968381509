import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import React from "react";
import { setSidebarVisibility, Sidebar } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import package_ from "../../package.json";
import IconSmall from "../assets/img/edding-logo-small.png";
import Icon from "../assets/img/edding-logo.svg";

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 76;

const useSidebarStyles = makeStyles({
	drawerPaper: {
		zIndex: 1200,
		width: properties => (properties.open ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH),
	},
});

const useStyles = makeStyles(theme => ({
	logoWrapper: {
		marginTop: 22,
		marginBottom: 57,
		display: "flex",
		justifyContent: "center",
	},
	logoImage: {
		objectFit: "scale-down",
		height: properties => (properties.open ? 50 : 33),
	},
	sidebarContent: {
		width: properties => (properties.open ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH) - 12,
		height: "100%",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
	},
	roundToggle: {
		width: "24px",
		height: "24px",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
		border: "solid 1px #e8eaf0",
		backgroundColor: theme.palette.primary.main,
		borderRadius: "50%",
		color: "white",
		position: "absolute",
		cursor: "pointer",
		bottom: 58,
		right: 0,
	},
}));

const MySidebar = ({ children, ...properties }) => {
	const dispatch = useDispatch();
	const open = useSelector(state => state.admin.ui.sidebarOpen);

	const sidebarStyles = useSidebarStyles({ ...properties, open });
	const classes = useStyles({ ...properties, open });

	const toggleSidebar = () => {
		dispatch(setSidebarVisibility(!open));
	};
	return (
		<Sidebar classes={sidebarStyles} {...properties} open={open}>
			<div className={classes.sidebarContent}>
				<div className={classes.logoWrapper}>
					<img
						className={classes.logoImage}
						src={open ? Icon : IconSmall}
						alt="company_logo"
					/>
				</div>
				{children}
				<div className={classes.roundToggle} onClick={toggleSidebar}>
					{open ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
				</div>
				<Chip label={`v${package_.version}`} size="small" style={{ marginTop: 16 }} />
			</div>
		</Sidebar>
	);
};

export default MySidebar;
