import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import {
	Create,
	FormWithRedirect,
	SaveButton,
	useNotify,
	usePermissions,
	useTranslate,
} from "react-admin";

import PermissionUtils from "../common/permissionUtils";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

import UserForm from "./UserForm";

const useStyles = makeStyles(() => ({
	toolbar: {
		backgroundColor: "inherit",
	},
}));

const adminDefaultValues = {
	role: 1,
	canRead: true,
	canEdit: true,
	canManage: true,
	isNotified: true,
};

const UserCreateToolbar = properties => {
	const permissions = new PermissionUtils(usePermissions());
	const classes = useStyles();
	const { invalid, pristine, saving, handleSubmitWithRedirect } = properties;

	const transformBeforeSubmit = data => ({
		...data,
		fk_tenantId: data.fk_tenantId || permissions.getTenantId(),
	});
	return (
		<div className={classes.toolbar}>
			<SaveButton
				variant="contained"
				saving={saving}
				handleSubmitWithRedirect={handleSubmitWithRedirect}
				label="users.actions.save"
				transform={transformBeforeSubmit}
				redirect={false}
				submitOnEnter={false}
				disabled={invalid || pristine}
			/>
		</div>
	);
};

const UserCreate = properties => {
	const translate = useTranslate();
	const notify = useNotify();

	const onFailure = () => {
		notify("pages.userCreate.error", "warning");
	};

	return (
		<div style={{ maxWidth: 800 }}>
			<TitleBreadcrumbs title={translate("pages.userCreate.title")} />
			<Create onFailure={onFailure} {...properties}>
				<FormWithRedirect
					initialValues={adminDefaultValues}
					render={formProperties => (
						<UserForm
							isCreate
							formProps={formProperties}
							toolbar={<UserCreateToolbar {...formProperties} />}
						/>
					)}
				/>
			</Create>
		</div>
	);
};

export default UserCreate;
