import { Button, Toolbar } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import React from "react";
import { useListContext } from "react-admin";

import StyledBaseInput from "../StyledBaseInput";

const useToolbarStyles = makeStyles(() => ({
	root: {
		height: 60,
		color: "black",
		fontSize: 14,
		fontWeight: 600,
		justifyContent: "flex-end",
		"& *": {},
	},
}));

const useStyles = makeStyles(() => ({
	margin: {
		margin: "0 10px",
	},
}));

const MyPagination = () => {
	const toolbarStyles = useToolbarStyles();
	const classes = useStyles();
	const { page, perPage, total, setPage, setPerPage } = useListContext();
	const nbPages = Math.ceil(total / perPage) || 1;

	return (
		<Toolbar classes={toolbarStyles}>
			<span>Zeilen pro Seite</span>
			<FormControl variant="outlined" className={classes.margin}>
				<Select
					value={perPage}
					input={<StyledBaseInput />}
					onChange={event => setPerPage(event.target.value)}
				>
					<MenuItem value={5}>5</MenuItem>
					<MenuItem value={10}>10</MenuItem>
					<MenuItem value={25}>25</MenuItem>
					<MenuItem value={50}>50</MenuItem>
					<MenuItem value={100}>100</MenuItem>
				</Select>
			</FormControl>

			<span className={classes.margin}>
				{`${1 + (page - 1) * perPage}-${Math.min(page * perPage, total)} von ${total}`}
			</span>

			<Button disabled={page === 1} onClick={() => setPage(1)}>
				<FirstPage />
			</Button>

			<Button key="prev" disabled={page === 1} onClick={() => setPage(page - 1)}>
				<ChevronLeft />
			</Button>

			<Button key="next" disabled={page === nbPages} onClick={() => setPage(page + 1)}>
				<ChevronRight />
			</Button>

			<Button disabled={page === nbPages} onClick={() => setPage(nbPages)}>
				<LastPage />
			</Button>
		</Toolbar>
	);
};

export default MyPagination;
