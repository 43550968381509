import withStyles from "@material-ui/core/styles/withStyles";

const OutlinedInput = inputComponent =>
	withStyles(theme => ({
		root: {
			"label + &": {
				marginTop: theme.spacing(3),
			},
		},
		input: {
			borderRadius: 4,
			position: "relative",
			backgroundColor: theme.palette.background.paper,
			border: `1px solid ${theme.palette.primary.light}`,
			fontSize: 14,
			padding: "9px 26px 9px 16px",
			transition: theme.transitions.create(["border-color", "box-shadow"]),
			fontFamily: ["Helvetica Neue", "Arial"].join(","),
			"&:focus": {
				borderRadius: 4,
				borderColor: theme.palette.gray.main,
				boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
			},
		},
	}))(inputComponent);

export default OutlinedInput;
