import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useInput, useTranslate } from "react-admin";

const useStyles = makeStyles(theme => ({
	hint: {
		fontSize: 11,
		color: theme.palette.gray.main,
	},
	labelContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
	},
}));

const FormCheckbox = properties => {
	const {
		input: { name, onChange, value },
	} = useInput(properties);
	const translate = useTranslate();
	const classes = useStyles();

	return (
		<FormControlLabel
			style={{ width: properties.fullWidth ? "100%" : null, marginBottom: 10 }}
			className={properties.className}
			control={
				<Checkbox
					checked={value}
					name={name}
					disabled={properties.disabled}
					color="primary"
					onChange={onChange}
				/>
			}
			label={
				<div className={classes.labelContainer}>
					<Typography variant="body1">{translate(properties.label)}</Typography>
					{Boolean(properties.hint) && (
						<span className={classes.hint}>{translate(properties.hint)}</span>
					)}
				</div>
			}
		/>
	);
};

export default FormCheckbox;
