import React from "react";

import { formatIsoDate } from "../../common/dateUtils";

const DateField = ({ record, source }) => {
	if (record && record[source]) {
		return <span>{formatIsoDate(record[source])}</span>;
	}
	return <span />;
};

export default DateField;
