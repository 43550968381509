import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Loading, useNotify, useRefresh, useShowController, useTranslate } from "react-admin";

import controlFeedback from "../assets/controlFeedback.json";
import { formatIsoDate } from "../common/dateUtils";
import IndicatorCard from "../components/IndicatorCard";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";
import customProvider from "../providers/customProvider";

import DocumentImageList from "./DocumentImageList";

const useStyles = makeStyles(theme => ({
	cardContainer: {
		flex: 1,
		margin: 10,
		padding: "14px 42px 24px 42px",
		display: "flex",
		flexDirection: "column",
	},
	mainContainer: {
		flex: 1,
		margin: 10,
		padding: "14px 42px 24px 42px",
		minHeight: 600,
	},
	statusCard: {
		width: 160,
		height: 45,
	},
	emailText: {
		...theme.typography.subtitle2,
		fontStyle: "italic",
	},
}));

// refactoring: put card into reusable component
const statusColors = s => {
	switch (s) {
		case -1:
		case 6: {
			return "primary";
		}
		case 1:
		case 4:
		case 5: {
			return "yellow";
		}
		case 2: {
			return "green";
		}
		case 3:
		case 0: {
			return "red";
		}
		default: {
			return "gray";
		}
	}
};

const PhotoControlShow = properties => {
	const refresh = useRefresh();
	const notify = useNotify();
	const classes = useStyles();
	const translate = useTranslate();
	const [comments, setComments] = useState([]);
	const [rejections, setRejections] = useState([]);
	const [status, setStatus] = useState(1);
	const [loading, setLoading] = useState(false);

	const { loaded, record, resource } = useShowController(properties);

	useEffect(() => {
		if (record) {
			setStatus(record.status);
			if (record.comment) {
				setComments(JSON.parse(record.comment));
			}
			if (record.rejectionReasons) {
				setRejections(JSON.parse(record.rejectionReasons));
			}
		}
	}, [record]);

	if (!loaded || !record || loading) {
		return <Loading />;
	}

	const getFsStatusText = s => {
		switch (s) {
			case 1:
			case 4:
			case 5: {
				return translate("drivers.tableStates.pending");
			}
			case 2: {
				return translate("drivers.tableStates.success");
			}
			case 3:
			case 0: {
				return translate("drivers.tableStates.fail");
			}
			case 7: {
				return translate("drivers.tableStates.other");
			}
			//
			// case 6:
			default: {
				return translate("drivers.tableStates.created");
			}
		}
	};

	const handleRejectionChange = id => () => {
		if (rejections.includes(id)) {
			setRejections(rejections.filter(r => r !== id));
		} else {
			setRejections([...rejections, id]);
		}
	};

	const handleCommentChange = id => () => {
		if (comments.includes(id)) {
			setComments(comments.filter(r => r !== id));
		} else {
			setComments([...comments, id]);
		}
	};

	const handleAccept = async () => {
		try {
			setLoading(true);
			await customProvider.customAction(resource, {
				id: record.id,
				action: "update-status",
				status: 2,
				comment: comments,
			});
			setStatus(2);
			notify("common.success");
		} catch {
			notify("common.fail");
		} finally {
			setLoading(false);
			refresh();
		}
	};

	const handleReject = async () => {
		try {
			setLoading(true);
			await customProvider.customAction(resource, {
				id: record.id,
				action: "update-status",
				status: 0,
				rejections,
			});
			notify("common.success");
			setStatus(0);
		} catch {
			notify("common.fail");
		} finally {
			setLoading(false);
			refresh();
		}
	};

	const renderComments = (disabled = false) => (
		<Box display="flex" flexDirection="column" style={{ overflowY: "scroll" }}>
			{controlFeedback.commentCheckboxes.map(({ id, text }) => (
				<FormControlLabel
					key={id}
					label={text}
					control={
						<Checkbox
							disabled={disabled}
							checked={comments.includes(id)}
							name="checkedB"
							color="primary"
							onChange={handleCommentChange(id)}
						/>
					}
				/>
			))}
		</Box>
	);

	const renderRejectionReasons = (disabled = false) => (
		<Box display="flex" flexDirection="column" style={{ overflowY: "scroll" }}>
			{controlFeedback.rejectionCheckboxes.map(({ id, text }) => (
				<FormControlLabel
					key={id}
					label={text}
					control={
						<Checkbox
							disabled={disabled}
							checked={rejections.includes(id)}
							name="checkedB"
							color="primary"
							onChange={handleRejectionChange(id)}
						/>
					}
				/>
			))}
		</Box>
	);

	return (
		<div>
			<TitleBreadcrumbs
				path={[{ label: translate("photoControls.label"), to: `/${resource}` }]}
				title={
					record.Driver
						? `${record.Driver.firstName} ${record.Driver.surName}`
						: translate("photoControls.noDriver")
				}
			/>
			<Box display="flex">
				<Paper className={classes.cardContainer}>
					<Box mb={2}>
						<Typography variant="subtitle1" className={classes.subTitle}>
							{translate("drivers.sections.contactInfo")}
						</Typography>
						<Typography variant="h6">
							{`${record.Driver.firstName} ${record.Driver.surName}`}
						</Typography>
						<Typography variant="body1">
							{translate(`drivers.contactType.${record.Driver.contactType}`)}
						</Typography>
						<Typography className={classes.emailText}>{record.Driver.email}</Typography>
						<Typography className={classes.emailText}>
							{record.Driver.phoneNumber}
						</Typography>

						{record.Driver.driverTypeEnum !== null && (
							<Typography variant="body1">
								{translate(`drivers.driverTypes.${record.Driver.driverTypeEnum}`)}
							</Typography>
						)}

						{record.Driver.isPkw && (
							<Typography variant="body1">
								{translate("drivers.fields.isPkw")}
							</Typography>
						)}

						{record.Driver.isLkw && (
							<Typography variant="body1">
								{translate("drivers.fields.isLkw")}
							</Typography>
						)}

						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Box mr={3}>
								<Typography variant="subtitle2">
									{translate("photoControls.status")}
								</Typography>
								<IndicatorCard
									classes={{ container: classes.statusCard }}
									color={statusColors(record.status)}
								>
									<Box flex={1} pl={2} py={1}>
										<Typography variant="h6">
											{getFsStatusText(record.status)}
										</Typography>
									</Box>
								</IndicatorCard>
							</Box>
						</Box>
					</Box>

					<Box maxWidth={500}>
						<Typography variant="subtitle1">
							{translate("photoControls.quiz")}
						</Typography>
						<Typography variant="h4">
							{translate(`photoControls.tasks.${record.quizNumber}`)}
						</Typography>
					</Box>

					{record && record.Documents && (
						<DocumentImageList documents={record.Documents} />
					)}
				</Paper>

				<Paper className={classes.mainContainer}>
					<Box display="flex" flexDirection="column" height="100%">
						<Box
							display="flex"
							flexDirection="column"
							mt={2}
							justifyContent="space-between"
						>
							{status === 1 ? (
								<>
									{/* The content of first step */}
									<Box display="flex" flexDirection="column">
										<Typography variant="subtitle2">
											{translate("photoControls.date")}
										</Typography>
										<Typography>{formatIsoDate(record.createdAt)}</Typography>
										<Typography variant="subtitle1">
											{translate("photoControls.commentHeading")}
										</Typography>
										{renderComments(status !== 1)}
									</Box>

									{/* The content of second step */}
									<Box display="flex" flexDirection="column">
										<Typography variant="subtitle1">
											{translate("photoControls.rejectionHeading")}
										</Typography>
										{renderRejectionReasons(status !== 1)}
									</Box>
								</>
							) : (
								<>
									{/* The content of third step */}
									<Box display="flex" flexDirection="column">
										{status === 2 ? (
											<>
												<Typography variant="subtitle1">
													{translate("photoControls.success")}
												</Typography>
												{renderComments(true)}
											</>
										) : (
											<>
												<Typography variant="subtitle1">
													{translate("photoControls.fail")}
												</Typography>
												{renderRejectionReasons(true)}
											</>
										)}
									</Box>
								</>
							)}

							{record &&
								record.status !== 0 &&
								record.status !== 2 &&
								record.status !== 3 && (
									<Box display="flex" justifyContent="space-around">
										<Button
											color="primary"
											disabled={
												rejections.length > 0 ||
												comments.length <
													controlFeedback.commentCheckboxes.length
											}
											onClick={handleAccept}
										>
											{translate("photoControls.acceptButton")}
										</Button>
										<Button
											style={{ marginLeft: 10 }}
											disabled={rejections.length === 0}
											onClick={handleReject}
										>
											{translate("photoControls.rejectButton")}
										</Button>
									</Box>
								)}
						</Box>
					</Box>
				</Paper>
			</Box>
		</div>
	);
};

export default PhotoControlShow;
