const initialState = {
	loading: true,
	dashboard: {},
	monitoring: {},
};

export const fetchDashboard = tenantIds => ({
	type: "FETCH_DASHBOARD",
	payload: { tenantIds },
});

export const setDashboardData = data => ({
	type: "SET_DASHBOARD_DATA",
	payload: data,
});

export const fetchMonitoring = (startDate, endDate) => ({
	type: "FETCH_MONITORING",
	payload: {
		startDate,
		endDate,
	},
});

export const setMonitoringData = data => ({
	type: "SET_MONITORING_DATA",
	payload: data,
});

export default (previousState = initialState, { type, payload }) => {
	if (type === "FETCH_DASHBOARD") {
		return {
			...previousState,
			loading: true,
		};
	}
	if (type === "SET_DASHBOARD_DATA") {
		return {
			...previousState,
			loading: false,
			dashboard: payload,
		};
	}
	if (type === "FETCH_MONITORING") {
		return {
			...previousState,
			loading: true,
		};
	}
	if (type === "SET_MONITORING_DATA") {
		return {
			...previousState,
			loading: false,
			monitoring: payload,
		};
	}
	return previousState;
};
