import { parse } from "query-string";
import React from "react";
import { Create, FormWithRedirect, SaveButton, usePermissions, useTranslate } from "react-admin";

import PermissionUtils from "../common/permissionUtils";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

import TenantForm from "./TenantForm";

const TenantCreateToolbar = properties => {
	const { invalid, pristine, saving, handleSubmitWithRedirect } = properties;
	return (
		<div className={{ backgroundColor: "inherit" }}>
			<SaveButton
				variant="contained"
				saving={saving}
				handleSubmitWithRedirect={handleSubmitWithRedirect}
				label="tenants.actions.save"
				redirect="edit"
				submitOnEnter={false}
				disabled={invalid || pristine}
			/>
		</div>
	);
};

const TenantCreate = properties => {
	const translate = useTranslate();
	const permissions = new PermissionUtils(usePermissions());

	const { superTenantId, groupId } = parse(properties.history.location.search);

	return (
		<div style={{ maxWidth: 600 }}>
			<TitleBreadcrumbs
				path={[{ label: translate("pages.admin.title"), to: "tenants" }]}
				title={translate("tenants.actions.create")}
			/>
			<Create {...properties}>
				<FormWithRedirect
					initialValues={{
						active: true,
						type1UVV: true,
						includeUVVLKW: false,
						isPkw: true,
						fk_superTenantId: superTenantId || permissions.getSuperTenantId(),
						fk_groupId: groupId || permissions.getGroupId(),
						useReminder: true,
						useTriggerEmail: true,
						useInterval: true,
					}}
					render={formProperties => (
						<TenantForm
							formProps={formProperties}
							toolbar={<TenantCreateToolbar {...formProperties} />}
						/>
					)}
				/>
			</Create>
		</div>
	);
};

export default TenantCreate;
