import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { Notification, useLogin, useNotify, useTranslate } from "react-admin";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import package_ from "../../package.json";
import Icon from "../assets/img/edding-logo.svg";
import PasswordTextField from "../components/PasswordTextField";
import {
	setPasswordAction,
	setTwoFactorAuthCode,
	setTwoFaEnabled,
	setUsernameAction,
} from "../redux/authReducer";
import { fetchSettings } from "../redux/settingsReducer";

const useStyles = makeStyles(() => ({
	logoImage: {
		objectFit: "scale-down",
		width: 445,
		height: 128,
	},
	titleContainer: {
		paddingBottom: 38,
		borderBottom: properties => `1px solid ${properties.secondaryColor}`,
	},
}));

const MyLoginPage = ({
	theme,
	username,
	password,
	onUsernameChange,
	onPasswordChange,
	history,
	twoFactorAuthCode,
	onTwoFactorAuthCodeChange,
	twoFaEnabled,
	onTwoFaEnabledChange,
	fetchUserSettings,
}) => {
	const classes = useStyles({ secondaryColor: theme.palette.secondary.main });
	const login = useLogin();
	const translate = useTranslate();
	const notify = useNotify();

	const submit = e => {
		e.preventDefault();
		login({ username, password, twoFactorAuthCode })
			.then(data => {
				onPasswordChange("");
				onUsernameChange("");
				if (data.require2FA) {
					notify(translate("ra.auth.require_2fa"));
					history.push("/settings");
				}
				fetchUserSettings();
				onTwoFaEnabledChange(false);
			})
			.catch(error => {
				if (error.body && error.body.twoFactorEnabled) {
					onTwoFaEnabledChange(true);
					if (twoFactorAuthCode) {
						notify(translate("ra.auth.invalid_2fa"));
					}
				} else if (
					error.body &&
					error.body.message === "User not assigned to any department"
				) {
					onTwoFaEnabledChange(false);
					notify(translate("ra.auth.not_assigned_any_department"));
				} else {
					onTwoFaEnabledChange(false);
					notify(translate("ra.auth.sign_in_error"));
				}
			});
	};

	const handlePasswordResetClick = () => {
		history.push("/auth/reset");
	};

	return (
		<ThemeProvider theme={theme}>
			<Container fixed>
				<Box
					height="90vh"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
				>
					<Box
						height="80%"
						maxHeight={700}
						display="flex"
						flexDirection="column"
						justifyContent="space-around"
						alignItems="center"
					>
						<img className={classes.logoImage} src={Icon} alt="company_logo" />

						<Box className={classes.titleContainer}>
							<Typography variant="h1">{translate("pages.login.title")}</Typography>
							<Typography variant="h2">
								{translate("pages.login.subtitle")}
							</Typography>
						</Box>
						<form data-testid="login-form" onSubmit={submit}>
							<Box
								maxWidth={520}
								width="50vw"
								height={350}
								display="flex"
								flexDirection="column"
								flexWrap="wrap"
								justifyContent="start"
							>
								<Box my={1}>
									<Typography variant="subtitle1">Login</Typography>
								</Box>
								<Box my={2}>
									<TextField
										fullWidth
										label={translate("ra.auth.username")}
										name="username"
										type="username"
										value={username}
										variant="outlined"
										InputProps={{
											inputProps: { "data-testid": "username-field" },
										}}
										onChange={e => onUsernameChange(e.target.value)}
									/>
								</Box>
								<Box mb={2}>
									<PasswordTextField
										fullWidth
										label={translate("ra.auth.password")}
										name="password"
										value={password}
										variant="outlined"
										InputProps={{
											inputProps: { "data-testid": "password-field" },
										}}
										onChange={e => onPasswordChange(e.target.value)}
									/>
								</Box>

								{twoFaEnabled && (
									<Box mb={2}>
										<TextField
											fullWidth
											name="twoFactorAuthCode"
											value={twoFactorAuthCode}
											variant="outlined"
											label={translate(
												"pages.settings.twoFa.twoFactorAuthCode"
											)}
											onChange={e =>
												onTwoFactorAuthCodeChange(e.target.value)
											}
										/>
									</Box>
								)}

								<Box display="flex" flexDirection="row">
									<Button
										variant="contained"
										color="primary"
										type="submit"
										data-testid="submit-button"
										style={{ width: 140 }}
										disabled={!username || !password}
									>
										{translate("ra.auth.sign_in")}
									</Button>
									<Button
										color="primary"
										style={{ width: 180 }}
										onClick={handlePasswordResetClick}
									>
										{translate("pages.login.passwordForget")}
									</Button>
								</Box>
							</Box>
						</form>
					</Box>
				</Box>

				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						<Link to="legal/cookies">Cookieeinstellungen</Link>{" "}
						<a
							target="_blank"
							href="https://www.easycheck-by-edding.com/impressum/"
							rel="noreferrer"
						>
							Impressum
						</a>{" "}
						<a
							target="_blank"
							href="https://www.easycheck-by-edding.com/datenschutzerklaerung/"
							rel="noreferrer"
						>
							Datenschutzerklärung
						</a>
					</div>
					<div>{`v${package_.version}`}</div>
				</div>
			</Container>

			<Notification />
		</ThemeProvider>
	);
};

const mapStateToProperties = state => ({
	username: state.auth.username,
	password: state.auth.password,
	twoFactorAuthCode: state.auth.twoFactorAuthCode,
	twoFaEnabled: state.auth.twoFaEnabled,
});

const mapDispatchToProperties = dispatch => ({
	onUsernameChange: username => {
		dispatch(setUsernameAction(username));
	},
	onPasswordChange: password => {
		dispatch(setPasswordAction(password));
	},
	onTwoFactorAuthCodeChange: twoFaCode => {
		dispatch(setTwoFactorAuthCode(twoFaCode));
	},
	onTwoFaEnabledChange: twoFaEnabled => {
		dispatch(setTwoFaEnabled(twoFaEnabled));
	},
	fetchUserSettings: () => {
		dispatch(fetchSettings());
	},
});

export default connect(mapStateToProperties, mapDispatchToProperties)(MyLoginPage);
