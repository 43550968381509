import { Typography } from "@material-ui/core";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import IconButton from '@material-ui/core/IconButton';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveIcon from "@material-ui/icons/Save";
import { parseISO } from "date-fns";
import React, { useState } from "react";
import { usePermissions, useTranslate } from "react-admin";

import { ReactComponent as CommentIcon } from "../assets/icons/comment.svg";
import { ReactComponent as FSIcon } from "../assets/icons/events/fs_done.svg";
import { ReactComponent as FSNextIcon } from "../assets/icons/events/fs_next.svg";
import { ReactComponent as FSRequestedIcon } from "../assets/icons/events/fs_request.svg";
import { ReactComponent as MailIcon } from "../assets/icons/events/mail.svg";
import { ReactComponent as NewDriverIcon } from "../assets/icons/events/new_driver.svg";
import { ReactComponent as PhotoControlIcon } from "../assets/icons/events/photo_control.svg";
import { ReactComponent as UVVIcon } from "../assets/icons/events/uvv.svg";
import { formatIsoDate, formatIsoTime } from "../common/dateUtils";
import PermissionUtils from "../common/permissionUtils";
import IndicatorCard from "../components/IndicatorCard";

const getEventIcon = eventType => {
	if (eventType > 100) {
		return <MailIcon style={{ fontSize: "0.5rem" }} />;
	}
	switch (eventType) {
		case 1: {
			return <NewDriverIcon />;
		}
		case 3:
		case 6:
		case 7: {
			return <FSRequestedIcon />;
		}
		case 2:
		case 8:
		case 9: {
			return <FSIcon />;
		}
		case 13:
		case 14:
		case 15:
		case 16: {
			return <UVVIcon />;
		}
		case 30:
		case 31:
		case 4:
		case 5:
		case 11:
		case 32:
		case 12:
		case 10:
		case 81: {
			return <PhotoControlIcon />;
		}
		default: {
			return <FSNextIcon />;
		}
	}
};

/**
 CREATED: -1,
 CONTROL_FAILED: 0,
 CONTROL_PENDING: 1,
 CONTROL_SUCCESS: 2,
 CONTROL_FAILED_FIRST: 3,
 INIT_PENDING: 4,
 INIT_FAILED_FIRST: 5
 */

const getEventColor = (eventType, status, theme) => {
	switch (status) {
		case -1:
		case 4: {
			return theme.palette.primary.main;
		}
		case 0: {
			return theme.palette.red.main;
		}
		case 1:
		case 3:
		case 5: {
			return theme.palette.yellow.main;
		}
		case 2: {
			return theme.palette.green.main;
		}
		default: {
			return theme.palette.text.disabled;
		}
	}
};

const useEventStyles = makeStyles(theme => ({
	statusIndicator: {
		backgroundColor: ({ eventType, status }) => getEventColor(eventType, status, theme),
	},
	container: {
		flex: 2,
	},
	iconWrapper: {
		zIndex: 2,
		padding: "10px 0 10px 0",
		background: "white",
		alignContent: "center",
	},
	line: {
		position: "absolute",
		top: -50,
		left: 104.5,
		borderLeft: `1px solid ${theme.palette.primary.light}`,
		marginBottom: 25,
		height: 80,
		zIndex: 0,
	},
	changesAccordion: {
		maxWidth: "95%",
		boxShadow: "unset",
		border: "none",
		borderRadius: 5,
		"&::before": {
			display: "none",
		},
		"& .MuiIconButton-root": {
			padding: "0 0 0 2px",
		},
	},
	accordionDetails: {
		padding: 0,
		width: "100%",
	},
	accordionSummaryContent: {
		margin: "0 !important",
	},
	accordionSummaryRoot: {
		padding: 0,
		minHeight: "unset !important",
	},
	listRoot: {
		width: "100%",
	},
}));

const Event = ({
	id,
	createdAt,
	eventType,
	status,
	metadataAttributes = [],
	comment,
	updatedAt,
	User,
	onSave,
	text,
}) => {
	const translate = useTranslate();
	const classes = useEventStyles({ eventType, status });
	const permissions = new PermissionUtils(usePermissions());

	const [commentEdit, setCommentEdit] = useState(comment || "");
	const [showEdit, setShowEdit] = useState(false);

	const getEventTitle = () => {
		if (eventType >= 300) {
			return (
				translate(`tenants.uvvs.${metadataAttributes[0]}`) +
				translate(`templates.types.${eventType}`)
			);
		}
		if (eventType > 100) {
			return translate(`templates.types.${eventType}`);
		}
		switch (eventType) {
			case 1: {
				return translate("drivers.events.newDriver");
			}
			case 2: {
				return translate("drivers.events.fsManual");
			}
			case 3: {
				return translate("drivers.events.fsPending");
			}
			case 4: {
				return translate("drivers.events.fsRequested");
			}
			case 5: {
				return translate("drivers.events.fsDone");
			}
			case 6: {
				return translate("drivers.events.fsOverdue");
			}
			case 7: {
				return translate("drivers.events.fsAdhoc");
			}
			case 8: {
				return translate("drivers.events.fsFail");
			}
			case 9: {
				return translate("drivers.events.fsFailFirst");
			}
			case 10: {
				return translate("drivers.events.initRequested");
			}
			case 11: {
				return translate("drivers.events.initFailed");
			}
			case 12: {
				return translate("drivers.events.initSuccess");
			}
			case 13: {
				return translate("drivers.events.uvvPending");
			}
			case 14: {
				return translate("drivers.events.uvvRequested");
			}
			case 15: {
				return translate("drivers.events.uvvDone");
			}
			case 16: {
				return translate("drivers.events.uvvOverdue");
			}
			case 17: {
				return translate("drivers.events.uvvFail");
			}
			case 18: {
				return translate("drivers.events.fsCancel");
			}
			case 19: {
				return translate("drivers.events.eventScheduled");
			}
			case 20: {
				return translate("drivers.events.fsManualSa");
			}
			case 21: {
				return translate("drivers.events.activated");
			}
			case 22: {
				return translate("drivers.events.deactivated");
			}
			case 23: {
				return translate("drivers.events.uvvLkwPending");
			}
			case 24: {
				return translate("drivers.events.uvvLkwRequested");
			}
			case 25: {
				return translate("drivers.events.uvvLkwDone");
			}
			case 26: {
				return translate("drivers.events.uvvLkwOverdue");
			}
			case 27: {
				return translate("drivers.events.uvvLkwFail");
			}
			case 30: {
				return translate("drivers.events.photoControlRequest");
			}
			case 31: {
				return translate("drivers.events.photoControlFail");
			}
			case 32: {
				return translate("drivers.events.photoControlSuccess");
			}
			case 34: {
				return translate("drivers.events.driverWelcome");
			}
			case 47: {
				return translate("drivers.events.driverUpdate");
			}
			case 81: {
				return translate("drivers.events.initStarted");
			}
			default: {
				return translate("drivers.events.next");
			}
		}
	};

	const handleCommentSave = () => {
		setShowEdit(false);
		onSave(id, commentEdit);
	};

	const renderText = () => {
		if (text && eventType === 47) {
			const parsedText = JSON.parse(text);

			return (
				<Accordion classes={{ root: classes.changesAccordion }}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						classes={{
							content: classes.accordionSummaryContent,
							root: classes.accordionSummaryRoot,
						}}
					>
						<Typography variant="body2">
							{translate("drivers.events.diff", { user: parsedText.userName })}
						</Typography>
					</AccordionSummary>
					<AccordionDetails classes={{ root: classes.accordionDetails }}>
						<List classes={{ root: classes.listRoot }}>
							{Object.entries(parsedText.diff).map(([key, value]) => (
								<ListItem key={key}>
									<Box display="flex" flexDirection="column" width="100%">
										<ListItemText
											primary={`${translate(`drivers.fields.${key}`, key)}: `}
										/>
										<Box
											display="flex"
											flexDirection="row"
											justifyContent="space-between"
											flexWrap="wrap"
										>
											<Box>
												<Typography variant="body1">
													{value.oldValue}
												</Typography>
											</Box>
											<Box
												item
												display="flex"
												flexDirection="row"
												justifyContent="center"
											>
												<ArrowForwardIcon fontSize="small" />
											</Box>
											<Box>
												<Typography variant="body1">
													{value.newValue}
												</Typography>
											</Box>
										</Box>
									</Box>
								</ListItem>
							))}
						</List>
					</AccordionDetails>
				</Accordion>
			);
		}

		return null;
	};

	return (
		<Box display="flex" flexDirection="row" alignItems="center" my={2}>
			<Box position="relative" display="flex" flexDirection="column" alignItems="flex-end">
				<Typography variant="h6">{formatIsoDate(createdAt)}</Typography>
				<Typography variant="subtitle2">{formatIsoTime(createdAt)}</Typography>
				<div className={classes.line} />
			</Box>

			<Box mx={3} display="flex" justifyContent="center" className={classes.iconWrapper}>
				<SvgIcon>{getEventIcon(eventType)}</SvgIcon>
			</Box>

			<IndicatorCard
				classes={{ container: classes.container, indicator: classes.statusIndicator }}
			>
				<Box flex={2} mx={2} display="flex" flexDirection="column" my={1}>
					<Box display="flex" flexDirection="column">
						<Box display="flex" flexDirection="row" justifyContent="space-between">
							<Typography variant="subtitle1">
								{getEventTitle(eventType, status)}
							</Typography>
							{permissions.isSuperAdmin() && (
								<IconButton
									style={{ padding: 0 }}
									onClick={() => setShowEdit(!showEdit)}
								>
									<CommentIcon />
								</IconButton>
							)}
						</Box>
						{renderText()}
					</Box>

					{permissions.isSuperAdmin() && comment && !showEdit && (
						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Typography variant="subtitle2">{comment}</Typography>
							<Box display="flex" flexDirection="column">
								<Typography variant="subtitle2">
									{formatIsoDate(updatedAt)}
								</Typography>
								{User && (
									<Typography variant="subtitle2">
										{`${User.firstName} ${User.surName}`}
									</Typography>
								)}
							</Box>
						</Box>
					)}
					{showEdit && (
						<Box display="flex" flexDirection="row" justifyContent="space-between">
							<TextField
								fullWidth
								name="comment"
								label={translate("drivers.events.comment")}
								value={commentEdit}
								variant="outlined"
								onChange={event => setCommentEdit(event.target.value)}
							/>
							<IconButton style={{ padding: 0 }} onClick={handleCommentSave}>
								<SaveIcon />
							</IconButton>
						</Box>
					)}
				</Box>
			</IndicatorCard>
		</Box>
	);
};

const DriverEvents = ({ events, onSave }) => {
	const displayEvents = [...events].filter(event => !event.text || event.text !== "retry");
	displayEvents.sort((a, b) => parseISO(b.createdAt) - parseISO(a.createdAt));
	return (
		<Box display="flex" flexDirection="column" px={4} py={4}>
			{displayEvents.map(event => (
				<Event key={event.id} {...event} onSave={onSave} />
			))}
		</Box>
	);
};

export default DriverEvents;
