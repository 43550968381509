import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { useTranslate } from "react-admin";

import AdminHeader from "./AdminHeader";

const useStyles = makeStyles(() => ({
	card: {
		padding: "22px 44px",
		width: "max-content",
	},
}));

const FeaturesPage = ({ location }) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<Box width="100%">
			<AdminHeader active={location.pathname} />

			<Box maxWidth="558" display="flex" flexDirection="column">
				<Paper className={classes.card}>
					<Typography variant="subtitle1">
						{translate("pages.adminFeatures.batchCheck")}
					</Typography>
					<Box mt={1}>
						<Button color="primary" variant="contained">
							{translate("pages.adminFeatures.batchCheckButton")}
						</Button>
					</Box>
				</Paper>
			</Box>
		</Box>
	);
};

export default FeaturesPage;
