import { usePermissions } from "react-admin";

/**
 * @returns {boolean}
 */
// eslint-disable-next-line import/prefer-default-export
export function useIsMigratedMATM() {
	const { permissions } = usePermissions();
	return typeof permissions?.isSuperAdmin === "boolean";
}

/**
 *
 * @param {boolean} globalView
 * @returns {boolean}
 */
// eslint-disable-next-line import/prefer-default-export
export function useGlobalViewV2(globalView) {
	const isMigrated = useIsMigratedMATM();
	return isMigrated && globalView;
}
