import { createStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import React from "react";

const LargeSwitch = withStyles(theme =>
	createStyles({
		root: {
			width: 42,
			height: 26,
			padding: 0,
			marginRight: 5,
			overflow: "inherit",
		},
		switchBase: {
			padding: 1,
			"&$checked": {
				transform: "translateX(16px)",
				color: theme.palette.common.white,
				"& + $track": {
					backgroundColor: theme.palette.primary.main,
					opacity: 1,
					border: "none",
				},
			},
			"&$focusVisible $thumb": {
				color: theme.palette.primary.light,
				border: "6px solid #fff",
			},
		},
		thumb: {
			width: 24,
			height: 24,
		},
		track: {
			borderRadius: 26 / 2,
			border: `1px solid ${theme.palette.grey[400]}`,
			backgroundColor: theme.palette.grey[50],
			opacity: 1,
			transition: theme.transitions.create(["background-color", "border"]),
		},
		checked: {},
		focusVisible: {},
	})
)(({ classes, ...properties }) => (
	<Switch
		disableRipple
		focusVisibleClassName={classes.focusVisible}
		inputProps={{ checked: properties.checked }}
		classes={{
			root: classes.root,
			switchBase: classes.switchBase,
			thumb: classes.thumb,
			track: classes.track,
			checked: classes.checked,
		}}
		{...properties}
	/>
));

export default LargeSwitch;
