import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { usePermissions, useTranslate } from "react-admin";
import { connect } from "react-redux";

import { formatDateAsIso } from "../common/dateUtils";
import PermissionUtils from "../common/permissionUtils";
import DateField from "../components/DateField";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";
import { fetchMonitoring } from "../redux/statsReducer";

import DailyCounterChart from "./charts/DailyCounterChart";
import DriverByStatusChart from "./charts/DriverByStatusChart";
import DriverByTypeChart from "./charts/DriverByTypeChart";
import DriverByUvvChart from "./charts/DriverByUvvChart";

const useStyles = makeStyles(theme => ({
	card: {
		padding: "26px 32px",
		width: 450,
		margin: "12px 0",
	},
	picker: {
		width: "48%",
	},
	hint: {
		color: theme.palette.text.secondary,
	},
}));

const MonitoringPage = ({ fetch, data, loading }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const permissions = new PermissionUtils(usePermissions());

	const [state, setState] = useState({ startDate: null, endDate: null });

	useEffect(() => {
		if (state.startDate) {
			fetch(state);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const handleDateChange = name => date => {
		setState({
			...state,
			[name]: date ? formatDateAsIso(date) : null,
		});
	};

	return (
		<Box width="100%">
			<TitleBreadcrumbs title={translate("pages.monitoring.title")} />

			<Paper className={classes.card}>
				<Typography variant="subtitle1">{translate("pages.monitoring.range")}</Typography>

				<Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
					<DateField
						disableFuture
						className={classes.picker}
						label={translate("common.startDate")}
						value={state.startDate}
						maxDate={state.endDate}
						onChange={handleDateChange("startDate")}
					/>
					<DateField
						disableFuture
						clearable
						className={classes.picker}
						label={translate("common.endDate")}
						value={state.endDate}
						minDate={state.startDate}
						onChange={handleDateChange("endDate")}
					/>
				</Box>
			</Paper>

			{!state.startDate && (
				<Box>
					<Typography className={classes.hint} variant="h4">
						{translate("pages.monitoring.hint")}
					</Typography>
				</Box>
			)}

			{!loading && !isEmpty(data.monitoring) && (
				<Box
					display="flex"
					flexDirection="row"
					flexWrap="wrap"
					justifyContent="space-between"
				>
					<Paper className={classes.card}>
						<Typography variant="h4">
							{translate("pages.monitoring.driversByStatus.title")}
						</Typography>
						<DriverByStatusChart monitoring={data.monitoring} />
					</Paper>
					<Paper className={classes.card}>
						<Typography variant="h4">
							{translate("pages.monitoring.driversByType.title")}
						</Typography>
						<DriverByTypeChart monitoring={data.monitoring} />
					</Paper>
					{data.dailyCounters.length > 0 && (
						<Paper className={classes.card}>
							<Typography variant="h4">
								{translate("pages.monitoring.dailyCounters.title")}
							</Typography>
							<DailyCounterChart dailyCounters={data.dailyCounters} />
						</Paper>
					)}
					{permissions.isIncludeUvv() && (
						<Paper className={classes.card}>
							<Typography variant="h4">
								{translate("pages.monitoring.driversByUvv.title")}
							</Typography>
							<DriverByUvvChart monitoring={data.monitoring} />
						</Paper>
					)}
				</Box>
			)}
		</Box>
	);
};

const mapStateToProperties = state => ({
	data: state.stats.monitoring,
	loading: state.stats.loading,
});

const mapDispatchToProperties = dispatch => ({
	fetch: ({ startDate, endDate }) => {
		dispatch(fetchMonitoring(startDate, endDate));
	},
});

export default connect(mapStateToProperties, mapDispatchToProperties)(MonitoringPage);
