/* eslint-disable react/forbid-prop-types */
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

const useMenuStyles = makeStyles(() => ({
	paper: {
		minWidth: properties => properties.width,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
	},
	list: {
		minWidth: properties => properties.width,
		padding: "unset",
	},
}));

const useStyles = makeStyles(theme => ({
	listItem: {
		height: 52,
		borderTop: `1px solid ${theme.palette.gray.light}`,
	},
	listIcon: {
		minWidth: 20,
		marginRight: 23,
	},
	itemText: {
		fontWeight: 600,
	},
}));

const PopoverMenu = ({ anchorEl, width, menuOptions, onClose }) => {
	const menuStyles = useMenuStyles({ width });
	const classes = useStyles();
	const renderMenuOptions = () =>
		menuOptions
			.filter(o => !o.hidden)
			.map(({ icon, label, listIcon, listItem, role, ...rest }) => (
				<MenuItem {...rest} key={label} className={classes.listItem}>
					<ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
					<ListItemText classes={{ primary: classes.itemText }} primary={label} />
				</MenuItem>
			));

	// rebuild: anchor set on click https://material-ui.com/components/menus/#CustomizedMenus.js
	return (
		<Menu
			keepMounted
			classes={menuStyles}
			getContentAnchorEl={null}
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			style={{ width }}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			onClose={onClose}
		>
			{renderMenuOptions()}
		</Menu>
	);
};

PopoverMenu.propTypes = {
	anchorEl: PropTypes.object,
	width: PropTypes.number,
	menuOptions: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.object,
			label: PropTypes.string,
			onClick: PropTypes.func,
			disabled: PropTypes.bool,
			hidden: PropTypes.bool,
			"data-testid": PropTypes.string,
		})
	),
	onClose: PropTypes.func,
};

PopoverMenu.defaultProps = {
	anchorEl: null,
	width: 150,
	menuOptions: [],
	onClose: () => {},
};

export default PopoverMenu;
