import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { required, TextInput, useTranslate } from "react-admin";

import FormSubtitle from "../components/form/FormSubtitle";

const useStyles = makeStyles(() => ({
	container: {
		padding: "20px 40px 30px 40px",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	halfWidth: {
		flexBasis: "48%",
	},
}));

const BusinessUnitForm = ({ toolbar }) => {
	const classes = useStyles();
	const translate = useTranslate();

	return (
		<form className={classes.container}>
			<Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
				<FormSubtitle>{translate("businessUnits.sections.masterData")}</FormSubtitle>
				<TextInput
					className={classes.halfWidth}
					margin="none"
					variant="outlined"
					source="name"
					label="businessUnits.fields.name"
					validate={required()}
				/>
				<TextInput
					className={classes.halfWidth}
					margin="none"
					variant="outlined"
					source="description"
					label="businessUnits.fields.description"
				/>
				<TextInput
					className={classes.halfWidth}
					margin="none"
					variant="outlined"
					source="address"
					label="businessUnits.fields.address"
					validate={required()}
				/>
				<TextInput
					className={classes.halfWidth}
					margin="none"
					variant="outlined"
					source="city"
					label="businessUnits.fields.city"
					validate={required()}
				/>
				<TextInput
					className={classes.halfWidth}
					margin="none"
					variant="outlined"
					source="country"
					label="businessUnits.fields.country"
					validate={required()}
				/>
			</Box>

			<Box>
				<FormSubtitle>{translate("businessUnits.sections.contactPerson")}</FormSubtitle>
				<TextInput
					fullWidth
					margin="none"
					variant="outlined"
					source="contactPerson"
					label="businessUnits.fields.contactPerson"
					validate={required()}
				/>
			</Box>

			<Box>
				<FormSubtitle>{translate("businessUnits.sections.other")}</FormSubtitle>
				<TextInput
					fullWidth
					margin="none"
					variant="outlined"
					source="comments"
					label="businessUnits.fields.comments"
				/>
			</Box>
			{toolbar}
		</form>
	);
};

export default BusinessUnitForm;
