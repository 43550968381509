import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useState } from "react";
import {
	useAuthProvider,
	useLogout,
	useNotify,
	usePermissions,
	useRefresh,
	useTranslate,
} from "react-admin";
import { connect } from "react-redux";

import { ReactComponent as ExitIcon } from "../assets/icons/exit.svg";
import { ReactComponent as SwitchIcon } from "../assets/icons/switch.svg";
import PermissionUtils from "../common/permissionUtils";
import LargeSwitch from "../components/LargeSwitch";
import PopoverMenu from "../components/PopoverMenu";
import { setSettings, updateSettings } from "../redux/settingsReducer";

import SwitchTenantModal from "./SwitchTenantModal";

const useAppbarStyles = makeStyles(() => ({
	root: {
		backgroundColor: "white",
		boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.1)",
		height: 77,
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
	},
}));

const useStyles = makeStyles(theme => ({
	triggerContainer: {
		width: 200,
		height: 60,
		marginRight: 24,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-around",
		cursor: "pointer",
		borderRadius: 5,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		boxShadow: properties => (properties.menuOpen ? "0px 8px 10px 1px rgba(0,0,0,0.14)" : null),
	},
	menuIndicator: {
		color: theme.palette.gray.main,
	},
}));

const MyAppBar = ({ globalView, resetUserSettings, updateGlobalView, ...properties }) => {
	const translate = useTranslate();
	const logout = useLogout();
	const notify = useNotify();
	const permissions = new PermissionUtils(usePermissions());
	const refresh = useRefresh();
	const authProvider = useAuthProvider();
	const appbarStyles = useAppbarStyles();
	const [anchorElement, setAnchorElement] = useState(null);
	const [swiModalOpen, setSwiModal] = useState(false);
	const classes = useStyles({ menuOpen: Boolean(anchorElement) });

	const handleTenantSwitch = tenantId => {
		authProvider.switchTenant(tenantId).then(() => {
			notify("common.success");
			setTimeout(() => {
				window.location.reload(false);
			}, 200);
		});
	};

	const handleGlobalViewChange = () => {
		updateGlobalView(!globalView);
		refresh();
	};

	const handleLogout = () => {
		logout();
		resetUserSettings();
	};

	return (
		<AppBar {...properties} classes={appbarStyles} data-testid="appbar">
			{(permissions.isSuperAdmin() ||
				permissions.isTenantAdmin() ||
				permissions.isGroupAdmin() ||
				typeof permissions.isSuperAdminV2() === "boolean") &&
				globalView !== null &&
				globalView !== undefined && (
					<Box display="flex" flexDirection="row" alignItems="center">
						<Box mr={1}>
							<Typography variant="h6">
								{translate("pages.appBar.globalView")}
							</Typography>
						</Box>
						<LargeSwitch
							id="global-switch"
							checked={globalView}
							onChange={handleGlobalViewChange}
						/>
					</Box>
				)}
			<Box
				className={classes.triggerContainer}
				data-testid="user-dropdown"
				onClick={e => setAnchorElement(e.currentTarget)}
			>
				<div>
					<Typography variant="subtitle1" id="user-name">
						{permissions.getName()}
					</Typography>
				</div>
				{anchorElement ? (
					<KeyboardArrowUpIcon className={classes.menuIndicator} />
				) : (
					<KeyboardArrowDownIcon className={classes.menuIndicator} />
				)}
			</Box>
			<PopoverMenu
				anchorEl={anchorElement}
				width={200}
				menuOptions={[
					{
						icon: (
							<SvgIcon>
								<SwitchIcon />
							</SvgIcon>
						),
						role: 2,
						label: translate("pages.appBar.switchTenant"),
						onClick: () => setSwiModal(true),
					},
					{
						icon: (
							<SvgIcon>
								<ExitIcon />
							</SvgIcon>
						),
						label: translate("ra.auth.logout"),
						onClick: handleLogout,
						"data-testid": "logout-button",
					},
				].filter(
					o =>
						!o.role ||
						permissions.getRole() >= o.role ||
						permissions.isManagerWithMultipleDepartmentsAccess()
				)}
				onClose={() => setAnchorElement(null)}
			/>

			{permissions &&
				((permissions.isTenantAdmin() && permissions.getSuperTenantId()) ||
					(permissions.isGroupAdmin() && permissions.getGroupId()) ||
					permissions.isSuperAdmin() ||
					permissions.isManagerWithMultipleDepartmentsAccess()) && (
					<SwitchTenantModal
						open={swiModalOpen}
						tenantId={permissions.getTenantId()}
						superTenantId={
							permissions.isSuperAdmin() ? null : permissions.getSuperTenantId()
						}
						onClose={() => setSwiModal(false)}
						onConfirm={handleTenantSwitch}
					/>
				)}
		</AppBar>
	);
};

const mapStateToProperties = state => ({
	globalView: state.settings.globalView,
});

const mapDispatchToProperties = dispatch => ({
	resetUserSettings: () => {
		dispatch(setSettings({}));
	},
	updateGlobalView: globalView => {
		dispatch(updateSettings({ globalView }));
	},
});

export default connect(mapStateToProperties, mapDispatchToProperties)(MyAppBar);
