import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import {
	Button,
	CreateButton,
	Datagrid,
	List,
	NumberField,
	sanitizeListRestProps,
	TextField,
	TopToolbar,
	useListContext,
	useTranslate,
} from "react-admin";
import { useHistory } from "react-router-dom"; // added useHistory

import AdminHeader from "../admin/AdminHeader";
import FormSubtitle from "../components/form/FormSubtitle";
import BoolField from "../components/table/BoolField";
import DateField from "../components/table/DateField";
import MyPagination from "../components/table/Pagination";
import SearchFilter from "../components/table/SearchFilter";
import GroupPanel from "../group/GroupListPanel";

const SuperTenantActions = properties => {
	const { className, exporter, filters, maxResults, ...rest } = properties;

	const { basePath } = useListContext();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			<CreateButton
				variant="contained"
				basePath={basePath}
				label="superTenants.actions.create"
			/>
		</TopToolbar>
	);
};

const TenantActions = properties => {
	const { className, exporter, filters, maxResults, ...rest } = properties;

	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			<CreateButton variant="contained" basePath="tenants" label="tenants.actions.create" />
		</TopToolbar>
	);
};

const useStyles = makeStyles(() => ({
	expandedPanel: {
		"& > *": {
			padding: 0,
		},
	},
}));

const SuperTenantList = properties => {
	const translate = useTranslate();
	const classes = useStyles();
	const history = useHistory(); // use useHistory hook to get the history object

	const { basePath } = properties;
	return (
		<div>
			<AdminHeader active={basePath} />

			<Button
				variant="outlined"
				label={translate("superTenants.actions.allTenants")}
				onClick={() => history.push("/tenants")}
			/>
			<List
				pagination={<MyPagination />}
				filters={<SearchFilter />}
				actions={<SuperTenantActions />}
				bulkActionButtons={false}
				{...properties}
			>
				<Datagrid
					rowClick="edit"
					expand={<GroupPanel />}
					classes={{ expandedPanel: classes.expandedPanel }}
					isRowExpandable={rec =>
						Boolean(
							(rec.Tenants && rec.Tenants.length > 0) ||
								(rec.Groups && rec.Groups.length > 0)
						)
					}
				>
					<TextField source="name" label="superTenants.fields.name" />
					<BoolField source="includeUVV" label="superTenants.fields.includeUVV" />
					<NumberField source="maxTenants" label="superTenants.fields.maxTenants" />
					<TextField
						source="controlIntervalDays"
						label="superTenants.fields.controlIntervalDays"
					/>
					<TextField
						source="createdByUserName"
						label="superTenants.fields.createdByUserName"
					/>
					<DateField source="createdAt" label="superTenants.fields.createdAt" />
				</Datagrid>
			</List>

			<Box paddingY="20px">
				<FormSubtitle>{translate("superTenants.sections.withoutSuperTenant")}</FormSubtitle>
				<List
					basePath="/tenants"
					resource="tenants"
					bulkActionButtons={false}
					filter={{ fk_superTenantId: null }}
					pagination={<MyPagination />}
					filters={<SearchFilter />}
					exporter={false}
					actions={<TenantActions />}
				>
					<Datagrid rowClick="edit">
						<TextField source="name" label="tenants.fields.name" />
						<BoolField source="active" label="tenants.fields.active" />
						<DateField source="createdAt" label="tenants.fields.createdAt" />
					</Datagrid>
				</List>
			</Box>
		</div>
	);
};

export default SuperTenantList;
