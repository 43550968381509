import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.white.main,
		borderRadius: 4,
		borderColor: theme.palette.primary.light,
	},
	inputRoot: {
		padding: "4px !important",
		"& > label": {
			top: "-4px !important",
			fontWeight: 500,
		},
		"& > input": {
			border: "0 !important",
		},
		"& > fieldset": {
			borderColor: theme.palette.primary.light,
		},
	},
}));

const useInputStyles = makeStyles({
	root: {
		border: "0 !important",
		borderColor: "transparent",
		"& > label": {
			top: "-4px !important",
			fontWeight: 500,
		},
	},
});

const AutocompleteFilter = ({
	label,
	resource,
	selected,
	onChange,
	width = 300,
	disabled,
	single,
	transferOptions,
	sortBy = "name",
	identifier = "id",
}) => {
	const classes = useStyles();
	const inputClasses = useInputStyles();
	const dataProvider = useDataProvider();
	const [options, setOptions] = useState([]);
	const [selOptions, setSelOptions] = useState(single ? null : []);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState("");

	const fetchOptions = search => {
		setLoading(true);
		return dataProvider
			.getList(resource, {
				pagination: { page: 1, perPage: 20 },
				filter: !isEmpty(selected) && !search ? { [identifier]: selected } : { q: search },
			})
			.then(res => {
				if (res && res.data) {
					setOptions(res.data);
					return res.data;
				}
				return [];
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		fetchOptions(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!single && selected && (!selOptions || selOptions.length !== selected.length)) {
			setSelOptions(options.filter(o => selected.includes(o[identifier])));
		} else if (single && selOptions[identifier] !== selected) {
			setSelOptions(options.find(o => o[identifier] === selected));
		} else if (!single && (!selected || selected.length === 0) && selOptions.length > 0) {
			setSelOptions([]);
		} else if (single && !selected && selOptions) {
			setSelOptions(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options, selOptions, selected]);

	const handleInputChange = (_event, value) => {
		fetchOptions(value);
		setInputValue(value);
	};

	const handleChange = (_event, value) => {
		setSelOptions(value);
		if (Array.isArray(value)) {
			onChange(value.map(v => v[identifier]));
		} else if (value) {
			onChange(value[identifier]);
		} else {
			onChange(null);
		}
	};

	const handleIsOptionEqual = (option, value) =>
		value && option && option[identifier] === value[identifier];

	const getOptionLabel = option => {
		if (option && transferOptions) {
			return transferOptions(option)[sortBy];
		}
		return option && option[sortBy];
	};

	return (
		<Autocomplete
			includeInputInList
			classes={classes}
			value={selOptions}
			multiple={!single}
			getOptionSelected={handleIsOptionEqual}
			inputValue={inputValue}
			style={{ width }}
			options={transferOptions ? options.map(element => transferOptions(element)) : options}
			getOptionLabel={getOptionLabel}
			loading={loading}
			popupIcon={<KeyboardArrowDownIcon />}
			filterOptions={x => x}
			disabled={disabled}
			renderInput={parameters => (
				<TextField
					{...parameters}
					classes={inputClasses}
					variant="outlined"
					label={label}
				/>
			)}
			onChange={handleChange}
			onInputChange={handleInputChange}
		/>
	);
};

export default AutocompleteFilter;
