import React from "react";
import { Edit, FormWithRedirect, SaveButton, useTranslate } from "react-admin";

import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

import SuperTenantForm from "./SuperTenantForm";

const SuperTenantEditToolbar = properties => {
	const { invalid, pristine, saving, handleSubmitWithRedirect } = properties;
	return (
		<div className={{ backgroundColor: "inherit" }}>
			<SaveButton
				variant="contained"
				saving={saving}
				handleSubmitWithRedirect={handleSubmitWithRedirect}
				label="superTenants.actions.save"
				redirect={false}
				submitOnEnter={false}
				disabled={invalid || pristine}
			/>
		</div>
	);
};

const TenantEdit = properties => {
	const translate = useTranslate();
	return (
		<div>
			<TitleBreadcrumbs
				path={[{ label: translate("pages.admin.title"), to: "/#/super-tenants" }]}
				title={translate("superTenants.actions.edit")}
			/>
			<Edit {...properties}>
				<FormWithRedirect
					render={formProperties => (
						<SuperTenantForm
							formProps={formProperties}
							toolbar={<SuperTenantEditToolbar {...formProperties} />}
						/>
					)}
				/>
			</Edit>
		</div>
	);
};

export default TenantEdit;
