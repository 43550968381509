// export const imageToBase64 = (file) => new Promise((resolve) => {
//   const reader = new FileReader();
//   reader.onload = function () {
//     const base64String = reader.result.replace('data:', '')
//       .replace(/^.+,/, '');
//     resolve(base64String);
//   };
//   reader.readAsDataURL(file);
// });
//
// export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth = 230, maxHeight = 64) => {
//   const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
//   return { width: srcWidth * ratio, height: srcHeight * ratio };
// };

// eslint-disable-next-line import/prefer-default-export
import { formatDateAsIso } from "./dateUtils";

export const resizeImage = (file, maxWidth = 460, maxHeight = 128) => {
	const reader = new FileReader();
	const image = new Image();
	const canvas = document.createElement("canvas");
	const resize = () => {
		let { width } = image;
		let { height } = image;
		if (width > height) {
			if (width > maxWidth) {
				height *= maxWidth / width;
				width = maxWidth;
			}
		} else if (height > maxHeight) {
			width *= maxHeight / height;
			height = maxHeight;
		}
		canvas.width = width;
		canvas.height = height;
		canvas.getContext("2d").drawImage(image, 0, 0, width, height);
		return canvas.toDataURL("image/png");
	};
	return new Promise((resolve, reject) => {
		if (!/image.*/.test(file.type)) {
			reject(new Error("Not an image"));
			return;
		}
		reader.addEventListener("load", readerEvent => {
			image.addEventListener("load", () => resolve(resize()));
			image.src = readerEvent.target.result;
		});
		reader.readAsDataURL(file);
	});
};

export const createDownloadLink = href => {
	Object.assign(document.createElement("a"), {
		href,
		download: `export-${formatDateAsIso(new Date())}`,
	}).click();
};
