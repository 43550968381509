import React from "react";

const NameField = ({ record, source }) => {
	if (record && record[source]) {
		return <span>{`${record[source].firstName} ${record[source].surName}`}</span>;
	}

	return <span />;
};

export default NameField;
