import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import React from "react";
import { useTranslate } from "react-admin";
import { Link } from "react-router-dom";

import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

const useStyles = makeStyles(theme => ({
	link: {
		fontSize: 22,
		fontWeight: 500,
		textDecoration: "none",
		padding: 10,
		color: theme.palette.gray.main,
	},
	active: {
		color: theme.palette.primary.main,
	},
}));

const InvoiceHeader = ({ active }) => {
	const translate = useTranslate();
	const classes = useStyles();

	const links = [
		{
			label: "invoices.label",
			to: "/invoices",
		},
		{
			label: "superTenantInvoices.label",
			to: "/supertenant-invoices",
		},
	];

	return (
		<div>
			<TitleBreadcrumbs title={translate("invoices.label")} />

			<Box
				display="flex"
				flexDirection="row"
				justifyContent="flex-start"
				flexWrap="wrap"
				my={2}
			>
				{links.map(link => (
					<Link
						key={link.to}
						to={link.to}
						className={classNames(
							classes.link,
							active === link.to ? classes.active : null
						)}
					>
						{translate(link.label)}
					</Link>
				))}
			</Box>
		</div>
	);
};

export default InvoiceHeader;
