import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useDataProvider, useTranslate } from "react-admin";

import { selectedTenant } from "../next-gen/state";

const useStyles = makeStyles({
	container: {
		padding: 24,
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: 220,
		width: 300,
		position: "absolute",
		top: "30%",
		left: "42%",
	},
});

const SwitchTenantModal = ({ open, onClose, tenantId, onConfirm, superTenantId }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const dataProvider = useDataProvider();
	const [tenants, setTenants] = useState([]);
	const [selected, setSelected] = useAtom(selectedTenant);

	const fetchTenants = (e, search) =>
		dataProvider
			.getList("tenants", {
				pagination: { page: 1, perPage: 20 },
				filter: superTenantId
					? { fk_superTenantId: superTenantId, q: search }
					: { q: search },
				sort: {},
			})
			.then(res => {
				if (res && res.data) {
					setTenants(res.data);
					return res.data;
				}
				return [];
			});

	const handleChange = (e, value) => {
		setSelected(value);
	};

	useEffect(() => {
		if (open) {
			fetchTenants().then(data => {
				setSelected(data.find(t => t.id === tenantId));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [superTenantId, open]);

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Paper className={classes.container}>
				<Typography mb={2} variant="h3">
					{translate("pages.appBar.switchTenant")}
				</Typography>
				<Autocomplete
					id="switch-tenant-input"
					value={selected}
					getOptionLabel={option => option.name}
					options={tenants}
					style={{ width: 300 }}
					renderInput={parameters => <TextField {...parameters} variant="outlined" />}
					onInputChange={fetchTenants}
					onChange={handleChange}
				/>

				<Box mt={1} display="flex" flexDirection="row">
					<Button onClick={onClose}>{translate("ra.action.cancel")}</Button>
					<Button
						style={{ marginLeft: 10 }}
						color="primary"
						variant="contained"
						onClick={() => onConfirm(selected.id)}
					>
						{translate("ra.action.confirm")}
					</Button>
				</Box>
			</Paper>
		</Modal>
	);
};

export default SwitchTenantModal;
