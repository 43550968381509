import { baseApi } from "../common/envUtils";

import httpClient from "./httpClient";

const customProvider = {
	customAction: (resource, parameters) => {
		const { id, action, ...requestBody } = parameters;

		const url = `${baseApi}/${resource}/${id}/${action}`;
		return httpClient(url, {
			method: "POST",
			body: requestBody ? JSON.stringify(requestBody) : null,
		}).then(({ json }) => ({ data: json }));
	},
	customBulk: (resource, parameters) => {
		const { action, ...requestBody } = parameters;

		let url = `${baseApi}/${resource}/batch`;
		if (action) {
			url += `/${action}`;
		}
		return httpClient(url, {
			method: "POST",
			body: JSON.stringify(requestBody),
		}).then(({ json }) => ({ data: json }));
	},
	get: resource => {
		const url = `${baseApi}/${resource}`;
		return httpClient(url).then(({ json }) => ({ data: json }));
	},
};

export default customProvider;
