import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import React from "react";
import { usePermissions, useTranslate } from "react-admin";
import { Link } from "react-router-dom";

import PermissionUtils from "../common/permissionUtils";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

const useStyles = makeStyles(theme => ({
	link: {
		fontSize: 22,
		fontWeight: 500,
		textDecoration: "none",
		padding: 10,
		color: theme.palette.gray.main,
	},
	active: {
		color: theme.palette.primary.main,
	},
}));

const AdminHeader = ({ active }) => {
	const translate = useTranslate();
	const classes = useStyles();

	const permissions = new PermissionUtils(usePermissions());

	const links = [
		{
			role: 2,
			label: "pages.admin.manageAccounts",
			to: "/super-tenants",
		},
		{
			label: "pages.admin.manageAdmins",
			to: "/users",
		},
		// {
		//   label: 'pages.admin.features',
		//   to: '/admin/features',
		// },
		{
			role: 1,
			label: "pages.admin.orderLabel",
			to: "/admin/order-label",
		},
		{
			label: "pages.admin.downloadArea",
			to: "/drivers-report",
		},
		{
			role: 2,
			label: "logs.label",
			to: "/stat_logs",
		},
		// {
		//   role: 3,
		//   label: 'pages.admin.tenants',
		//   to: '/tenants',
		// },
		{
			role: 2,
			label: "pages.admin.reminder",
			to: "/templates",
		},
		{
			role: 2,
			label: "pages.admin.labelCodes",
			to: "/label-codes",
		},
		{
			role: 2,
			label: "statsPerf.label",
			to: "/stat_perf",
		},
	];

	return (
		<div>
			<TitleBreadcrumbs title={translate("pages.admin.title")} />

			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				flexWrap="wrap"
				my={2}
			>
				{permissions.isTenantAdmin() &&
					!permissions.isSuperAdmin() &&
					permissions.canEdit() && (
						<Link
							key="manageTenantAdmin"
							to="/groups"
							className={classNames(
								classes.link,
								active === "/groups" ? classes.active : null
							)}
						>
							{translate("pages.admin.manageAccounts")}
						</Link>
					)}
				{permissions.isGroupAdmin() &&
					!permissions.isTenantAdmin() &&
					permissions.canEdit() && (
						<Link
							key="manageGroupAdmin"
							to="/tenants"
							className={classNames(
								classes.link,
								active === "/tenants" ? classes.active : null
							)}
						>
							{translate("pages.admin.manageAccounts")}
						</Link>
					)}
				{links
					.filter(l => !l.role || permissions.checkRole(l.role))
					.map(link => (
						<Link
							key={link.to}
							to={link.to}
							className={classNames(
								classes.link,
								active === link.to ? classes.active : null
							)}
						>
							{translate(link.label)}
						</Link>
					))}
			</Box>
		</div>
	);
};

export default AdminHeader;
