/* eslint-disable no-bitwise */
import { get } from "lodash";

export const adminPermissions = {
	// bit wise
	READ: 1,
	EDIT: 2,
	MANAGE: 4,
};

export default class PermissionUtils {
	constructor({ permissions }) {
		this.permissions = permissions;
	}

	isLoggedIn() {
		return !!this.permissions;
	}

	getId() {
		return this.permissions ? this.permissions.userId : "";
	}

	getName() {
		return this.permissions ? this.permissions.fullName : "";
	}

	isTenantAdmin() {
		if (this.isSuperAdmin()) {
			return true;
		}
		return this.permissions ? this.permissions.role === 3 : false;
	}

	isSuperAdmin() {
		return this.permissions ? this.permissions.role === 2 : false;
	}

	isSuperAdminV2() {
		return this.permissions?.isSuperAdmin;
	}

	isGroupAdmin() {
		if (this.isTenantAdmin()) {
			return true;
		}
		return this.permissions ? this.permissions.role === 4 : false;
	}

	isAdmin() {
		return this.permissions ? this.permissions.role >= 1 : false;
	}

	isFpm() {
		return this.permissions ? this.permissions.role === 1 : false;
	}

	hasPhotoRollout() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.tenantConfig && this.permissions.tenantConfig.hasPhotoRollout;
	}

	isManagerWithMultipleDepartmentsAccess() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.isSuperAdmin === false && this.permissions.departmentCount > 1;
	}

	hasPhotoControl() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.tenantConfig && this.permissions.tenantConfig.hasPhotoControl;
	}

	checkRole(role) {
		if (this.permissions) {
			const userRoles = [];
			if (this.permissions.role === 2) {
				return true;
			}

			if (this.permissions.role === 0) {
				userRoles.push(0);
			}
			if (this.permissions.role === 1) {
				userRoles.push(0, 1);
			}

			if (this.permissions.role === 3) {
				userRoles.push(0, 1, 3, 4);
			}
			if (this.permissions.role === 4) {
				userRoles.push(0, 1, 4);
			}
			return userRoles.includes(role);
		}
		return false;
	}

	getRole() {
		return this.permissions ? this.permissions.role : 0;
	}

	canEdit() {
		if (!this.permissions) {
			return false;
		}
		return (this.permissions.permissions & adminPermissions.EDIT) === adminPermissions.EDIT;
	}

	canManage() {
		if (!this.permissions) {
			return false;
		}
		return (this.permissions.permissions & adminPermissions.MANAGE) === adminPermissions.MANAGE;
	}

	isIncludeUvv() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.tenantConfig && this.permissions.tenantConfig.includeUVV;
	}

	isType1Uvv() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.tenantConfig && this.permissions.tenantConfig.type1UVV;
	}

	isIncludeUvvLkw() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.tenantConfig && this.permissions.tenantConfig.includeUVVLKW;
	}

	isPkw() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.tenantConfig && this.permissions.tenantConfig.isPkw;
	}

	isLkw() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.tenantConfig && this.permissions.tenantConfig.isLkw;
	}

	isAllowSms() {
		if (!this || !this.permissions) {
			return false;
		}
		return this.permissions.tenantConfig && this.permissions.tenantConfig.allowSms;
	}

	getControlIntervalDays() {
		return this.permissions && this.permissions.tenantConfig
			? this.permissions.tenantConfig.controlIntervalDays
			: null;
	}

	getAdditionalUVVs() {
		return this.permissions && this.permissions.tenantConfig
			? this.permissions.tenantConfig.additionalUVVs
			: null;
	}

	getTenantId() {
		return this.permissions ? this.permissions.tenantId : "";
	}

	getSuperTenantId() {
		return this.permissions ? this.permissions.superTenantId : null;
	}

	getGroupId() {
		return this.permissions ? this.permissions.groupId : null;
	}

	isExternal() {
		return this.permissions && this.permissions.tenantConfig
			? this.permissions.tenantConfig.external
			: false;
	}

	checkPermissions(requestPerm) {
		if (!requestPerm) {
			return true;
		}
		if (!this.permissions) {
			return false;
		}
		return (this.permissions.permissions & requestPerm) === requestPerm;
	}

	getCommentField1Name() {
		return (
			get(this.permissions, "tenantConfig.commentField1Name") ||
			"drivers.fields.licenseComment1"
		);
	}

	getCommentField2Name() {
		return get(this.permissions, "tenantConfig.commentField2Name", null);
	}

	hasCustomCommentField() {
		return [
			Boolean(get(this.permissions, "tenantConfig.commentField1Name")),
			Boolean(get(this.permissions, "tenantConfig.commentField2Name")),
		];
	}

	showDeleted() {
		return get(this.permissions, "tenantConfig.showDeleted", false);
	}
}
