import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ChevronRight from "@material-ui/icons/ChevronRight";
import MaterialTable from "material-table";
import React, { forwardRef } from "react";
import { useTranslate } from "react-admin";
import { useHistory } from "react-router-dom";

import { formatIsoDate } from "../common/dateUtils";
import TenantListPanel from "../superTenant/TenantListPanel";

const useStyles = makeStyles(theme => ({
	container: {
		background: theme.palette.background.default,
		padding: theme.spacing(2),
	},
}));

const GroupPanel = ({ record }) => {
	const translate = useTranslate();
	const history = useHistory();
	const classes = useStyles();

	const handleRowClick = data => {
		history.push(`/groups/${data.id}`);
	};

	const handleTenantRowClick = data => {
		history.push(`/tenants/${data.id}`);
	};

	return (
		<Box className={classes.container}>
			{record.Groups && (
				<>
					<Typography style={{ margin: "10px" }} variant="subtitle1">
						{translate("groups.plural")}
					</Typography>
					<MaterialTable
						title={null}
						data={record.Groups}
						detailPanel={rowData => <TenantListPanel record={rowData} />}
						icons={{
							// eslint-disable-next-line react/display-name
							DetailPanel: forwardRef((properties, reference) => (
								<ChevronRight {...properties} ref={reference} />
							)),
						}}
						style={{
							padding: 0,
							margin: 0,
							width: "100%",
						}}
						columns={[
							{ title: translate("groups.fields.name"), field: "name" },
							{
								title: translate("groups.fields.createdAt"),
								field: "createdAt",
								type: "date",
								render: data => formatIsoDate(data.createdAt),
							},
						]}
						options={{
							showEmptyDataSourceMessage: false,
							toolbar: false,
							search: false,
							paging: false,
							filtering: false,
							header: false,
							exportButton: false,
						}}
						onRowClick={(_, data) => handleRowClick(data)}
					/>
				</>
			)}

			{record.Tenants && (
				<>
					<Typography style={{ margin: "10px" }} variant="subtitle1">
						{translate("superTenants.sections.withoutGroup")}
					</Typography>
					<MaterialTable
						title={null}
						data={record.Tenants}
						style={{
							padding: 0,
							margin: 0,
							width: "100%",
						}}
						columns={[
							{ title: translate("tenants.fields.name"), field: "name" },
							{
								title: translate("tenants.fields.createdAt"),
								field: "createdAt",
								type: "date",
								render: data => formatIsoDate(data.createdAt),
							},
						]}
						options={{
							showEmptyDataSourceMessage: false,
							toolbar: false,
							search: false,
							paging: false,
							filtering: false,
							header: false,
							exportButton: false,
						}}
						onRowClick={(_, data) => handleTenantRowClick(data)}
					/>
				</>
			)}
		</Box>
	);
};

export default GroupPanel;
