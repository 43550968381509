import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import {
	AutocompleteInput,
	email,
	FormDataConsumer,
	RadioButtonGroupInput,
	ReferenceInput,
	required,
	TextInput,
	usePermissions,
	useTranslate,
} from "react-admin";
import { useForm } from "react-final-form";

import PermissionUtils from "../common/permissionUtils";
import FormCheckbox from "../components/form/FormCheckbox";
import FormSubtitle from "../components/form/FormSubtitle";

const useStyles = makeStyles(() => ({
	container: {
		padding: "20px 40px 30px 40px",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	halfWidth: {
		flexBasis: "48%",
	},
	toolbar: {
		backgroundColor: "inherit",
	},
}));

const UserForm = ({ toolbar }) => {
	const classes = useStyles();
	const translate = useTranslate();
	const permissions = new PermissionUtils(usePermissions());
	const form = useForm();

	const handleRoleChange = value => {
		if (value === 2 || value === 3 || value === 4) {
			form.change("canEdit", true);
			form.change("canRead", true);
			form.change("canManage", true);
		}
	};

	let permissionRoles = [
		{ id: 1, name: translate("users.roles.1") },
		{ id: 4, name: translate("users.roles.4") },
		{ id: 3, name: translate("users.roles.3") },
		{ id: 2, name: translate("users.roles.2") },
	];

	if (!permissions.isSuperAdmin()) {
		permissionRoles = permissionRoles.filter(pr => pr.id !== 2);
	}
	if (!permissions.isTenantAdmin()) {
		permissionRoles = permissionRoles.filter(pr => pr.id !== 3);
	}
	if (!permissions.isGroupAdmin()) {
		permissionRoles = permissionRoles.filter(pr => pr.id !== 4);
	}

	return (
		<form className={classes.container}>
			<Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
				<FormSubtitle>{translate("users.sections.personalData")}</FormSubtitle>
				<TextInput
					className={classes.halfWidth}
					margin="none"
					variant="outlined"
					source="firstName"
					label="users.fields.firstName"
					validate={required()}
				/>
				<TextInput
					className={classes.halfWidth}
					margin="none"
					variant="outlined"
					source="surName"
					label="users.fields.surName"
					validate={required()}
				/>
				<TextInput
					fullWidth
					margin="none"
					variant="outlined"
					source="email"
					label="users.fields.email"
					validate={[required(), email()]}
				/>
			</Box>

			<Box>
				<FormSubtitle>{translate("users.sections.adminRoles")}</FormSubtitle>
				<RadioButtonGroupInput
					row
					source="role"
					label=""
					validate={required()}
					style={{ marginLeft: 10, marginTop: 0 }}
					choices={permissionRoles}
					onChange={handleRoleChange}
				/>
			</Box>

			<Box>
				<FormSubtitle>{translate("users.sections.companyDetails")}</FormSubtitle>
				<FormDataConsumer>
					{({ formData, ...rest }) => {
						const tenantFilter = () => {
							if (formData.role === 3 && formData.fk_superTenantId) {
								return { fk_superTenantId: formData.fk_superTenantId };
							}
							if (
								formData.role === 3 &&
								permissions.isTenantAdmin() &&
								permissions.getSuperTenantId()
							) {
								return { fk_superTenantId: permissions.getSuperTenantId };
							}
							if (formData.role === 4 && formData.fk_groupId) {
								return { fk_groupId: formData.fk_groupId };
							}
							if (permissions.isGroupAdmin()) {
								return { fk_groupId: permissions.getGroupId() };
							}

							return {};
						};

						return (
							<>
								{formData.role === 3 && permissions.isSuperAdmin() && (
									<ReferenceInput
										key="superTenant"
										label="users.fields.superTenant"
										source="fk_superTenantId"
										reference="super-tenants"
										filter={null}
										{...rest}
									>
										<AutocompleteInput
											fullWidth
											allowEmpty
											margin="none"
											variant="outlined"
											optionText="name"
											validate={required()}
										/>
									</ReferenceInput>
								)}
								{formData.role === 4 && permissions.isTenantAdmin() && (
									<ReferenceInput
										key="group"
										label="users.fields.group"
										source="fk_groupId"
										reference="groups"
										filter={null}
										{...rest}
									>
										<AutocompleteInput
											fullWidth
											allowEmpty
											margin="none"
											variant="outlined"
											optionText="name"
											validate={required()}
										/>
									</ReferenceInput>
								)}
								{(permissions.isSuperAdmin() ||
									(permissions.getSuperTenantId() &&
										permissions.isTenantAdmin()) ||
									(permissions.getGroupId() && permissions.isGroupAdmin())) && (
									<ReferenceInput
										key="tenant"
										label="users.fields.tenant"
										source="fk_tenantId"
										reference="tenants"
										filter={tenantFilter()}
										{...rest}
									>
										<AutocompleteInput
											fullWidth
											allowEmpty
											margin="none"
											variant="outlined"
											optionText="name"
											validate={required()}
										/>
									</ReferenceInput>
								)}
							</>
						);
					}}
				</FormDataConsumer>
			</Box>

			<Box mb={2}>
				<FormSubtitle>{translate("users.sections.rights")}</FormSubtitle>

				<FormDataConsumer>
					{({ formData, ...rest }) => (
						<>
							<FormCheckbox
								disabled
								fullWidth
								source="canRead"
								label="users.fields.canRead"
								hint="users.hints.canRead"
								{...rest}
							/>
							<FormCheckbox
								fullWidth
								source="canEdit"
								disabled={formData.canManage}
								label="users.fields.canEdit"
								hint="users.hints.canEdit"
								{...rest}
							/>
							<FormCheckbox
								fullWidth
								source="canManage"
								disabled={!formData.canEdit}
								label="users.fields.canManage"
								hint="users.hints.canManage"
								{...rest}
							/>
						</>
					)}
				</FormDataConsumer>

				<FormCheckbox
					fullWidth
					source="isNotified"
					label="users.fields.isNotified"
					hint="users.hints.isNotified"
				/>

				<FormDataConsumer>
					{({ formData, ...rest }) =>
						formData.role === 3 || formData.role === 4 ? (
							<FormCheckbox
								fullWidth
								source="isNotifiedGlobal"
								label="users.fields.isNotifiedGlobal"
								hint="users.hints.isNotifiedGlobal"
								disabled={!formData.isNotified}
								{...rest}
							/>
						) : undefined
					}
				</FormDataConsumer>
			</Box>

			{toolbar}
		</form>
	);
};

export default UserForm;
