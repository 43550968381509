import React from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";

import AdminHeader from "../admin/AdminHeader";

const TemplateTypeField = ({ record, source }) => {
	const translate = useTranslate();

	if (record && record[source]) {
		return <span>{translate(`templates.types.${record[source]}`)}</span>;
	}

	return <span />;
};

const TemplateList = properties => {
	const { basePath } = properties;
	return (
		<div>
			<AdminHeader active={basePath} />
			<List pagination={null} actions={null} {...properties}>
				<Datagrid rowClick="edit">
					<TemplateTypeField
						source="templateType"
						label="templates.fields.templateType"
					/>
					<TextField source="subject" label="templates.fields.subject" />
				</Datagrid>
			</List>
		</div>
	);
};

export default TemplateList;
