import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useTypographyStyles = makeStyles({
	root: {
		marginBottom: 10,
		width: "100%",
	},
});

// remove props injected by SimpleForm
const FormSubtitle = ({ basePath, variant, ...rest }) => {
	const classes = useTypographyStyles();
	return <Typography classes={classes} variant="subtitle1" {...rest} />;
};

export default FormSubtitle;
