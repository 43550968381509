import { forEach } from "lodash";
import { stringify } from "query-string";
import simpleRestProvider from "ra-data-simple-rest";

import { baseApi } from "../common/envUtils";

import httpClient from "./httpClient";

const dataProvider = simpleRestProvider(baseApi, httpClient);

const resourceDataProvider = {
	...dataProvider,
	getList: (resource, parameters) => {
		let url = `${baseApi}/${resource}`;
		if (parameters) {
			const { page, perPage } = parameters.pagination || { page: 1, perPage: 100 };
			const { field, order } = parameters.sort || {};

			const { q, customFilter, ...filter } = parameters.filter;

			const listRegex = /\[(-?\d,?)+]/;
			let startDate;
			let endDate;
			forEach(filter, (values, key) => {
				if (key.includes("status")) {
					filter[key] = values.flatMap(value => {
						if (typeof value === "string" && listRegex.test(value)) {
							return value.match(/([\d?-]+)/g);
						}
						return [value];
					});
				}
				if (key === "startDate") {
					startDate = values;
					delete filter[key];
				}
				if (key === "endDate") {
					endDate = values;
					delete filter[key];
				}
			});

			const query = {
				limit: perPage,
				offset: (page - 1) * perPage,
				orderBy: field && order ? `${field} ${order}` : null,
				search: q || null,
				filter:
					parameters.filter && Object.values(filter).length > 0
						? JSON.stringify(filter)
						: null,
				customFilter,
				startDate,
				endDate,
			};

			url += `?${stringify(query, {
				skipEmptyString: true,
				skipNull: true,
				encode: true,
			})}`;
		}

		if (resource === "templates") {
			return httpClient(url).then(({ json }) => {
				const newData = json.data.map(d => ({ ...d, id: d.templateType }));
				return { ...json, data: newData };
			});
		}

		return httpClient(url).then(({ json }) => json);
	},
	getMany: (resource, parameters) => {
		const query = {
			filter:
				parameters && parameters.ids && parameters.ids.length > 0
					? JSON.stringify({ id: parameters.ids })
					: null,
		};

		const url = `${baseApi}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ json }) => json);
	},
	getOne: (resource, parameters) =>
		httpClient(`${baseApi}/${resource}/${parameters.id}`).then(({ json }) =>
			resource === "templates"
				? {
						data: {
							...json,
							id: json.templateType,
						},
					}
				: {
						data: json,
					}
		),
	delete: (resource, parameters) => {
		let url = `${baseApi}/${resource}/${parameters.id}`;
		if (parameters.final) {
			url += "?final=true";
		}

		return httpClient(url, {
			method: "DELETE",
		}).then(({ json }) => ({ data: json }));
	},
};

const resourceNameMapper = callback => (resource, parameters) => {
	const res = resource.replace("_", "/");
	return callback(res, parameters);
};

forEach(resourceDataProvider, (callbackFunction, key) => {
	resourceDataProvider[key] = resourceNameMapper(callbackFunction);
});

export default resourceDataProvider;
