import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { useEffect } from "react";
import { Link, Redirect, Route } from "react-router-dom";

import FeaturesPage from "./admin/FeaturesPage";
import LabelCodesPage from "./admin/LabelCodesPage";
import OrderLabelPage from "./admin/OrderLabelPage";
import Icon from "./assets/img/edding-logo.svg";
import ActivationPage from "./auth/ActivationPage";
import PasswordResetPage from "./auth/PasswordResetPage";
import VerifyPage from "./auth/VerifyPage";
import theme from "./common/theme";
import DashboardPage from "./dashboard/DashboardPage";
import DriverReportList from "./drivers/DriverReportList";
import DriverUploadPage from "./drivers/DriverUploadPage";
import useCookiebot from "./hooks/cookiebot";
import MonitoringPage from "./monitoring/MonitoringPage";
import ScheduleEventPage from "./scheduleEvent/ScheduleEventPage";
import SettingsPage from "./settings/SettingsPage";
import SupportPage from "./support/SupportPage";

const useStyles = makeStyles(() => ({
	logoImage: {
		objectFit: "scale-down",
		width: "auto",
		height: 48,
	},
}));

export function LoadCookiebot() {
	const { renewConsent } = useCookiebot();
	const classes = useStyles();

	useEffect(() => {
		const container = document.querySelector("#Cookiebot-Content");
		const script = document.createElement("script");
		script.id = "Cookiebot-Dynamic-Content";
		script.src = "https://consent.cookiebot.com/1babc6e3-cb57-477b-9dde-79eaf255e06a/cd.js";
		script.type = "text/javascript";
		script.async = true;

		if (container) {
			container.append(script);
		}

		return () => {
			// Clean the container and remove the script
			container.innerHTML = "";
			const existingScript = document.querySelector("#Cookiebot-Dynamic-Content");
			if (existingScript) {
				existingScript.remove();
			}
		};
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Container fixed>
				<div style={{ height: 48 }} />
				<Link to="/" aria-label="Startseite">
					<img className={classes.logoImage} src={Icon} alt="company_logo" />
				</Link>

				<Typography gutterBottom variant="h1">
					Cookieeinstellungen
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						renewConsent();
					}}
				>
					Cookies zurücksetzen
				</Button>
				<div style={{ height: 48 }} />
				<div id="Cookiebot-Content" />
			</Container>
		</ThemeProvider>
	);
}

export default [
	<Route key="legal-cookies" exact noLayout path="/legal/cookies" component={LoadCookiebot} />,
	<Route key="auth-reset" exact noLayout path="/auth/reset" component={PasswordResetPage} />,
	<Route key="auth-verify" exact noLayout path="/auth/verify" component={VerifyPage} />,
	<Route key="auth-activation" exact noLayout path="/activation" component={ActivationPage} />,
	<Route key="dashboard" exact path="/dashboard" component={DashboardPage} />,
	<Route key="monitoring" exact path="/monitoring" component={MonitoringPage} />,
	<Route key="admin-features" exact path="/admin/features" component={FeaturesPage} />,
	<Route key="admin-order-label" exact path="/admin/order-label" component={OrderLabelPage} />,
	<Route key="settings" exact path="/settings" component={SettingsPage} />,
	<Route key="support" exact path="/support" component={SupportPage} />,
	<Route
		key="schedule-event"
		exact
		noLayout
		path="/schedule-event"
		component={ScheduleEventPage}
	/>,
	<Route key="label-codes" exact path="/label-codes" component={LabelCodesPage} />,
	<Route key="driver-upload" exact path="/drivers-upload" component={DriverUploadPage} />,
	<Route key="driver-report" exact path="/drivers-report" component={DriverReportList} />,
	<Redirect key="redirect" exact from="/" to="/dashboard" />,
];
