import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ThemeProvider } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { useDataProvider, useNotify, useTranslate } from "react-admin";
import { CalendlyEventListener, InlineWidget } from "react-calendly";

import Icon from "../assets/img/edding-logo.svg";
import { baseApi } from "../common/envUtils";
import theme from "../common/theme";
import httpClient from "../providers/httpClient";

const useStyles = makeStyles(() => ({
	logoImage: {
		objectFit: "scale-down",
		width: 220,
		height: 64,
		paddingBottom: 38,
	},
	titleContainer: {
		textAlign: "center",
		paddingBottom: 38,
		borderBottom: properties => `1px solid ${properties.secondaryColor}`,
	},
}));

const ScheduleEventPage = ({ history }) => {
	const dataProvider = useDataProvider();
	const classes = useStyles();
	const translate = useTranslate();
	const notify = useNotify();
	const [driver, setDriver] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const [success, setSuccess] = useState(false);

	const { userId } = parse(history.location.search);

	useEffect(() => {
		dataProvider
			.getOne("drivers/calendly", { id: userId })
			.then(({ data }) => {
				setDriver(data);
				setLoading(false);
			})
			.catch(error_ => {
				setError(error_);
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleConfirm = async message => {
		setLoading(true);
		setTimeout(() => {
			httpClient(`${baseApi}/controls/schedule-event`, {
				method: "POST",
				body: JSON.stringify({
					userId,
					url: message.data.payload.event.uri,
				}),
			}).then(() => {
				notify("common.success");
				setLoading(false);
				setSuccess(true);
			});
		}, 5000);
	};

	return (
		<ThemeProvider theme={theme}>
			<Container fixed>
				<Box
					height="90vh"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
				>
					<Box
						width="100%"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
					>
						<img className={classes.logoImage} src={Icon} alt="company_logo" />

						<Box width="100%" alignItems="center" justifyContent="center">
							{error ? (
								<Typography align="center" color="secondary" variant="h3">
									{translate("pages.scheduleEvent.invalid")}
								</Typography>
							) : (
								<>
									<Box className={classes.titleContainer}>
										<Typography variant="h3">
											{translate("pages.scheduleEvent.title")}
										</Typography>
									</Box>
									{success && (
										<Typography align="center" color="primary" variant="h3">
											{translate("pages.scheduleEvent.success")}
										</Typography>
									)}
									{!success && loading && <LinearProgress />}

									{!success && !loading && (
										<CalendlyEventListener onEventScheduled={handleConfirm}>
											<InlineWidget
												url="https://calendly.com/easycheck-by-edding/fsk"
												prefill={{
													email: driver.email,
													firstName: driver.firstName,
													lastName: driver.surName,
													name: `${driver.firstName} ${driver.surName}`,
												}}
											/>
										</CalendlyEventListener>
									)}
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default ScheduleEventPage;
