import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import MaterialTable from "material-table";
import { stringify } from "query-string";
import React, { useEffect, useState } from "react";
import {
	Button,
	TextInput,
	useDataProvider,
	useNotify,
	usePermissions,
	useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { connect } from "react-redux";

import AdminHeader from "../admin/AdminHeader";
import { formatDateAsIso } from "../common/dateUtils";
import { baseApi } from "../common/envUtils";
import PermissionUtils from "../common/permissionUtils";
import { useOutlinedInputStyles } from "../common/theme";
import { createDownloadLink } from "../common/utils";
import AutocompleteFilter from "../components/AutocompleteFilter";
import DateInput from "../components/DateField";
import { useGlobalViewV2, useIsMigratedMATM } from "../hooks/global-view-v2";
import TitleBreadcrumbs from "../layout/TitleBreadcrumbs";

const useStyles = makeStyles(theme => ({
	card: {
		padding: "26px 32px",
		margin: "12px 0",
	},
	picker: {
		width: "48%",
	},
	hint: {
		color: theme.palette.text.secondary,
	},
}));

const DriverReportFilter = ({ globalView, filterValues, setFilters }) => {
	const permissions = new PermissionUtils(usePermissions());
	const inputStyles = useOutlinedInputStyles();
	const translate = useTranslate();
	const classes = useStyles();
	const globalViewV2 = useGlobalViewV2(globalView);

	const handleDateChange = name => date => {
		setFilters({ ...filterValues, [name]: date ? formatDateAsIso(date) : null }, []);
	};

	const handleTenantChange = selected => {
		setFilters({ ...filterValues, fk_tenantId: selected }, []);
	};

	const onHandleSubmit = values => {
		setFilters(values);
	};

	return (
		<Box>
			<Form initialValues={filterValues} onSubmit={onHandleSubmit}>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="flex-start"
							mt={4}
							mb={2}
						>
							<Box width="100%" display="flex">
								<TextInput
									resettable
									fullWidth
									alwaysOn
									helperText={false}
									source="q"
									label=""
									placeholder={translate("pages.download.search")}
									variant="outlined"
									margin="none"
									InputProps={{
										classes: inputStyles,
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
									onChange={() => handleSubmit()}
								/>
								{(((permissions.isSuperAdmin() || permissions.isTenantAdmin()) &&
									globalView) ||
									globalViewV2) && (
									<Box ml={2}>
										<AutocompleteFilter
											resource="tenants"
											label={translate("users.fields.tenant")}
											selected={filterValues.fk_tenantId}
											width={300}
											disabled={
												!permissions.isSuperAdmin() &&
												!permissions.isTenantAdmin()
											}
											transferOptions={
												permissions.isTenantAdmin()
													? o => ({
															id: o.id,
															name: o.Group
																? `${o.name} - ${o.Group.name}`
																: o.name,
														})
													: undefined
											}
											onChange={handleTenantChange}
										/>
									</Box>
								)}
							</Box>
							<Paper className={classes.card}>
								<Typography variant="subtitle1">
									{translate("pages.monitoring.range")}
								</Typography>

								<Box
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									mt={2}
								>
									<DateInput
										disableFuture
										clearable
										className={classes.picker}
										label={translate("common.startDate")}
										value={filterValues.startDate}
										maxDate={filterValues.endDate}
										onChange={handleDateChange("startDate")}
									/>
									<DateInput
										disableFuture
										clearable
										className={classes.picker}
										label={translate("common.endDate")}
										value={filterValues.endDate}
										minDate={filterValues.startDate}
										onChange={handleDateChange("endDate")}
									/>
								</Box>
							</Paper>
						</Box>
					</form>
				)}
			</Form>
		</Box>
	);
};

const DriverReportList = properties => {
	const { location, globalView } = properties;
	const notify = useNotify();
	const translate = useTranslate();
	const isMigratedMATM = useIsMigratedMATM();
	const dataProvider = useDataProvider();

	const [filterValues, setFilterValues] = useState({});
	const [reportPreview, setReportPreview] = useState([]);

	const fetchReport = async () => {
		const { data } = await dataProvider.getList("drivers/report", {
			filter: filterValues,
		});

		setReportPreview(data);
	};

	useEffect(() => {
		fetchReport();
	}, [filterValues]);

	const fetchReportDownload = async () => {
		let url = `${baseApi}/drivers/report-download`;

		const { q, startDate, endDate, ...filter } = filterValues;

		const queryString = stringify(
			{
				search: q,
				filter: JSON.stringify(filter),
				startDate,
				endDate,
			},
			{
				skipEmptyString: true,
				skipNull: true,
			}
		);

		url = queryString ? `${url}?${queryString}` : url;
		const token = localStorage.getItem("token");
		try {
			const response = await fetch(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status !== 200) {
				notify("pages.download.rangeError");
				return;
			}
			const blob = await response.blob();
			const href = URL.createObjectURL(blob);
			createDownloadLink(href);
		} catch (error) {
			console.error(error); // replace this with your error handling logic
		}
	};

	return (
		<div>
			{isMigratedMATM ? (
				<TitleBreadcrumbs title={translate("pages.admin.downloadArea")} />
			) : (
				<AdminHeader active={location.pathname} />
			)}
			<Box display="flex" flexDirection="column" alignItems="start">
				<DriverReportFilter
					globalView={globalView}
					filterValues={filterValues}
					setFilters={setFilterValues}
				/>

				<Button
					variant="contained"
					label={translate("pages.download.download")}
					style={{ padding: 10 }}
					onClick={fetchReportDownload}
				/>
			</Box>

			<Box marginTop={2}>
				<Typography variant="subtitle1">
					{translate("pages.driverReport.preview")}
				</Typography>

				<MaterialTable
					title={null}
					data={reportPreview}
					style={{
						padding: 0,
						margin: "20px 0",
						width: "100%",
					}}
					columns={[
						{ title: translate("drivers.fields.firstName"), field: "firstName" },
						{
							title: translate("drivers.fields.surName"),
							field: "surName",
						},
						{
							title: translate("drivers.fields.email"),
							field: "email",
						},
						{
							title: translate("drivers.fields.statusFS"),
							field: "status",
						},
						{ title: translate("drivers.fields.controlDate"), key: "controlDate" },
					]}
					options={{
						showEmptyDataSourceMessage: false,
						toolbar: false,
						search: false,
						paging: false,
						filtering: false,
						exportButton: false,
					}}
				/>
			</Box>
		</div>
	);
};

const mapStateToProperties = state => ({
	globalView: state.settings.globalView,
});

export default connect(mapStateToProperties)(DriverReportList);
