import { call, put, takeEvery } from "redux-saga/effects";

import settingsProvider from "../providers/settingsProvider";

import { setSettings } from "./settingsReducer";

export default function* settingsSaga() {
	yield takeEvery("FETCH_SETTINGS", function* () {
		try {
			const settings = yield call(settingsProvider.getSettings);
			localStorage.setItem("globalView", settings.globalView);
			yield put(setSettings(settings));
		} catch {
			yield put(setSettings({}));
		}
	});

	yield takeEvery("UPDATE_SETTINGS", function* ({ payload }) {
		try {
			if (payload.globalView != null) {
				localStorage.setItem("globalView", payload.globalView);
			}

			yield call(settingsProvider.updateSettings, payload);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	});
}
