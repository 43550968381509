import React from "react";
import AceEditor from "react-ace";
import { useInput } from "react-admin";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";

const FormHtmlEditor = properties => {
	const {
		input: { onChange, value },
	} = useInput(properties);

	return <AceEditor width="100%" mode="html" theme="github" value={value} onChange={onChange} />;
};

export default FormHtmlEditor;
