import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDataProvider, useTranslate } from "react-admin";

const GroupSelect = ({ selected, onChange, superTenantId }) => {
	const [groups, setGroups] = useState([]);
	const translate = useTranslate();
	const dataProvider = useDataProvider();

	const handleChange = (_event, value) => {
		onChange(value);
	};

	const fetchGroups = (_event, search) =>
		dataProvider
			.getList("groups", {
				pagination: { page: 1, perPage: 50 },
				filter: superTenantId
					? { fk_superTenantId: superTenantId, q: search }
					: { q: search },
				sort: {},
			})
			.then(response => {
				if (response && response.data) {
					setGroups(response.data);
					return response.data;
				}
				return [];
			});

	useEffect(() => {
		fetchGroups();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [superTenantId]);

	return (
		<Autocomplete
			value={selected}
			getOptionLabel={option => option && option.name}
			options={groups}
			style={{ width: 300 }}
			renderInput={parameters => (
				<TextField {...parameters} label={translate("groups.label")} variant="outlined" />
			)}
			onInputChange={fetchGroups}
			onChange={handleChange}
		/>
	);
};

export default GroupSelect;
