import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import BorderClearIcon from "@material-ui/icons/BorderClear";
import DeleteIcon from "@material-ui/icons/Delete";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import SearchIcon from "@material-ui/icons/Search";
import { isEmpty, reduce, pickBy } from "lodash";
import React, { useEffect, useState } from "react";
import {
	TextInput,
	useDataProvider,
	useListContext,
	usePermissions,
	useTranslate,
} from "react-admin";
import { Form } from "react-final-form";
import { connect } from "react-redux";

import { ReactComponent as TenantIcon } from "../assets/icons/filter/businessUnit.svg";
import { ReactComponent as StatusFSIcon } from "../assets/icons/filter/statusFS.svg";
import { ReactComponent as StatusUVVIcon } from "../assets/icons/filter/statusUVV.svg";
import PermissionUtils from "../common/permissionUtils";
import { useOutlinedInputStyles } from "../common/theme";
import AutocompleteFilter from "../components/AutocompleteFilter";
import FilterChipBar from "../components/FilterChipBar";
import LargeSwitch from "../components/LargeSwitch";
import SelectFilter from "../components/SelectFilter";
import { useGlobalViewV2 } from "../hooks/global-view-v2";
import customProvider from "../providers/customProvider";
import { fetchSettings, updateSettings } from "../redux/settingsReducer";

const cleanFilterObject = filter => pickBy(filter, t => !!t && !isEmpty(t));

const DriverFilter = ({
	history,
	driverFilter,
	driverSort,
	updateFilterSettings,
	updateSortSettings,
	fetchUserSettings,
	globalView,
}) => {
	const translate = useTranslate();
	const inputStyles = useOutlinedInputStyles();
	const { filterValues, setFilters, currentSort, setSort } = useListContext();
	const permissions = new PermissionUtils(usePermissions());

	const dataProvider = useDataProvider();
	const [tenants, setTenants] = useState([]);
	const [groups, setGroups] = useState([]);
	const globalViewV2 = useGlobalViewV2(globalView);

	useEffect(() => {
		fetchUserSettings();
		if (
			permissions.isSuperAdmin() ||
			permissions.isTenantAdmin() ||
			permissions.isGroupAdmin()
		) {
			customProvider.get("drivers/groups").then(response => {
				if (response && response.data) {
					setGroups(response.data.filter(Boolean));
				}
			});
			dataProvider
				.getList("tenants", {
					pagination: { page: 1, perPage: 1000 },
					filter: {},
					sort: {},
				})
				.then(response => {
					if (response && response.data) {
						setTenants(response.data);
					}
				})
				.catch(() => {});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (driverFilter && !filterValues.customFilter) {
			setFilters(driverFilter, {});
		} else if (!filterValues.customFilter) {
			setFilters({}, {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [driverFilter]);

	useEffect(() => {
		if (
			currentSort &&
			currentSort.field !== "id" &&
			(!driverSort ||
				currentSort.field !== driverSort.field ||
				currentSort.order !== driverSort.order)
		) {
			updateSortSettings(currentSort);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSort]);

	useEffect(() => {
		if (
			driverSort &&
			(isEmpty(currentSort) ||
				currentSort.field !== driverSort.field ||
				currentSort.order !== driverSort.order)
		) {
			setSort(driverSort.field, driverSort.order);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [driverSort]);

	const handleFilterChange = filters => {
		const cleaned = cleanFilterObject(filters);
		updateFilterSettings(cleaned);
		setFilters(cleaned, {});
	};

	const handleTenantChange = selected => {
		handleFilterChange({ ...filterValues, fk_tenantId: selected });
	};

	const handleGroupChange = selected => {
		setFilters({ ...filterValues, fk_groupId: selected }, []);
	};

	const handleExtensionGroupChange = selected => {
		handleFilterChange({ ...filterValues, group: selected });
	};

	const tenantFilterValues = reduce(
		tenants,
		(result, value) => {
			result[value.id] = value.name;
			return result;
		},
		{}
	);

	const statusFilterValues = {
		"[-1,6]": translate("drivers.tableStates.created"),
		0: translate("drivers.tableStates.fail"),
		"[1,3,4,5]": translate("drivers.tableStates.pending"),
		2: translate("drivers.tableStates.success"),
	};

	const statusFilterChoices = Object.keys(statusFilterValues).map(key => ({
		id: key,
		name: statusFilterValues[key],
	}));

	const isCriticalEnabled = () =>
		Boolean(filterValues.statusCombined) &&
		filterValues.statusCombined.includes(-1) &&
		filterValues.statusCombined.includes(0) &&
		filterValues.statusCombined.includes(1);

	const handleDeletedChange = () => {
		if (filterValues.deleted) {
			handleFilterChange({ ...filterValues, deleted: null });
		} else {
			handleFilterChange({ ...filterValues, deleted: [true] });
		}
	};

	const handleCriticalChange = () => {
		if (isCriticalEnabled()) {
			handleFilterChange({ ...filterValues, statusCombined: null });
		} else {
			handleFilterChange({ ...filterValues, statusCombined: [-1, 0, 1] });
		}
	};

	const handleStatusUvvChange = selected => {
		handleFilterChange({ ...filterValues, statusUVV: selected.length > 0 ? selected : null });
	};

	const handleStatusFsChange = selected => {
		handleFilterChange({ ...filterValues, statusFS: selected.length > 0 ? selected : null });
	};

	const handleActiveChange = selected => {
		handleFilterChange({ ...filterValues, active: selected });
	};

	const handleIsPkwChange = selected => {
		handleFilterChange({ ...filterValues, isPkw: selected });
	};

	const handleIsLkwChange = selected => {
		handleFilterChange({ ...filterValues, isLkw: selected });
	};

	const handleIsInvalidCodeChange = () => {
		handleFilterChange({
			...filterValues,
			licenseLabelCode: filterValues.licenseLabelCode ? undefined : "00000",
		});
	};

	const filterOptions = {
		statusFS: {
			icon: (
				<SvgIcon color="primary">
					<StatusFSIcon />
				</SvgIcon>
			),
			values: statusFilterValues,
		},
		statusUVV: {
			icon: (
				<SvgIcon color="primary">
					<StatusUVVIcon />
				</SvgIcon>
			),
			values: statusFilterValues,
		},
		statusCombined: {
			icon: <MergeTypeIcon />,
			values: {
				"-1": translate("drivers.states.created"),
				0: translate("drivers.tableStates.fail"),
				1: translate("drivers.tableStates.pending"),
				2: translate("drivers.tableStates.success"),
				3: translate("drivers.tableStates.controlFailedFirst"),
			},
		},
		deleted: {
			icon: <DeleteIcon />,
			values: { true: translate("drivers.fields.deleted") },
		},
		active: {
			icon: <BorderClearIcon />,
			values: {
				true: translate("drivers.fields.active"),
				false: translate("drivers.fields.inactive"),
			},
		},
		fk_tenantId: {
			icon: <TenantIcon />,
			values: tenantFilterValues,
		},
	};

	const clearDashboardFilter = () => {
		handleFilterChange({});
		history.push({
			pathname: "/drivers",
			search: "",
		});
	};

	if (filterValues.customFilter) {
		return (
			<Box width="100%" alignSelf="flex-end">
				<Button variant="outlined" color="primary" onClick={clearDashboardFilter}>
					{translate("drivers.actions.clearDbFilter")}
				</Button>
			</Box>
		);
	}

	return (
		<Box width="100%">
			<Form initialValues={filterValues} onSubmit={handleFilterChange}>
				{({ handleSubmit }) => (
					<form style={{ width: "100%" }} onSubmit={handleSubmit}>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="flex-start"
							mt={4}
							mb={2}
							width="100%"
						>
							<Box minWidth="50%">
								<TextInput
									resettable
									fullWidth
									alwaysOn
									helperText={false}
									source="q"
									label=""
									placeholder="Suchen"
									variant="outlined"
									margin="none"
									InputProps={{
										classes: inputStyles,
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
									onChange={() => handleSubmit()}
								/>
							</Box>

							<Box
								width="100%"
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="left"
								mt={1}
							>
								{permissions.isExternal() && (
									<Box mr={1}>
										<SelectFilter
											single
											label={translate("drivers.fields.externalGroup")}
											selected={filterValues.group}
											width={170}
											choices={groups.map(g => ({
												id: g,
												name: g,
											}))}
											onChange={handleExtensionGroupChange}
										/>
									</Box>
								)}
								{(((permissions.isSuperAdmin() ||
									permissions.isTenantAdmin() ||
									permissions.isGroupAdmin()) &&
									globalView) ||
									globalViewV2) && (
									<Box mr={1}>
										<AutocompleteFilter
											resource="tenants"
											label={translate("users.fields.tenant")}
											selected={filterValues.fk_tenantId}
											width={300}
											transferOptions={o => ({
												id: o.id,
												name: o.Group
													? `${o.name} - ${o.Group.name}`
													: o.name,
											})}
											onChange={handleTenantChange}
										/>
									</Box>
								)}
								{permissions.isTenantAdmin() && globalView && (
									<Box mr={1}>
										<AutocompleteFilter
											resource="groups"
											label={translate("drivers.fields.group")}
											selected={filterValues.fk_groupId}
											width={300}
											onChange={handleGroupChange}
										/>
									</Box>
								)}

								<Box mr={1}>
									<SelectFilter
										single
										label={translate("drivers.fields.active")}
										selected={filterValues.active}
										width={170}
										choices={[
											{
												id: "true",
												name: translate("drivers.fields.active"),
											},
											{
												id: "false",
												name: translate("drivers.fields.inactive"),
											},
										]}
										onChange={handleActiveChange}
									/>
								</Box>

								<Box mr={1}>
									<SelectFilter
										choices={statusFilterChoices}
										label={translate("drivers.fields.statusFS")}
										selected={filterValues.statusFS}
										width={250}
										onChange={handleStatusFsChange}
									/>
								</Box>

								{permissions.isIncludeUvv() && (
									<Box mr={1}>
										<SelectFilter
											choices={statusFilterChoices}
											label={translate("drivers.fields.statusUVV")}
											selected={filterValues.statusUVV}
											width={250}
											onChange={handleStatusUvvChange}
										/>
									</Box>
								)}
								{permissions.isIncludeUvvLkw() && (
									<>
										<Box mr={1}>
											<SelectFilter
												single
												label={translate("drivers.fields.isPkw")}
												selected={filterValues.isPkw}
												width={170}
												choices={[
													{
														id: "true",
														name: translate("ra.boolean.true"),
													},
													{
														id: "false",
														name: translate("ra.boolean.false"),
													},
												]}
												onChange={handleIsPkwChange}
											/>
										</Box>
										<Box mr={1}>
											<SelectFilter
												single
												label={translate("drivers.fields.isLkw")}
												selected={filterValues.isLkw}
												width={170}
												choices={[
													{
														id: "true",
														name: translate("ra.boolean.true"),
													},
													{
														id: "false",
														name: translate("ra.boolean.false"),
													},
												]}
												onChange={handleIsLkwChange}
											/>
										</Box>
									</>
								)}
								{permissions.isIncludeUvv() && (
									<Box display="flex" flexDirection="row" alignItems="center">
										<LargeSwitch
											checked={isCriticalEnabled()}
											onChange={handleCriticalChange}
										/>

										<Box ml={1}>
											<Typography variant="h6">
												{translate("pages.driverList.filters.criticalOnly")}
											</Typography>
										</Box>
									</Box>
								)}

								{(permissions.isSuperAdmin() || permissions.showDeleted()) && (
									<Box
										ml={1}
										display="flex"
										flexDirection="row"
										alignItems="center"
									>
										<LargeSwitch
											checked={filterValues.deleted}
											onChange={handleDeletedChange}
										/>

										<Box ml={1}>
											<Typography variant="h6">
												{translate("pages.driverList.filters.deleted")}
											</Typography>
										</Box>
									</Box>
								)}

								{permissions.isSuperAdmin() && (
									<Box
										ml={1}
										display="flex"
										flexDirection="row"
										alignItems="center"
									>
										<LargeSwitch
											checked={filterValues.licenseLabelCode}
											onChange={handleIsInvalidCodeChange}
										/>

										<Box ml={1}>
											<Typography variant="h6">
												{translate("pages.driverList.filters.neutralState")}
											</Typography>
										</Box>
									</Box>
								)}
							</Box>
						</Box>
					</form>
				)}
			</Form>

			<FilterChipBar
				filterDisplay={filterOptions}
				filterValues={filterValues}
				onFilterChange={handleFilterChange}
			/>
		</Box>
	);
};

const mapStateToProperties = state => ({
	driverFilter: state.settings.driverFilter,
	driverSort: state.settings.driverSort,
	globalView: state.settings.globalView,
});

const mapDispatchToProperties = dispatch => ({
	updateFilterSettings: driverFilter => {
		dispatch(updateSettings({ driverFilter }));
	},
	updateSortSettings: driverSort => {
		dispatch(updateSettings({ driverSort }));
	},
	fetchUserSettings: () => {
		dispatch(fetchSettings());
	},
});

export default connect(mapStateToProperties, mapDispatchToProperties)(DriverFilter);
