import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import {
	CreateButton,
	Datagrid,
	List,
	sanitizeListRestProps,
	TextField,
	TopToolbar,
	useListContext,
	usePermissions,
	useTranslate,
} from "react-admin";

import AdminHeader from "../admin/AdminHeader";
import PermissionUtils from "../common/permissionUtils";
import FormSubtitle from "../components/form/FormSubtitle";
import BoolField from "../components/table/BoolField";
import DateField from "../components/table/DateField";
import MyPagination from "../components/table/Pagination";
import SearchFilter from "../components/table/SearchFilter";
import TenantGroupBatchMove from "../superTenant/TenantGroupBatchMove";
import GroupPanel from "../superTenant/TenantListPanel";

const GroupActions = properties => {
	const { className, exporter, filters, maxResults, ...rest } = properties;

	const { basePath } = useListContext();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			<Box mr={2}>
				<CreateButton
					variant="outlined"
					basePath="tenants"
					label="tenants.actions.create"
				/>
			</Box>
			<CreateButton variant="contained" basePath={basePath} label="groups.actions.create" />
		</TopToolbar>
	);
};

const TenantActions = properties => {
	const { className, exporter, filters, maxResults, ...rest } = properties;

	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			<CreateButton variant="outlined" basePath="tenants" label="tenants.actions.create" />
		</TopToolbar>
	);
};

const useStyles = makeStyles(() => ({
	expandedPanel: {
		"& > *": {
			padding: 0,
		},
	},
}));

const GroupList = properties => {
	const translate = useTranslate();
	const classes = useStyles();
	const permissions = new PermissionUtils(usePermissions());

	const { basePath } = properties;

	return (
		<div>
			<AdminHeader active={basePath} />

			<List
				pagination={<MyPagination />}
				bulkActionButtons={false}
				filters={<SearchFilter />}
				actions={permissions.canManage() && <GroupActions />}
				{...properties}
			>
				<Datagrid
					rowClick="edit"
					isRowExpandable={rec => Boolean(rec.Tenants && rec.Tenants.length > 0)}
					expand={<GroupPanel />}
					classes={{ expandedPanel: classes.expandedPanel }}
				>
					<TextField source="name" label="groups.fields.name" />
					<TextField source="createdByUserName" label="groups.fields.createdByUserName" />
					<DateField source="createdAt" label="groups.fields.createdAt" />
				</Datagrid>
			</List>

			<Box paddingY="20px">
				<FormSubtitle>{translate("superTenants.sections.withoutGroup")}</FormSubtitle>
				<List
					basePath="/tenants"
					resource="tenants"
					bulkActionButtons={permissions.canManage() ? <TenantGroupBatchMove /> : false}
					filter={{ fk_groupId: null }}
					pagination={<MyPagination />}
					filters={<SearchFilter />}
					exporter={false}
					actions={permissions.canManage() && <TenantActions />}
				>
					<Datagrid rowClick="edit">
						<TextField source="name" label="tenants.fields.name" />
						<BoolField source="active" label="tenants.fields.active" />
						<DateField source="createdAt" label="tenants.fields.createdAt" />
					</Datagrid>
				</List>
			</Box>
		</div>
	);
};

export default GroupList;
